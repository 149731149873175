export default {
    "name": "CreateQuestionToService",
    "kind": "HoudiniMutation",
    "hash": "c6d0c548f04cf644f0694ee58a9ccb3fece7173fe37c6c93b39cb210ed5571db",

    "raw": `mutation CreateQuestionToService($newServiceQuestion: NewServiceQuestion!, $serviceId: PositiveInt) {
  addQuestionToService(newServiceQuestion: $newServiceQuestion) {
    questions {
      ...ServiceQuestionData_7Hvpf
      id
    }
    id
  }
}

fragment ServiceQuestionData_7Hvpf on Question {
  id
  question
  description
  questionDataType
  answers {
    ...ServiceAnswerData_7Hvpf
    id
  }
  services {
    id
  }
  __typename
}

fragment ServiceAnswerData_7Hvpf on Answer {
  id
  answer
  tag {
    ...ServiceTagData_7Hvpf
    id
  }
  __typename
}

fragment ServiceTagData_7Hvpf on Tag {
  id
  code
  name
  description
  prompt
  entityType
  tagPricingForService(serviceId: $serviceId) {
    ...TagPricingData
    id
  }
  __typename
}

fragment TagPricingData on TagPricing {
  id
  priceAdjustment
  pricingType
  serviceId
  tagId
  postPriceAdjustment
  __typename
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "addQuestionToService": {
                "type": "Service",
                "keyRaw": "addQuestionToService(newServiceQuestion: $newServiceQuestion)",

                "selection": {
                    "fields": {
                        "questions": {
                            "type": "Question",
                            "keyRaw": "questions",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "question": {
                                        "type": "String",
                                        "keyRaw": "question",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "questionDataType": {
                                        "type": "QuestionDataType",
                                        "keyRaw": "questionDataType",
                                        "visible": true
                                    },

                                    "answers": {
                                        "type": "Answer",
                                        "keyRaw": "answers",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "answer": {
                                                    "type": "String",
                                                    "keyRaw": "answer",
                                                    "visible": true
                                                },

                                                "tag": {
                                                    "type": "Tag",
                                                    "keyRaw": "tag",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "code": {
                                                                "type": "String",
                                                                "keyRaw": "code",
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            },

                                                            "description": {
                                                                "type": "String",
                                                                "keyRaw": "description",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "prompt": {
                                                                "type": "String",
                                                                "keyRaw": "prompt",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "entityType": {
                                                                "type": "EntityType",
                                                                "keyRaw": "entityType",
                                                                "visible": true
                                                            },

                                                            "tagPricingForService": {
                                                                "type": "TagPricing",
                                                                "keyRaw": "tagPricingForService(serviceId: $serviceId)",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "id": {
                                                                            "type": "PositiveInt",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        },

                                                                        "priceAdjustment": {
                                                                            "type": "String",
                                                                            "keyRaw": "priceAdjustment",
                                                                            "visible": true
                                                                        },

                                                                        "pricingType": {
                                                                            "type": "PricingType",
                                                                            "keyRaw": "pricingType",
                                                                            "visible": true
                                                                        },

                                                                        "serviceId": {
                                                                            "type": "PositiveInt",
                                                                            "keyRaw": "serviceId",
                                                                            "visible": true
                                                                        },

                                                                        "tagId": {
                                                                            "type": "PositiveInt",
                                                                            "keyRaw": "tagId",
                                                                            "visible": true
                                                                        },

                                                                        "postPriceAdjustment": {
                                                                            "type": "Boolean",
                                                                            "keyRaw": "postPriceAdjustment",
                                                                            "visible": true
                                                                        },

                                                                        "__typename": {
                                                                            "type": "String",
                                                                            "keyRaw": "__typename",
                                                                            "visible": true
                                                                        }
                                                                    },

                                                                    "fragments": {
                                                                        "TagPricingData": {
                                                                            "arguments": {}
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "ServiceTagData": {
                                                                "arguments": {
                                                                    "serviceId": {
                                                                        "kind": "Variable",

                                                                        "name": {
                                                                            "kind": "Name",
                                                                            "value": "serviceId"
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "__typename": {
                                                    "type": "String",
                                                    "keyRaw": "__typename",
                                                    "visible": true
                                                }
                                            },

                                            "fragments": {
                                                "ServiceAnswerData": {
                                                    "arguments": {
                                                        "serviceId": {
                                                            "kind": "Variable",

                                                            "name": {
                                                                "kind": "Name",
                                                                "value": "serviceId"
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "services": {
                                        "type": "Service",
                                        "keyRaw": "services",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "ServiceQuestionData": {
                                        "arguments": {
                                            "serviceId": {
                                                "kind": "Variable",

                                                "name": {
                                                    "kind": "Name",
                                                    "value": "serviceId"
                                                }
                                            }
                                        }
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "newServiceQuestion": "NewServiceQuestion",
            "serviceId": "PositiveInt"
        },

        "types": {
            "NewServiceQuestion": {
                "questionId": "PositiveInt",
                "serviceId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=27ae3affad661dd301dffec37123cc38a32396ab9971ffa4fc0f7308ac3631a7";