<script lang="ts">
	import Button from '@isoftdata/svelte-button'
	import type { GetQuoteStore, GetQuoteGoogleAddress } from 'utility/get-quote-helper'
	import GetQuoteSelectService from 'components/get-quote-components/GetQuoteSelectService.svelte'
	import GetQuoteAddress from 'components/get-quote-components/GetQuoteAddress.svelte'
	import GetQuoteAnswers from 'components/get-quote-components/GetQuoteAnswers.svelte'
	import GetQuotePrice from 'components/get-quote-components/GetQuotePrice.svelte'
	import GetQuoteCreateAccount from 'components/get-quote-components/GetQuoteCreateAccount.svelte'
	import type { Readable, Writable } from 'svelte/store'

	enum Steps {
		SERVICE_SELECTION = 'Service Selection',
		PROPERTY_ADDRESS = 'Property Address',
		QUESTIONS = 'Questions',
		PRICE_QUOTE = 'Price Quote',
		ACCOUNT_CREATION = 'Account Creation',
	}

	export let quoteData: Writable<GetQuoteStore>
	export let derivedSelectedAddress: Readable<GetQuoteGoogleAddress | undefined>
	export let derivedAllQuestionsAnswered: Readable<boolean>
	export let signupDisabledMessage: string

	let currentStep: Steps = Steps.SERVICE_SELECTION
</script>

<div class="container-lg container-fluid mt-3">
	<div class="d-flex justify-content-between align-items-end mb-2">
		<img
			src="images/lawnhiro-logo-horizontal.svg"
			style="height: 30px;"
			class="mb-2"
			alt="LawnHiroLogo"
		/>
		<Button
			class="float-right"
			outline
			color="primary"
			href={window.location.origin + '#/login'}
			target="_top"
		>
			Log In
		</Button>
	</div>
	<!-- Step 1-->
	<!-- This is the first step, this should always be enabled. -->
	<GetQuoteSelectService
		stepOpen={currentStep === Steps.SERVICE_SELECTION}
		disabled={false}
		{quoteData}
		on:show={() => {
			currentStep = Steps.SERVICE_SELECTION
		}}
		on:submit={() => {
			currentStep = Steps.PROPERTY_ADDRESS
		}}
	/>
	<!-- Step 2 -->
	<GetQuoteAddress
		stepOpen={currentStep === Steps.PROPERTY_ADDRESS}
		disabled={currentStep === Steps.SERVICE_SELECTION}
		{quoteData}
		{derivedSelectedAddress}
		on:show={() => {
			currentStep = Steps.PROPERTY_ADDRESS
		}}
		on:submit={() => {
			currentStep = $quoteData.questions.length > 0 ? Steps.QUESTIONS : Steps.PRICE_QUOTE
		}}
	/>
	{#if $quoteData.questions.length > 0}
		<!-- Step 3 -->
		<GetQuoteAnswers
			stepOpen={currentStep === Steps.QUESTIONS}
			disabled={currentStep === Steps.SERVICE_SELECTION || currentStep === Steps.PROPERTY_ADDRESS}
			{quoteData}
			{derivedAllQuestionsAnswered}
			{derivedSelectedAddress}
			on:show={() => {
				currentStep = Steps.QUESTIONS
			}}
			on:submit={() => {
				currentStep = Steps.PRICE_QUOTE
			}}
		/>
	{/if}
	<!-- Step 4 -->
	<GetQuotePrice
		stepOpen={currentStep === Steps.PRICE_QUOTE}
		disabled={currentStep === Steps.SERVICE_SELECTION || currentStep === Steps.PROPERTY_ADDRESS || currentStep === Steps.QUESTIONS}
		{quoteData}
		on:show={() => {
			currentStep = Steps.PRICE_QUOTE
		}}
		on:submit={() => {
			currentStep = Steps.ACCOUNT_CREATION
		}}
	/>
	<!-- Step 5-->
	<GetQuoteCreateAccount
		stepOpen={currentStep === Steps.ACCOUNT_CREATION}
		disabled={currentStep !== Steps.ACCOUNT_CREATION}
		{quoteData}
		{derivedSelectedAddress}
		{signupDisabledMessage}
		on:show={() => {
			$quoteData.userAccount.email = $quoteData.emailAddress
			currentStep = Steps.ACCOUNT_CREATION
		}}
	/>
</div>
