import { UpcomingOrdersCountStore, UpcomingOrders$result, UpcomingOrdersStore, graphql } from '$houdini'

const upcomingOrdersDataQuery: UpcomingOrdersStore = graphql`
	query UpcomingOrders($orderBy: [JobOrderBy!], $pagination: PaginatedInput) {
		upcomingOrders(orderBy: $orderBy, pagination: $pagination) {
			data {
				id
				residence {
					street
					city
					state
					zip
					country
				}
				jobStatus
				requestedSchedule
				service {
					name
					id
				}
				jobClaims {
					scheduledAt
					jobClaimStatus
				}
				answers {
					id
					answer
				}
				files {
					id
					jobId
					userAccountId
					fileId
					file {
						id
						name
						created
						updated
						hash
						path
						mimeType
						type
					}
					public
					rank
					imageFileType
				}
			}
		}
	}
`

const upcomingOrdersCountQuery: UpcomingOrdersCountStore = graphql`
	query UpcomingOrdersCount($orderBy: [JobOrderBy!], $pagination: PaginatedInput) {
		upcomingOrders(orderBy: $orderBy, pagination: $pagination) {
			info {
				totalItems
			}
		}
	}
`

export type UpcomingOrdersData = UpcomingOrders$result['upcomingOrders']['data'][number]
type OriginalFile = NonNullable<UpcomingOrdersData['files']>[number]
type FormattedFile = Omit<OriginalFile, 'file'> & {
	mimeType: string
	size: number
	name: string
}

export type FormattedUpcomingOrderData = Omit<UpcomingOrdersData, 'files'> & {
	files: FormattedFile[]
}

export async function getUpcomingOrders(): Promise<FormattedUpcomingOrderData[]> {
	const { data } = await upcomingOrdersDataQuery.fetch({
		variables: {
			pagination: {
				pageNumber: 1,
				pageSize: 0,
			},
		},
	})

	if (!data) {
		return []
	}

	return data.upcomingOrders.data.map(order => {
		const orderFiles: FormattedFile[] =
			order.files?.map(metadata => {
				const formattedData: FormattedFile = {
					...metadata,
					mimeType: metadata.file?.mimeType ?? '',
					size: 0,
					name: metadata.file?.name ?? '',
				}
				return formattedData
			}) ?? []
		return {
			...order,
			files: orderFiles ?? [],
		}
	})
}

export async function getUpcomingOrdersCount() {
	const { data } = await upcomingOrdersCountQuery.fetch({
		variables: {
			pagination: {
				pageNumber: 1,
				pageSize: 1,
			},
		},
	})

	if (!data) {
		return 0
	}

	return data.upcomingOrders.info.totalItems
}
