import { loadPermissions, loadUserAccounts } from 'utility/user-account-helper'
import component from './UserAccount.svelte'

import { AppContext } from 'types/common'
import { localWritable } from '@macfja/svelte-persistent-store'
import { get } from 'svelte/store'

export default ({ stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'app.admin.user-account',
		route: 'user-account',
		template: {
			component,
			svelte: true,
			options: {},
		},
		async resolve(_data, _parameters) {
			const userSavedPageSize = localWritable('admin-user-account-page-size', 100)
			const userSavedRolesFilter = localWritable<'CUSTOMER' | 'PROVIDER' | 'BOTH' | null>('admin-user-account-roles-filter', null)
			const userSavedActiveFilter = localWritable<boolean | null>('admin-user-account-active-filter', null)

			let rolesFilter: Array<'CUSTOMER' | 'PROVIDER'> | null = null

			switch (get(userSavedRolesFilter)) {
				case 'CUSTOMER':
					rolesFilter = ['CUSTOMER']
					break
				case 'PROVIDER':
					rolesFilter = ['PROVIDER']
					break
				case 'BOTH':
					rolesFilter = ['CUSTOMER', 'PROVIDER']
					break
				default:
					rolesFilter = null
					break
			}

			const [userAccountsRes, permissions] = await Promise.all([loadUserAccounts(get(userSavedActiveFilter), rolesFilter, { pageNumber: 1, pageSize: get(userSavedPageSize) }), loadPermissions()])
			const userAccounts = userAccountsRes.data
			const paginationInfo = userAccountsRes.info

			return {
				permissions,
				userAccounts,
				paginationInfo,
				userSavedPageSize,
				userSavedRolesFilter,
				userSavedActiveFilter,
				pageNumber: 1,
			}
		},
	})
}
