export default {
    "name": "UserAccountReactivate",
    "kind": "HoudiniMutation",
    "hash": "da72cf56ad3ef9fe3ee34501f58b226bd5f8c8b4d995996c407f423b30793492",

    "raw": `mutation UserAccountReactivate($userAccountId: Float!) {
  userAccountReactivate(userAccountId: $userAccountId) {
    id
    mobile
    created
    email
    firstName
    lastName
    fullName
    status
    customer {
      id
    }
    provider {
      id
      status
      profilePictureFile {
        id
        path
      }
    }
    userRoles {
      permissionName
      value
    }
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "userAccountReactivate": {
                "type": "UserAccount",
                "keyRaw": "userAccountReactivate(userAccountId: $userAccountId)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "mobile": {
                            "type": "PhoneNumber",
                            "keyRaw": "mobile",
                            "nullable": true,
                            "visible": true
                        },

                        "created": {
                            "type": "DateTime",
                            "keyRaw": "created",
                            "visible": true
                        },

                        "email": {
                            "type": "EmailAddress",
                            "keyRaw": "email",
                            "visible": true
                        },

                        "firstName": {
                            "type": "String",
                            "keyRaw": "firstName",
                            "visible": true
                        },

                        "lastName": {
                            "type": "String",
                            "keyRaw": "lastName",
                            "visible": true
                        },

                        "fullName": {
                            "type": "String",
                            "keyRaw": "fullName",
                            "nullable": true,
                            "visible": true
                        },

                        "status": {
                            "type": "UserAccountStatus",
                            "keyRaw": "status",
                            "visible": true
                        },

                        "customer": {
                            "type": "Customer",
                            "keyRaw": "customer",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "provider": {
                            "type": "Provider",
                            "keyRaw": "provider",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "status": {
                                        "type": "ProviderStatus",
                                        "keyRaw": "status",
                                        "visible": true
                                    },

                                    "profilePictureFile": {
                                        "type": "FileEntity",
                                        "keyRaw": "profilePictureFile",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "path": {
                                                    "type": "String",
                                                    "keyRaw": "path",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "userRoles": {
                            "type": "UserRole",
                            "keyRaw": "userRoles",

                            "selection": {
                                "fields": {
                                    "permissionName": {
                                        "type": "NonEmptyString",
                                        "keyRaw": "permissionName",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "String",
                                        "keyRaw": "value",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "userAccountId": "Float"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=1e044879e6d550b1c8631e8e03f1c36aa6d5279789146aa2ed6147e5cf162aa4";