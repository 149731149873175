<script lang="ts">
	import {
		deleteAnswerFromQuestion,
		editAnswerMutation,
		editTagPricingMutation,
		loadQuestions,
		loadTags,
		newAnswerMutation,
		newQuestionMutation,
		newServiceQuestionMutation,
		newTagMutation,
		newTagPricingMutation,
		removeQuestionFromServiceMutation,
		tagsQuery,
		type AdminServiceQuestion,
		type AdminServiceTag,
		type SelectedQuestionAnswer,
	} from 'utility/service-helper'
	import Button from '@isoftdata/svelte-button'
	import Modal from '@isoftdata/svelte-modal'
	import Select from '@isoftdata/svelte-select'
	import Input from '@isoftdata/svelte-input'
	import TextArea from '@isoftdata/svelte-textarea'
	import CurrencyInput from '@isoftdata/svelte-currency-input'

	import Table, { Td, type Column } from '@isoftdata/svelte-table'
	import formatCurrency from 'utility/format/format-currency'
	import { toDisplay, toSave } from 'utility/format/format-percent'
	import { tagPricingTypeList } from 'utility/tag-pricing-types'
	import { questionDataTypeList } from 'utility/question-data-types'

	import { getContext } from 'svelte'
	import type { Mediator } from 'types/common'
	import { klona } from 'klona'
	import type { WritableDeep } from 'type-fest'
	import { upsert } from '@isoftdata/utility-array'
	import toTitleCase from 'to-title-case'

	const mediator = getContext<Mediator>('mediator')

	type ServiceQuestionModal = {
		selectedQuestionId: number | null
		question: string
		description: string
		questionDataType: 'CHOICE' | 'BOOLEAN'
		questions: AdminServiceQuestion[]
		isEditingServiceQuestion: boolean
		show: boolean
		errorMessage: string
	}

	type AnswerModal = {
		answer: string
		answerId: number | null
		tagOptionList: AdminServiceTag[]
		selectedTagId: number | null
		tagCode: string | null
		tagName: string | null
		tagDescription: string | null
		tagPrompt: string | null
		tagEntityType: AdminServiceTag['entityType']
		tagPricingId: number | null | undefined
		pricingType: Exclude<WritableDeep<AdminServiceTag['tagPricingForService']>, null>['pricingType']
		priceAdjustment: string
		postPriceAdjustment: boolean
		questionDataType: 'CHOICE' | 'BOOLEAN'
		errorMessage: string
		editing: boolean
		show: boolean
	}

	const defaultServiceQuestionModalState: ServiceQuestionModal = Object.freeze({
		selectedQuestionId: null,
		question: '',
		description: '',
		questionDataType: 'CHOICE',
		questions: [],
		isEditingServiceQuestion: false,
		show: false,
		errorMessage: '',
	})

	const defaultAnswerModalState: AnswerModal = Object.freeze({
		answer: '',
		answerId: null,
		tagOptionList: [],
		selectedTagId: null,
		tagCode: null,
		tagName: null,
		tagDescription: '',
		tagPrompt: '',
		tagEntityType: 'RESIDENCE',
		tagPricingId: null,
		pricingType: 'PERCENT',
		priceAdjustment: '',
		postPriceAdjustment: false,
		questionDataType: 'CHOICE',
		errorMessage: '',
		editing: false,
		show: false,
	})

	export let serviceQuestions: WritableDeep<AdminServiceQuestion>[] = []
	export let selectedServiceId: number
	export let selectedQuestionId: number | null = null
	export let newAnswerAllowed: boolean = false
	export let selectedQuestion: AdminServiceQuestion | null = null

	let selectedQuestionAnswers: SelectedQuestionAnswer[] = []
	let answerModal: AnswerModal = klona(defaultAnswerModalState)
	let serviceQuestionModal: ServiceQuestionModal = klona(defaultServiceQuestionModalState)

	$: selectedServiceResidenceQuestions = serviceQuestions.filter(question => question.questionDataType === 'CHOICE') ?? []
	$: selectedServiceAddOns = serviceQuestions.filter(question => question.questionDataType === 'BOOLEAN') ?? []
	$: newAnswerAllowed = !(selectedQuestion?.questionDataType === 'BOOLEAN' && selectedQuestion.answers && selectedQuestion?.answers.length > 0)
	$: isMakingNewQuestion = serviceQuestionModal.selectedQuestionId === null
	$: isMakingNewTag = answerModal.selectedTagId === null

	const columns: Column<SelectedQuestionAnswer>[] = [
		{ property: 'answer', name: 'Answer' },
		{ property: 'tag[name]', name: 'Tag' },
		{ property: 'tag[tagPricingForService][priceAdjustment]', name: 'Pricing' },
		{ property: 'tag[tagPricingForService][pricingType]', name: 'Type' },
		{ property: 'action', name: 'Actions', sortType: false },
	]

	function selectQuestionId(id: number) {
		if (selectedQuestionId === id) {
			selectedQuestionId = null
			selectedQuestion = null
			selectedQuestionAnswers = []
		} else {
			selectedQuestionId = id
			selectedQuestion = serviceQuestions.find(question => question.id === id) ?? null
			selectedQuestionAnswers = selectedQuestion?.answers ?? []
		}
	}

	async function removeQuestionFromService() {
		if (!selectedQuestionId) {
			return
		}
		if (confirm(`Are you sure you want to remove the "${selectedQuestion?.question}" question from this service?`)) {
			try {
				const { data } = await removeQuestionFromServiceMutation.mutate({
					removeServiceQuestion: {
						serviceId: selectedServiceId,
						questionId: selectedQuestionId,
					},
					serviceId: selectedServiceId,
				})

				if (!data) {
					throw new Error('No data returned from mutation')
				}

				serviceQuestions = data?.removeQuestionFromService.questions ?? []
				selectedQuestionId = null
				selectedQuestion = null
				selectedQuestionAnswers = []
			} catch (err: unknown) {
				const error = err as Error
				console.error(error)
				mediator.call('showError', error, { message: 'Error removing question from service', title: 'Error Removing Question' })
			}
		}
	}

	async function loadQuestionsForServiceModal() {
		const questions = await loadQuestions()
		const filteredQuestions = questions
			.filter(question => !serviceQuestions.find(serviceQuestion => serviceQuestion.id === question.id))
			.filter(question => serviceQuestionModal.questionDataType === question.questionDataType)
		return filteredQuestions
	}

	async function openAddServiceQuestionModal() {
		const filteredQuestions = await loadQuestionsForServiceModal()
		serviceQuestionModal = {
			...defaultServiceQuestionModalState,
			questions: filteredQuestions,
			show: true,
		}

		const selectElement = document.getElementById('#questionDataTypeSelect')
		if (selectElement) {
			selectElement.focus()
		}
	}

	async function removeAnswerFromQuestion(id: number, answer: string) {
		const question = serviceQuestions.find(question => question.id === selectedQuestionId)?.question || 'Unkown Question'
		if (confirm(`Are you sure you want to delete this answer "${answer}" for question "${question}" ?`)) {
			try {
				await deleteAnswerFromQuestion(id)

				const questionIndex = serviceQuestions.findIndex(question => question.id === selectedQuestionId)
				const answerIndex = serviceQuestions[questionIndex].answers?.findIndex(answer => answer.id === id)
				if (answerIndex !== undefined && answerIndex !== -1) {
					serviceQuestions[questionIndex].answers?.splice(answerIndex, 1)
					serviceQuestions[questionIndex].answers = serviceQuestions[questionIndex].answers
					if (serviceQuestions[questionIndex].answers) {
						selectedQuestionAnswers = serviceQuestions[questionIndex].answers
					}
				} else {
					throw new Error('Answer not found in question')
				}
			} catch (err: unknown) {
				const error = err as Error
				console.error(error)
				mediator.call('showError', error, { message: `Error deleting answer ${answer}`, title: 'Error Deleting Answer' })
			}
		}
	}

	async function openEditAnswerModal(modalState: AnswerModal) {
		await openAnswerModal({
			...modalState,
			answer: modalState.answer,
			answerId: modalState.answerId,
			selectedTagId: modalState?.selectedTagId,
			tagCode: modalState?.tagCode,
			tagPricingId: modalState.tagPricingId,
			pricingType: modalState.pricingType,
			priceAdjustment: modalState.priceAdjustment,
			postPriceAdjustment: modalState.postPriceAdjustment,
			editing: true,
		})
	}

	async function openAnswerModal(modalState: AnswerModal = { ...defaultAnswerModalState }) {
		const tags: AdminServiceTag[] = await loadTags(null, selectedQuestion?.questionDataType === 'CHOICE' ? 'RESIDENCE' : 'JOB')
		modalState.tagOptionList = tags.reduce((acc: AdminServiceTag[], tag: AdminServiceTag) => {
			if (modalState.editing) {
				if (tag.id === modalState.selectedTagId) {
					acc.push(tag)
					return acc
				}
			} else if (!selectedQuestion?.answers?.some(answer => answer.tag.id === tag.id)) {
				acc.push(tag)
			}
			return acc
		}, new Array<AdminServiceTag>())

		answerModal = {
			...klona(defaultAnswerModalState),
			...klona(modalState),
			tagEntityType: selectedQuestion?.questionDataType === 'CHOICE' ? 'RESIDENCE' : 'JOB',
			show: true,
		}
		const answerInput = document.getElementById('#answerInput') as HTMLInputElement | undefined
		if (answerInput) {
			answerInput.select()
		}
	}

	async function saveServiceQuestion() {
		let questionId: number | null = null
		if (isMakingNewQuestion) {
			try {
				const { data } = await newQuestionMutation.mutate({
					question: {
						question: serviceQuestionModal.question,
						description: serviceQuestionModal.description,
						questionDataType: serviceQuestionModal.questionDataType,
					},
				})

				if (!data) {
					throw new Error('No data returned from mutation')
				}

				questionId = data.newQuestion.id
			} catch (err: unknown) {
				const error = err as Error
				console.error(error)
				serviceQuestionModal.errorMessage = error.message
				return
			}
		} else {
			questionId = serviceQuestionModal.selectedQuestionId
		}

		if (questionId) {
			try {
				const { data } = await newServiceQuestionMutation.mutate({
					newServiceQuestion: {
						serviceId: selectedServiceId,
						questionId,
					},
					serviceId: selectedServiceId,
				})

				if (!data) {
					throw new Error('No data returned from mutation')
				}

				serviceQuestions = data?.addQuestionToService.questions ?? []

				serviceQuestionModal = klona(defaultServiceQuestionModalState)
				selectQuestionId(questionId)
			} catch (err: unknown) {
				const error = err as Error
				console.error(error)
				mediator.call('showError', error, { message: 'Error adding question to service', title: 'Error Adding Question' })
			}
		}
	}

	async function saveAnswer() {
		try {
			let savedAnswer: WritableDeep<SelectedQuestionAnswer> | null = null
			let savedTag: AdminServiceTag | null = null
			let savedTagPricing: AdminServiceTag['tagPricingForService'] | null = null
			let tagId: number = 0
			if (answerModal.selectedTagId) {
				const { data } = await tagsQuery.fetch({
					variables: {
						filter: {
							id: answerModal.selectedTagId,
						},
					},
				})

				if (!data) {
					throw new Error('No data returned from query')
				}

				savedTag = data.tags[0]
			} else if (answerModal.tagCode && answerModal.tagName) {
				const { data } = await newTagMutation.mutate({
					tag: {
						entityType: answerModal.tagEntityType,
						code: answerModal.tagCode,
						name: answerModal.tagName,
						description: answerModal.tagDescription,
						prompt: answerModal.tagPrompt,
					},
				})

				if (!data) {
					throw new Error('No data returned from mutation')
				}

				savedTag = data.newTag
			} else {
				throw new Error('No tag data to save')
			}

			if (savedTag) {
				tagId = savedTag.id
			} else if (answerModal.selectedTagId) {
				tagId = answerModal.selectedTagId
			} else {
				throw new Error('No tag data to save')
			}

			if (answerModal.editing && answerModal.answerId) {
				const { data } = await editAnswerMutation.mutate({
					editAnswer: {
						id: answerModal.answerId,
						answer: answerModal.answer,
						tagId,
					},
				})

				if (!data) {
					throw new Error('No data returned from mutation')
				}

				savedAnswer = data.editAnswer
			} else if (selectedQuestionId) {
				const { data } = await newAnswerMutation.mutate({
					answer: {
						answer: answerModal.answer,
						tagId,
						questionId: selectedQuestionId,
					},
				})

				if (!data) {
					throw new Error('No data returned from mutation')
				}

				savedAnswer = data.newAnswer
			} else {
				throw new Error('No tag or question data to save')
			}
			answerModal.priceAdjustment = answerModal.priceAdjustment.replace('%', '') || '0'
			if (answerModal.tagPricingId) {
				const { data } = await editTagPricingMutation.mutate({
					tagPricing: {
						tagPricingId: answerModal.tagPricingId,
						pricingType: answerModal.pricingType,
						priceAdjustment: answerModal.pricingType === 'PERCENT' ? toSave(parseFloat(answerModal.priceAdjustment)) : answerModal.priceAdjustment,
						postPriceAdjustment: answerModal.postPriceAdjustment,
					},
				})

				if (!data) {
					throw new Error('No data returned from mutation')
				}

				savedTagPricing = data.editTagPricing

				const questionIndex = serviceQuestions.findIndex(question => question.id === selectedQuestionId)
				if (questionIndex === -1) {
					throw new Error('Question not found')
				}

				if (serviceQuestions[questionIndex].answers) {
					serviceQuestions[questionIndex].answers = upsert(serviceQuestions[questionIndex].answers, 'id', savedAnswer)
					serviceQuestions[questionIndex].answers = serviceQuestions[questionIndex].answers
					selectedQuestionAnswers = serviceQuestions[questionIndex].answers
				}
			} else if (parseFloat(answerModal.priceAdjustment) === 0 || '') {
				savedTagPricing = null
			} else {
				const { data } = await newTagPricingMutation.mutate({
					tagPricing: {
						tagId,
						serviceId: selectedServiceId,
						pricingType: answerModal.pricingType,
						priceAdjustment: answerModal.pricingType === 'PERCENT' ? toSave(parseFloat(answerModal.priceAdjustment)) : answerModal.priceAdjustment,
						postPriceAdjustment: answerModal.postPriceAdjustment,
					},
				})

				if (!data) {
					throw new Error('No data returned from mutation')
				}

				savedTagPricing = data.newTagPricing
				const questionIndex = serviceQuestions.findIndex(question => question.id === selectedQuestionId)
				if (questionIndex === -1) {
					throw new Error('Question not found')
				}
				if (serviceQuestions[questionIndex].answers) {
					serviceQuestions[questionIndex].answers = upsert(serviceQuestions[questionIndex].answers, 'id', savedAnswer)
					serviceQuestions[questionIndex].answers = serviceQuestions[questionIndex].answers
					selectedQuestionAnswers = serviceQuestions[questionIndex].answers
				}
			}
			answerModal = klona(defaultAnswerModalState)

			savedAnswer.tag.tagPricingForService = savedTagPricing
		} catch (err: unknown) {
			const error = err as Error
			console.error(error)
			answerModal.errorMessage = error.message
			return
		}
	}

	async function updatedQuestionDataType() {
		serviceQuestionModal.questions = await loadQuestionsForServiceModal()
	}
</script>

<div class="card-group">
	<div class="card">
		<div class="card-header p-2">Service Questions/Add-ons</div>
		<div class="card-body">
			{#if selectedServiceResidenceQuestions.length > 0}
				<ul class="list-group">
					{#each selectedServiceResidenceQuestions as residenceQuestion}
						<button
							class="list-group-item cursor-pointer d-flex justify-content-between align-items-center"
							class:active={selectedQuestionId === residenceQuestion.id}
							on:click={() => selectQuestionId(residenceQuestion.id)}
						>
							{residenceQuestion.question}
							<span class="badge badge-info badge-pill">Residence Question</span>
						</button>
					{/each}
				</ul>
			{/if}
			<hr class:d-none={selectedServiceResidenceQuestions.length === 0 || selectedServiceAddOns.length === 0} />
			{#if selectedServiceAddOns.length > 0}
				<ul class="list-group">
					{#each selectedServiceAddOns as addOn}
						<button
							class="list-group-item cursor-pointer d-flex justify-content-between align-items-center"
							class:active={selectedQuestionId === addOn.id}
							on:click={() => selectQuestionId(addOn.id)}
						>
							{addOn.question}
							<span class="badge badge-success badge-pill">Add-on</span>
						</button>
					{/each}
				</ul>
			{/if}
			{#if serviceQuestions.length === 0}
				There's no questions or add-ons for this service
			{/if}
		</div>
		<div class="card-footer d-flex justify-content-between p-2">
			<Button
				size="sm"
				iconClass="plus"
				on:click={() => openAddServiceQuestionModal()}
			>
				Add New Question/Add-on
			</Button>
			<Button
				size="sm"
				color="danger"
				iconClass="trash"
				on:click={() => removeQuestionFromService()}
				disabled={selectedQuestionId === null}
			>
				{#if selectedQuestion?.questionDataType === 'BOOLEAN'}
					Remove Add-on
				{:else}
					Remove Question
				{/if}
			</Button>
		</div>
	</div>
	<div class="card">
		<div class="card-header p-2">
			{#if selectedQuestion}
				Answers for "{selectedQuestion.question}"
			{:else}
				Select a Question/Add-on
			{/if}
		</div>
		<div class="card-body">
			{#if selectedQuestion}
				<Table
					responsive
					class="mb-0"
					filterEnabled={false}
					{columns}
					rows={selectedQuestionAnswers}
					let:row
				>
					<svelte:fragment slot="no-rows">
						<tr>
							<td
								colspan="5"
								class="text-center">No answers found for this question</td
							>
						</tr>
					</svelte:fragment>
					<tr>
						<Td property="answer">{row.answer}</Td>
						<Td property="tag[name]">{row.tag.name}</Td>
						<Td property="tag[tagPricingForService][priceAdjustment]"
							>{row.tag.tagPricingForService?.pricingType === 'PERCENT'
								? toDisplay(row.tag.tagPricingForService.priceAdjustment)
								: formatCurrency(row.tag.tagPricingForService?.priceAdjustment, '')}</Td
						>
						<Td property="tag[tagPricingForService][pricingType]">{row.tag.tagPricingForService?.pricingType ? toTitleCase(row.tag.tagPricingForService.pricingType) : ''}</Td>
						<Td property="action">
							<div class="d-flex">
								<Button
									outline
									size="sm"
									color="primary"
									class="mr-1"
									iconClass="pen"
									on:click={() =>
										openEditAnswerModal({
											answer: row.answer,
											answerId: row.id,
											selectedTagId: row.tag.id,
											tagCode: row.tag.code,
											tagName: row.tag.name,
											tagDescription: row.tag.description,
											tagPrompt: row.tag.prompt,
											tagEntityType: row.tag.entityType,
											tagPricingId: row.tag.tagPricingForService?.id,
											pricingType: row.tag.tagPricingForService?.pricingType ?? 'PERCENT',
											priceAdjustment: row.tag.tagPricingForService?.priceAdjustment ?? '',
											postPriceAdjustment: row.tag.tagPricingForService?.postPriceAdjustment ?? false,
											questionDataType: selectedQuestion?.questionDataType ?? 'CHOICE',
											editing: true,
											tagOptionList: [],
											errorMessage: '',
											show: false,
										})}
								/>
								<Button
									outline
									size="sm"
									color="danger"
									iconClass="trash"
									on:click={() => removeAnswerFromQuestion(row.id, row.answer)}
								/>
							</div>
						</Td>
					</tr>
				</Table>
			{:else}
				Select a question to view answer options.<br />
				OR<br />
				Select an add-on to view add-on pricing.
			{/if}
		</div>
		<div class="card-footer p-2">
			<Button
				size="sm"
				color="primary"
				iconClass="plus"
				on:click={() => openAnswerModal()}
				disabled={!selectedQuestionId || !newAnswerAllowed}
			>
				Add Answer Option
			</Button>
		</div>
	</div>
</div>

<Modal
	bind:show={serviceQuestionModal.show}
	title="Add New Question/Add-on"
	cancelButtonText="Cancel"
	confirmButtonDisabled={!serviceQuestionModal.question && isMakingNewQuestion}
	confirmButtonText={serviceQuestionModal.questionDataType === 'BOOLEAN' ? 'Add Add-on' : 'Add Question'}
	on:close={() => (serviceQuestionModal.show = false)}
	on:backdrop={() => (serviceQuestionModal.show = false)}
	on:confirm={() => saveServiceQuestion()}
>
	<div class="form-row">
		<div class="col-12">
			<Select
				id="questionDataTypeSelect"
				label="Select a Type"
				bind:value={serviceQuestionModal.questionDataType}
				showEmptyOption={false}
				on:change={() => {
					updatedQuestionDataType()
				}}
			>
				{#each questionDataTypeList as type}
					<option value={type.value}>{type.label}</option>
				{/each}
			</Select>
		</div>
		<div class="col-12">
			<Select
				label={serviceQuestionModal.questionDataType === 'BOOLEAN' ? 'Add-on' : 'Question'}
				bind:value={serviceQuestionModal.selectedQuestionId}
				emptyText={serviceQuestionModal.questionDataType === 'BOOLEAN' ? 'New Add-on' : 'New Question'}
				emptyValue={null}
			>
				{#each serviceQuestionModal.questions as question}
					<option value={question.id}>{question.question}</option>
				{/each}
			</Select>
		</div>
		{#if isMakingNewQuestion}
			{#if serviceQuestionModal.questionDataType === 'BOOLEAN'}
				<div class="col-12">
					<Input
						id="addOnNameInput"
						label="Add-on Name"
						bind:value={serviceQuestionModal.question}
					/>
				</div>
			{:else}
				<div class="col-12">
					<TextArea
						id="questionTextArea"
						label="Question Text"
						bind:value={serviceQuestionModal.question}
						labelClass="py-0 mb-2"
						textreaStyle="min-height: 83px;"
						placeholder="Write your question here..."
					/>
				</div>
			{/if}
			<div class="col-12">
				<TextArea
					id="descriptionTextArea"
					label="Description"
					labelClass="py-0 mb-2"
					textareaStyle="min-height: 83px;"
					bind:value={serviceQuestionModal.description}
					placeholder={`Information about this ${serviceQuestionModal.questionDataType === 'BOOLEAN' ? 'add-on' : 'question'} admin-only`}
				/>
			</div>
		{/if}
		{#if serviceQuestionModal.errorMessage}
			<div
				style="opacity: 1;"
				class="signin-message-box text-center alert alert-danger"
				role="alert"
			>
				<i class="fas fa-exclamation-circle"></i> <strong style="font-size: x-small">{serviceQuestionModal.errorMessage}</strong>
			</div>
		{/if}
	</div>
</Modal>

<Modal
	bind:show={answerModal.show}
	title={answerModal.editing ? 'Update Answer' : 'Add New Answer'}
	cancelButtonText="Cancel"
	confirmButtonDisabled={!answerModal.answer || (isMakingNewTag && !(answerModal.answer && answerModal.tagCode && answerModal.tagName))}
	confirmButtonText={answerModal.editing ? 'Save Answer' : 'Add Answer'}
	on:close={() => (answerModal.show = false)}
	on:backdrop={() => (answerModal.show = false)}
	on:confirm={() => saveAnswer()}
>
	<div class="form-row mb-2">
		<div class="col-12 col-sm-6">
			<Input
				id="answerInput"
				label="Answer Text"
				bind:value={answerModal.answer}
			/>
		</div>
		<div class="col-12 col-sm-6">
			<Select
				label="Tag"
				bind:value={answerModal.selectedTagId}
				emptyText="New Tag"
				emptyValue={null}
				disabled={answerModal.editing}
			>
				{#each answerModal.tagOptionList as tag}
					<option value={tag.id}>{tag.name}</option>
				{/each}
			</Select>
		</div>
		{#if isMakingNewTag}
			<div class="col-6">
				<Input
					required
					id="tagCode"
					label="Tag Code"
					bind:value={answerModal.tagCode}
					validation={{
						value: answerModal.tagCode,
					}}
				/>
			</div>
			<div class="col-6">
				<Input
					required
					id="tagName"
					label="Tag Name"
					bind:value={answerModal.tagName}
					validation={{
						value: answerModal.tagName,
					}}
				/>
			</div>
			<div class="col-12">
				<Input
					id="tagDescription"
					label="Tag Description"
					bind:value={answerModal.tagDescription}
				/>
			</div>
			<div class="col-12">
				<TextArea
					id="promptTextArea"
					label="Prompt"
					labelClass="py-0 mb-2"
					textareaStyle="min-height: 83px;"
					placeholder="A admin only description about the tag..."
					bind:value={answerModal.tagPrompt}
				/>
			</div>
		{/if}
		<div class="col-6">
			<Select
				id="tagPricingModelPricingTypeSelect"
				label="Pricing Type"
				bind:value={answerModal.pricingType}
				showEmptyOption={false}
				on:change={() => {
					answerModal.priceAdjustment = '0'
				}}
			>
				{#each tagPricingTypeList as type}
					<option value={type.value}>{type.label}</option>
				{/each}
			</Select>
		</div>
		<div class="col-6">
			{#if answerModal.pricingType === 'PERCENT'}
				<Input
					required
					id="priceAdjustmentPercentage"
					label="Percentage Amount"
					bind:value={answerModal.priceAdjustment}
					placeholder="0"
					max={answerModal.postPriceAdjustment ? '-1' : ''}
					validation={{
						value: answerModal.priceAdjustment,
					}}
				/>
			{:else}
				<CurrencyInput
					required
					label="Flat Amount"
					bind:value={answerModal.priceAdjustment}
					inputmode="decimal"
					validation={{
						value: answerModal.priceAdjustment,
					}}
				/>
			{/if}
		</div>
	</div>
	<small class="text-info"> Enter a negative value for a discount. </small>
	{#if answerModal.errorMessage}
		<div
			style="opacity: 1;"
			class="signin-message-box text-center alert alert-danger"
			role="alert"
		>
			<i class="fas fa-exclamation-circle"></i> <strong style="font-size: x-small">{answerModal.errorMessage}</strong>
		</div>
	{/if}
</Modal>
