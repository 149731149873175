<script lang="ts">
	import formatCityStateZip from 'utility/format/format-city-state-zip'
	import Button from '@isoftdata/svelte-button'
	import {
		cancelRecurringSchedule,
		loadCustomerResidenceCompletedScheduleJobs,
		loadSelectedCustomerResidenceServiceSchedules,
		type CompletedServiceSchedule,
		type CustomerServiceSchedule,
		type SelectedServiceSchedule,
	} from 'utility/recurring-order-helper'
	import { differenceInDays, startOfDay } from 'date-fns'
	import type { JobStatus$options } from '$houdini/graphql'
	import GenericBadge from 'components/GenericBadge.svelte'
	import Throbber from 'components/Throbber.svelte'
	import CollapsibleCard from '@isoftdata/svelte-collapsible-card'
	import { onMount } from 'svelte'
	import type { WritableDeep } from 'type-fest'

	export let customerRecurringSchedules: CustomerServiceSchedule[] = []
	export let selectedResidence: CustomerServiceSchedule | null = null

	type ComputedServiceSchedule = SelectedServiceSchedule & {
		serviceId: number
		serviceName: string
		cityStateZipFormatted: string
		currentServiceDate: string | undefined
		nextJobDate: string | undefined
		displayEndOfSchedule: string | undefined
		serviceSchedulePeriodValue: number
		status: string
		jobsCompleted: number
		totalNumOfJobs: number
		mostRecentJobStatus: JobStatus$options | undefined
		numOfCancelledJobs: number
	}

	let selectedResidenceId: number | null = null
	let selectedResidenceSchedules: WritableDeep<SelectedServiceSchedule>[] = []
	let completedScheduleJobs: CompletedServiceSchedule[] = []
	let showServiceDetails = false
	let selectedServiceId: number | null = null
	let loadingSubData = false
	let openSubscriptionCard: number | null = null

	function getClosestJob(
		jobList: {
			id: number
			requestedSchedule: Date
			jobStatus: JobStatus$options
			created: Date
		}[],
	) {
		const reducedList = jobList.reduce(
			(
				acc: {
					diff: number
					job: {
						id: number
						requestedSchedule: Date
						jobStatus: JobStatus$options
						created: Date
					} | null
				},
				job,
			) => {
				const diff = Math.abs(differenceInDays(new Date(job.created), new Date()))
				if (diff < acc.diff) {
					acc.diff = diff
					acc.job = job
				}
				return acc
			},
			{ diff: Infinity, job: null },
		)
		return reducedList.job
	}

	onMount(() => {
		// This looks stupid but just calling the function dosn't show the details since the details is not computed till after the button is rendered and this makes it work so :shrug
		clickDefaultOnPageLoad()
	})

	async function clickDefaultOnPageLoad() {
		if (customerRecurringSchedules.length > 0) {
			const residenceIdButton = document.getElementById(`residence-${customerRecurringSchedules[0].residence.id}-button`)
			if (residenceIdButton) {
				residenceIdButton.click()
			}
		}
	}

	type GroupedSchedulesByResidenceObject = {
		[key: number]: {
			residenceId: number
			residenceStreet: string
			residenceCityState: string
			residenceScheduleCount: number
		}
	}

	$: groupSchedulesByResidence = customerRecurringSchedules.reduce((acc: GroupedSchedulesByResidenceObject, schedule) => {
		if (acc[schedule.residence.id] && schedule.status === 'ACTIVE') {
			acc[schedule.residence.id].residenceScheduleCount++
		} else if (schedule.status === 'ACTIVE') {
			acc[schedule.residence.id] = {
				residenceId: schedule.residence.id,
				residenceStreet: schedule.residence.street,
				residenceCityState: formatCityStateZip(
					{
						city: schedule.residence.city,
						state: schedule.residence.state,
						zip: schedule.residence.zip,
					},
					false,
				),
				residenceScheduleCount: 1,
			}
		}
		return acc
	}, {})

	$: computedServiceSchedule = selectedResidenceSchedules.reduce((services: ComputedServiceSchedule[], serviceSchedule) => {
		const completedJobsForService = completedScheduleJobs.filter(completedScheduleJob => completedScheduleJob.serviceScheduleId === serviceSchedule.id)
		const completedJobsForServiceCount = completedJobsForService.length

		const period = serviceSchedule.serviceSchedulePeriod.serviceSchedulePeriod
		const numOfDaysFromFirstToLast = differenceInDays(serviceSchedule.endOfSchedule, startOfDay(serviceSchedule.created))
		const totalNumOfJobs = Math.floor(numOfDaysFromFirstToLast / period) + 1 // +1 to include the first job
		const validJobsToCheck = serviceSchedule.jobs?.filter(job => job.jobStatus !== 'EXPIRED') || []
		const numOfCancelledJobs = validJobsToCheck.filter(job => job.jobStatus === 'CANCELLED').length
		const mostRecentJobCreatedBySchedule = getClosestJob(validJobsToCheck)
		services.push({
			...serviceSchedule,
			serviceId: serviceSchedule.service.id,
			serviceName: serviceSchedule.service.name,
			cityStateZipFormatted: formatCityStateZip({
				city: serviceSchedule.residence.city,
				state: serviceSchedule.residence.state,
				zip: serviceSchedule.residence.zip,
			}),
			currentServiceDate: mostRecentJobCreatedBySchedule?.requestedSchedule.toLocaleDateString(navigator.language || 'en-US'),
			nextJobDate: serviceSchedule.nextJob.toLocaleDateString(navigator.language || 'en-US'),
			displayEndOfSchedule: serviceSchedule.endOfSchedule.toLocaleDateString(navigator.language || 'en-US'),
			serviceSchedulePeriodValue: serviceSchedule.serviceSchedulePeriod.serviceSchedulePeriod,
			status: serviceSchedule.status,
			jobsCompleted: completedJobsForServiceCount,
			totalNumOfJobs,
			mostRecentJobStatus: mostRecentJobCreatedBySchedule?.jobStatus,
			numOfCancelledJobs,
		})

		return services
	}, [])

	async function selectResidence(residenceId: number) {
		if (selectedResidenceId === residenceId) {
			selectedResidenceId = null
			selectedResidence = null
			selectedResidenceSchedules = []
			completedScheduleJobs = []
			showServiceDetails = false
			selectedServiceId = null
		} else {
			loadingSubData = true
			selectedResidenceId = residenceId
			selectedResidence = customerRecurringSchedules.find(schedule => schedule.residence.id === residenceId) || null
			try {
				selectedResidenceSchedules = await loadSelectedCustomerResidenceServiceSchedules(residenceId)
				completedScheduleJobs = await loadCustomerResidenceCompletedScheduleJobs(residenceId)
				showServiceDetails = selectedResidenceSchedules.length === 1
				selectedServiceId = selectedResidenceSchedules.length === 1 ? selectedResidenceSchedules[0].service.id : null
			} catch (err: unknown) {
				const error = err as Error
				console.error(error.message)
			}
			loadingSubData = false
		}
	}

	async function cancelRecurringScheduleOrder(id: number) {
		try {
			await cancelRecurringSchedule(id)

			const selectedServiceScheduleIndex = selectedResidenceSchedules.findIndex(schedule => schedule.id === id)
			if (selectedServiceScheduleIndex !== -1) {
				selectedResidenceSchedules[selectedServiceScheduleIndex].status = 'DISABLED'
			}
			selectedResidenceSchedules = selectedResidenceSchedules
			customerRecurringSchedules = customerRecurringSchedules.map(schedule => {
				if (schedule.id === id) {
					schedule.status = 'DISABLED'
				}
				return schedule
			})
		} catch (err: unknown) {
			const error = err as Error
			console.error(error.message)
		}
	}
</script>

<div class="row">
	{#if Object.keys(groupSchedulesByResidence).length === 0}
		<div class="col-12 text-center mt-2">
			<h4>You currently don't have any subscription</h4>
		</div>
	{:else}
		<div class="col-lg-4 col-xl-3">
			<div>
				<h4>Residences</h4>
			</div>
			<div
				class="list-group"
				style="overflow-y: auto; max-height: 50vh;"
			>
				{#each Object.keys(groupSchedulesByResidence) as residenceId}
					<button
						id={`residence-${residenceId}-button`}
						class="list-group-item list-group-item-action"
						class:active={selectedResidenceId === parseInt(residenceId, 10)}
						disabled={loadingSubData}
						on:click={() => selectResidence(parseInt(residenceId, 10))}
					>
						<div class="d-flex justify-content-between">
							<h5 class="mb-0">
								{groupSchedulesByResidence[residenceId].residenceStreet}
								<br />
								{groupSchedulesByResidence[residenceId].residenceCityState}
							</h5>
							<div class="d-flex flex-column align-items-start">
								<span class="badge badge-info">{groupSchedulesByResidence[residenceId].residenceScheduleCount}</span>
							</div>
						</div>
					</button>
				{/each}
			</div>
		</div>
		<div class="col-lg-8 col-xl-9">
			<h4 class="mt-3 mt-lg-0">Subscription Details</h4>
			<div style="overflow-y: auto; max-height: 60vh;">
				{#if !selectedResidenceId}
					<div class="text-center mt-2">
						<h4>Select a residence to view subscription details</h4>
					</div>
				{:else if loadingSubData}
					<div class="text-center mt-2">
						<Throbber />
					</div>
				{:else}
					{#each computedServiceSchedule as serviceSchedule}
						<CollapsibleCard
							cardHeaderClass="card-header d-flex justify-content-between align-items-center"
							bodyClass="p-1"
							entireHeaderToggles
							bodyShown={(computedServiceSchedule.length > 1 ? false : true) || openSubscriptionCard === serviceSchedule.id}
							on:show={() => {
								openSubscriptionCard = serviceSchedule.id
							}}
							on:hide={() => {
								openSubscriptionCard = null
							}}
						>
							<svelte:fragment slot="cardHeader">
								<div>
									<h4 class="mb-0">{serviceSchedule.serviceName}</h4>
									{#if serviceSchedule.status === 'DISABLED'}
										<span class="badge badge-danger">Cancelled</span><br />
									{:else if !serviceSchedule.nextJobDate}
										Service ended on {serviceSchedule.displayEndOfSchedule}<br />
									{:else}
										<div class="form-row m-0">
											<strong class="mr-1">Current Service: </strong>
											<div>
												{serviceSchedule.currentServiceDate}
												{#if serviceSchedule.mostRecentJobStatus !== 'PENDING' && serviceSchedule.mostRecentJobStatus !== 'CLAIMABLE'}
													<GenericBadge jobType={serviceSchedule.mostRecentJobStatus} />
												{/if}
											</div>
										</div>
										<strong>Next Service: </strong>
										{serviceSchedule.nextJobDate}<br />
									{/if}
									Recurs every {serviceSchedule.serviceSchedulePeriodValue} days
								</div>
							</svelte:fragment>
							<ul class="list-group list-group-flush">
								<li class="list-group-item">
									<div class="d-flex justify-content-between">
										<span>Completed:</span>
										<span>{serviceSchedule.jobsCompleted} of {serviceSchedule.totalNumOfJobs}</span>
									</div>
								</li>
								<li class="list-group-item">
									<div class="d-flex justify-content-between">
										<span>Schedule:</span>
										<span>Every {serviceSchedule.serviceSchedulePeriodValue} days</span>
									</div>
								</li>
								{#if serviceSchedule.numOfCancelledJobs > 0}
									<li class="list-group-item">
										<div class="d-flex justify-content-between">
											<span>Cancelled:</span>
											<span>{serviceSchedule.numOfCancelledJobs}</span>
										</div>
									</li>
								{/if}
								<li class="list-group-item">
									<div class="d-flex justify-content-between">
										<span>Next Service:</span>
										<span>{serviceSchedule.nextJobDate}</span>
									</div>
								</li>
								<li class="list-group-item">
									<div class="d-flex justify-content-between">
										<span>Service Ends On:</span>
										<span>{serviceSchedule.displayEndOfSchedule}</span>
									</div>
								</li>
								{#if serviceSchedule.answers && serviceSchedule.answers.length > 0}
									<li class="list-group-item">
										<span>Add-ons:</span>
										<div class="d-flex flex-column text-right">
											{#each serviceSchedule.answers as answer}
												<span>{answer.answer}</span>
											{/each}
										</div>
									</li>
								{/if}
							</ul>
							<div class="d-flex justify-content-end mt-1">
								<Button
									outline
									color="secondary"
									size="sm"
									class="responsive-button"
									disabled={serviceSchedule.status !== 'ACTIVE'}
									on:click={() => {
										if (confirm('Are you sure you want to cancel this subscription? This action will not cancel any jobs that have already been created.')) {
											cancelRecurringScheduleOrder(serviceSchedule.id)
										}
									}}
								>
									{#if serviceSchedule.status === 'DISABLED'}
										Cancelled
									{:else if serviceSchedule.status === 'ACTIVE'}
										Cancel Subscription
									{/if}
								</Button>
							</div>
						</CollapsibleCard>
					{/each}
				{/if}
			</div>
		</div>
	{/if}
</div>

<style>
	@media (max-width: 577px) {
		* :global(.w-xs-100) {
			width: 100% !important;
		}
	}

	div :global(.card:not(:last-child):not(:first-child)) {
		border-radius: 0px;
		border-bottom: none;
	}

	div :global(.card:first-child:not(:only-child)) {
		border-bottom: none;
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
	}

	div :global(.card:last-child:not(:only-child)) {
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;
	}
</style>
