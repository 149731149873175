export default {
    "name": "UserAccountDeactivate",
    "kind": "HoudiniMutation",
    "hash": "e47cd5328515a627897eddc5e9596f8edbcb4281260ca95fc05325ad55bb1928",

    "raw": `mutation UserAccountDeactivate($lockNotes: String!, $userAccountId: Float!) {
  userAccountDeactivate(lockNotes: $lockNotes, userAccountId: $userAccountId) {
    id
    mobile
    created
    email
    firstName
    lastName
    fullName
    status
    customer {
      id
    }
    provider {
      id
      status
      profilePictureFile {
        id
        path
      }
    }
    userRoles {
      permissionName
      value
    }
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "userAccountDeactivate": {
                "type": "UserAccount",
                "keyRaw": "userAccountDeactivate(lockNotes: $lockNotes, userAccountId: $userAccountId)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "mobile": {
                            "type": "PhoneNumber",
                            "keyRaw": "mobile",
                            "nullable": true,
                            "visible": true
                        },

                        "created": {
                            "type": "DateTime",
                            "keyRaw": "created",
                            "visible": true
                        },

                        "email": {
                            "type": "EmailAddress",
                            "keyRaw": "email",
                            "visible": true
                        },

                        "firstName": {
                            "type": "String",
                            "keyRaw": "firstName",
                            "visible": true
                        },

                        "lastName": {
                            "type": "String",
                            "keyRaw": "lastName",
                            "visible": true
                        },

                        "fullName": {
                            "type": "String",
                            "keyRaw": "fullName",
                            "nullable": true,
                            "visible": true
                        },

                        "status": {
                            "type": "UserAccountStatus",
                            "keyRaw": "status",
                            "visible": true
                        },

                        "customer": {
                            "type": "Customer",
                            "keyRaw": "customer",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "provider": {
                            "type": "Provider",
                            "keyRaw": "provider",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "status": {
                                        "type": "ProviderStatus",
                                        "keyRaw": "status",
                                        "visible": true
                                    },

                                    "profilePictureFile": {
                                        "type": "FileEntity",
                                        "keyRaw": "profilePictureFile",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "path": {
                                                    "type": "String",
                                                    "keyRaw": "path",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "userRoles": {
                            "type": "UserRole",
                            "keyRaw": "userRoles",

                            "selection": {
                                "fields": {
                                    "permissionName": {
                                        "type": "NonEmptyString",
                                        "keyRaw": "permissionName",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "String",
                                        "keyRaw": "value",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "lockNotes": "String",
            "userAccountId": "Float"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=57b2194cb89edd9a8048410106048edf0bd5a0ac7c0c2c6e98eaa4810f90ef47";