import { graphql, CustomerCancelJobMutationStore } from '$houdini'

export const customerCancelJobMutation: CustomerCancelJobMutationStore = graphql`
	mutation CustomerCancelJobMutation($cancelJobInput: CancelJobInput!) {
		cancelJob(cancelJobInput: $cancelJobInput) {
			id
			residence {
				street
				city
				state
				zip
				country
			}
			jobStatus
			requestedSchedule
			service {
				name
				id
			}
			jobClaims {
				scheduledAt
				jobClaimStatus
			}
			answers {
				id
				answer
			}
			files {
				id
				jobId
				userAccountId
				fileId
				file {
					id
					name
					created
					updated
					hash
					path
					mimeType
					type
				}
				public
				rank
				imageFileType
			}
		}
	}
`
