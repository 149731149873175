export default {
    "name": "RemoveAnswer",
    "kind": "HoudiniMutation",
    "hash": "5abb0fbefe8d4b3427508f1b1071f09c0c9b81285ec2b187aa652b5b30e421d6",

    "raw": `mutation RemoveAnswer($deleteAnswerId: PositiveInt!) {
  deleteAnswer(id: $deleteAnswerId)
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "deleteAnswer": {
                "type": "Boolean",
                "keyRaw": "deleteAnswer(id: $deleteAnswerId)",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "deleteAnswerId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=8620aa2f72a5b94b6c498da769a8e582728b6ffbad8291fc360036a1197bd023";