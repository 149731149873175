export default {
    "name": "GetCustomerServiceSchedulesQuery",
    "kind": "HoudiniQuery",
    "hash": "30b199e148ee89cd850f096919c46c636460683608d60443028202971fd90552",

    "raw": `query GetCustomerServiceSchedulesQuery($filter: CustomerServiceScheduleFilter) {
  getCustomerServiceSchedules(filter: $filter) {
    id
    status
    residence {
      id
      street
      city
      state
      zip
      country
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "getCustomerServiceSchedules": {
                "type": "ServiceSchedule",
                "keyRaw": "getCustomerServiceSchedules(filter: $filter)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "status": {
                            "type": "ServiceScheduleStatus",
                            "keyRaw": "status",
                            "visible": true
                        },

                        "residence": {
                            "type": "Residence",
                            "keyRaw": "residence",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "street": {
                                        "type": "String",
                                        "keyRaw": "street",
                                        "visible": true
                                    },

                                    "city": {
                                        "type": "String",
                                        "keyRaw": "city",
                                        "visible": true
                                    },

                                    "state": {
                                        "type": "String",
                                        "keyRaw": "state",
                                        "visible": true
                                    },

                                    "zip": {
                                        "type": "String",
                                        "keyRaw": "zip",
                                        "visible": true
                                    },

                                    "country": {
                                        "type": "String",
                                        "keyRaw": "country",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "CustomerServiceScheduleFilter"
        },

        "types": {
            "CustomerServiceScheduleFilter": {
                "residenceId": "PositiveInt",
                "serviceId": "PositiveInt",
                "status": "ServiceScheduleStatus"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=ee64c8d7fd0e1b818bba8100c1c940ee557968dc799fb1d12e53bb582f8ae0fd";