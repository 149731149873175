<script lang="ts">
	import GenericBadge from 'components/GenericBadge.svelte'
	import type jobStatusMap from 'utility/job-status-map'
	import type { PaymentStatus$options } from '$houdini/graphql'
	import { getCompletedFormatted } from 'utility/get-computed-job'
	import formatRelativeDateTime from 'utility/format/format-relative-date-time'
	import formatRelativeDate from 'utility/format/format-relative-date'
	import formatCityStateZip from 'utility/format/format-city-state-zip'
	import { getCustomerOrderListItemJob } from 'utility/graphql/get-customer-order-list-item'

	type Job = {
		id: number
		jobStatus: keyof typeof jobStatusMap
		completed?: Date | null
		jobClaims:
			| {
					scheduledAt: Date
			  }[]
			| null
		requestedSchedule: Date
		residence: {
			street: string
			city: string
			state: string
			zip: string
		}
		transaction?: {
			paymentStatus: PaymentStatus$options
			tip: string | null
		} | null
		service: {
			name: string
		}
		review?: string | null
	}

	export let job: Job
	export let showCityStateZip: boolean = true
	export let selectedJobId: number | null = null
	export let selectedJob: Job | null = null
	export let loadingJob: boolean = false

	async function selectJob() {
		if (job.id === selectedJobId) {
			return
		} else {
			loadingJob = true
			selectedJobId = job.id
			selectedJob = await getCustomerOrderListItemJob(job.id)
			loadingJob = false
		}
	}

	export async function reselectJob(jobId: number) {
		loadingJob = true
		selectedJobId = jobId
		selectedJob = await getCustomerOrderListItemJob(jobId)
		loadingJob = false
	}
</script>

<button
	id="job-id-{job.id}"
	class:active={job.id === selectedJobId}
	class="list-group-item list-group-item-action"
	on:click={() => selectJob()}
	disabled={loadingJob}
>
	<div class="d-flex flex-column">
		<h6>
			{#if job.jobStatus === 'COMPLETED' && job.completed}
				{`Completed ${getCompletedFormatted(job.completed)}`}
			{:else if job.jobStatus === 'CLAIMED' && !job.completed}
				{`Scheduled for ${job.jobClaims ? formatRelativeDateTime(job.jobClaims[0]?.scheduledAt, new Date()).formattedDate : ''}`}
			{:else if job.jobStatus === 'CANCELLED'}
				Cancelled
			{:else}
				{`Requested for ${formatRelativeDate(job.requestedSchedule, new Date()).formattedDate}`}
			{/if}
		</h6>
		<div class="d-flex align-items-end">
			<p class="mb-0 text-smaller w-50">
				{job.residence.street}
				{#if showCityStateZip}
					<br />{formatCityStateZip(
						{
							city: job.residence.city,
							state: job.residence.state,
							zip: job.residence.zip,
						},
						true,
					)}
				{/if}
			</p>
			<div class="d-flex flex-column w-50 align-items-end">
				{#if job.transaction?.paymentStatus === 'FAILED'}
					<span class="badge badge-danger">
						<i class="fas fa-exclamation-circle"></i>
						Payment Failed
					</span>
				{/if}
				{#if job.jobStatus === 'EXPIRED'}
					<GenericBadge jobType={job.jobStatus} />
				{/if}
				{#if job.jobStatus === 'CANCELLED'}
					<GenericBadge jobType={job.jobStatus} />
				{/if}
				<GenericBadge
					type="SERVICE"
					text={job.service.name}
				/>
				{#if job.review || job.transaction?.tip}
					<div class="d-flex pt-1">
						{#if job.review}
							<span class="badge badge-info">
								<i class="fas fa-comment"></i>
								Reviewed
							</span>
						{/if}
						{#if job.transaction?.tip}
							<span class="badge badge-info ml-1">
								<i class="fas fa-dollar"></i>
								Tipped
							</span>
						{/if}
					</div>
				{/if}
			</div>
		</div>
	</div>
</button>
