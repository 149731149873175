export default {
    "name": "UpdateService",
    "kind": "HoudiniMutation",
    "hash": "7dcedea9b1b8646576e648e689eef78d3ecc43bfa9b054b4684f50871fdb8386",

    "raw": `mutation UpdateService($editService: EditService!) {
  editService(editService: $editService) {
    ...ServiceData
    id
  }
}

fragment ServiceData on Service {
  id
  name
  description
  codeName
  active
  allowSubscription
  warning
  maximumDaysToPerform
  requirement
  allowNewApplication
  examplePictureFileId
  examplePictureFile {
    id
    name
    path
  }
  __typename
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "editService": {
                "type": "Service",
                "keyRaw": "editService(editService: $editService)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "nullable": true,
                            "visible": true
                        },

                        "codeName": {
                            "type": "String",
                            "keyRaw": "codeName",
                            "visible": true
                        },

                        "active": {
                            "type": "Boolean",
                            "keyRaw": "active",
                            "visible": true
                        },

                        "allowSubscription": {
                            "type": "Boolean",
                            "keyRaw": "allowSubscription",
                            "visible": true
                        },

                        "warning": {
                            "type": "String",
                            "keyRaw": "warning",
                            "nullable": true,
                            "visible": true
                        },

                        "maximumDaysToPerform": {
                            "type": "PositiveInt",
                            "keyRaw": "maximumDaysToPerform",
                            "visible": true
                        },

                        "requirement": {
                            "type": "String",
                            "keyRaw": "requirement",
                            "nullable": true,
                            "visible": true
                        },

                        "allowNewApplication": {
                            "type": "Boolean",
                            "keyRaw": "allowNewApplication",
                            "visible": true
                        },

                        "examplePictureFileId": {
                            "type": "PositiveInt",
                            "keyRaw": "examplePictureFileId",
                            "nullable": true,
                            "visible": true
                        },

                        "examplePictureFile": {
                            "type": "FileEntity",
                            "keyRaw": "examplePictureFile",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "path": {
                                        "type": "String",
                                        "keyRaw": "path",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "ServiceData": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "editService": "EditService"
        },

        "types": {
            "ImageFile": {
                "base64String": "String",
                "fileName": "String"
            },

            "EditService": {
                "active": "Boolean",
                "allowNewApplication": "Boolean",
                "allowSubscription": "Boolean",
                "codeName": "String",
                "description": "String",
                "exampleImageFile": "ImageFile",
                "id": "PositiveInt",
                "maximumDaysToPerform": "PositiveInt",
                "minimumDaysToPerform": "NonNegativeInt",
                "name": "NonEmptyString",
                "requirement": "String",
                "warning": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=dbfc84f52c716c5c82ab7cb294959795ba11e8889c8b582d5ee33829ced7e458";