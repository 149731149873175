export default {
    "name": "CreateService",
    "kind": "HoudiniMutation",
    "hash": "51296765569c721069e2fa6b82b9f61415a32c81a39666707ae88d1b44590fe9",

    "raw": `mutation CreateService($newService: NewService!) {
  newService(newService: $newService) {
    ...ServiceData
    id
  }
}

fragment ServiceData on Service {
  id
  name
  description
  codeName
  active
  allowSubscription
  warning
  maximumDaysToPerform
  requirement
  allowNewApplication
  examplePictureFileId
  examplePictureFile {
    id
    name
    path
  }
  __typename
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "newService": {
                "type": "Service",
                "keyRaw": "newService(newService: $newService)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "nullable": true,
                            "visible": true
                        },

                        "codeName": {
                            "type": "String",
                            "keyRaw": "codeName",
                            "visible": true
                        },

                        "active": {
                            "type": "Boolean",
                            "keyRaw": "active",
                            "visible": true
                        },

                        "allowSubscription": {
                            "type": "Boolean",
                            "keyRaw": "allowSubscription",
                            "visible": true
                        },

                        "warning": {
                            "type": "String",
                            "keyRaw": "warning",
                            "nullable": true,
                            "visible": true
                        },

                        "maximumDaysToPerform": {
                            "type": "PositiveInt",
                            "keyRaw": "maximumDaysToPerform",
                            "visible": true
                        },

                        "requirement": {
                            "type": "String",
                            "keyRaw": "requirement",
                            "nullable": true,
                            "visible": true
                        },

                        "allowNewApplication": {
                            "type": "Boolean",
                            "keyRaw": "allowNewApplication",
                            "visible": true
                        },

                        "examplePictureFileId": {
                            "type": "PositiveInt",
                            "keyRaw": "examplePictureFileId",
                            "nullable": true,
                            "visible": true
                        },

                        "examplePictureFile": {
                            "type": "FileEntity",
                            "keyRaw": "examplePictureFile",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "path": {
                                        "type": "String",
                                        "keyRaw": "path",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "ServiceData": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "newService": "NewService"
        },

        "types": {
            "ImageFile": {
                "base64String": "String",
                "fileName": "String"
            },

            "NewService": {
                "active": "Boolean",
                "allowNewApplication": "Boolean",
                "allowSubscription": "Boolean",
                "codeName": "String",
                "description": "String",
                "exampleImageFile": "ImageFile",
                "maximumDaysToPerform": "PositiveInt",
                "minimumDaysToPerform": "NonNegativeInt",
                "name": "NonEmptyString",
                "requirement": "String",
                "warning": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=da77d3684bd5c940aede447f48da6c268b3fc81d280a7c25731844aea2d464be";