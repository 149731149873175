<script lang="ts">
	import Select from '@isoftdata/svelte-select'
	import Table, { Td, type Column } from '@isoftdata/svelte-table'
	import Button from '@isoftdata/svelte-button'
	import Modal from '@isoftdata/svelte-modal'
	import ProviderProfilePicture from 'components/ProviderProfilePicture.svelte'
	import {
		loadUserAccounts,
		setUserPermission,
		type AdminUserAccount,
		adminUserAccountsQuery,
		type AdminUserPermission,
		reactivateUserAccount,
		deactivateUserAccount,
		adminDetachProviderProfilePicture,
	} from 'utility/user-account-helper'
	import toTitleCase from 'to-title-case'
	import formatImageFileUrl from 'utility/format/format-image-file-url'
	import { type UserAccessLevel$options } from '$houdini/graphql'
	import Throbber from 'components/Throbber.svelte'
	import type { PersistentStore } from '@macfja/svelte-persistent-store'
	import { upsert } from '@isoftdata/utility-array'
	import type { WritableDeep } from 'type-fest'
	import type { Mediator } from 'types/common'
	import { getContext, onMount } from 'svelte'

	let accountStatusFilterList = [{ value: true, label: 'Active Only' }]

	let theTable: Table<AdminUserAccount>

	const mediator = getContext<Mediator>('mediator')

	export let userAccounts: WritableDeep<AdminUserAccount>[] = []
	export let permissions: AdminUserPermission[] = []
	export let paginationInfo: { totalPages: number; pageNumber: number; pageSize: number | null; totalItems: number }
	export let userSavedPageSize: PersistentStore<number>
	export let userSavedRolesFilter: PersistentStore<'CUSTOMER' | 'PROVIDER' | 'BOTH' | null>
	export let userSavedActiveFilter: PersistentStore<boolean | null>
	export let pageNumber: number = 1
	export let selectedUserAccountId: number | null = null
	export let selectedUserAccount: AdminUserAccount | null = null

	onMount(() => {
		if ($userSavedRolesFilter !== null) {
			theTable.setColumnVisibility('accountType', false)
		}
		if ($userSavedRolesFilter !== 'PROVIDER') {
			theTable.setColumnVisibility('provider[status]', false)
		}
		if ($userSavedRolesFilter !== 'BOTH') {
			theTable.setColumnVisibility('provider[status]', false)
		}
	})

	const columns: Column<AdminUserAccount>[] = [
		{ property: 'fullName', name: 'Name', defaultSortColumn: true },
		{ property: 'email', name: 'Email' },
		{ property: 'mobile', name: 'Mobile' },
		{ property: 'created', name: 'Created' },
		{ property: 'status', name: 'Status' },
		{ property: 'provider[status]', name: 'Provider Status' },
		{ property: 'accountType', name: 'Account Type' },
	]

	let showProfilePictureModal = false
	let providerProfilePicturePath: string | null = null

	function selectUserAccount(userAccountId: number) {
		if (selectedUserAccountId === userAccountId) {
			selectedUserAccountId = null
			selectedUserAccount = null
			providerProfilePicturePath = null
		} else {
			selectedUserAccountId = userAccountId
			selectedUserAccount = userAccounts.find(account => account.id === userAccountId) ?? null
			providerProfilePicturePath = selectedUserAccount?.provider?.profilePictureFile?.path ? formatImageFileUrl(selectedUserAccount?.provider?.profilePictureFile?.path) : null
		}
	}

	function viewProfilePicture() {
		if (!selectedUserAccount) {
			return
		}
		showProfilePictureModal = true
	}

	async function filterChanged() {
		try {
			const activeOnly = $userSavedActiveFilter
			let roles: Array<'CUSTOMER' | 'PROVIDER'> | null = []
			switch ($userSavedRolesFilter) {
				case 'CUSTOMER':
					roles = ['CUSTOMER']
					theTable.setColumnVisibility('accountType', false)
					theTable.setColumnVisibility('provider[status]', false)
					break
				case 'PROVIDER':
					roles = ['PROVIDER']
					theTable.setColumnVisibility('accountType', false)
					theTable.setColumnVisibility('provider[status]', true)
					break
				case 'BOTH':
					roles = ['CUSTOMER', 'PROVIDER']
					theTable.setColumnVisibility('accountType', false)
					theTable.setColumnVisibility('provider[status]', true)
					break
				default:
					roles = null
					theTable.setColumnVisibility('accountType', true)
					theTable.setColumnVisibility('provider[status]', false)
					break
			}
			const userAccountRes = await loadUserAccounts(activeOnly, roles)
			userAccounts = userAccountRes.data
			paginationInfo = userAccountRes.info
		} catch (err: unknown) {
			const error = err as Error
			console.error(error)
			mediator.call('showError', error, { message: error.message, title: 'Error Loading User Accounts' })
		}
	}

	async function handleDeactivateUserAccount(id: number, userAccountName: string | null) {
		const supportEmail = 'support@lawnhiro.com'
		if (confirm(`Are you sure you want to deactivate ${userAccountName ?? 'Unknown'}?`)) {
			try {
				const res = await deactivateUserAccount(id, `Please contact ${supportEmail} to reactivate your account.`)

				userAccounts = upsert(userAccounts, 'id', res)
			} catch (err: unknown) {
				const error = err as Error
				console.error(error)
				mediator.call('showError', error, { message: error.message, title: 'Error Deactivating User Account' })
			}
		}
	}

	async function handleReactivateUserAccount(id: number, userAccountName: string | null) {
		if (confirm(`Are you sure you want to reactivate ${userAccountName ?? 'Unknown'}?`)) {
			try {
				const res = await reactivateUserAccount(id)

				userAccounts = upsert(userAccounts, 'id', res)
			} catch (err: unknown) {
				const error = err as Error
				console.error(error)
				mediator.call('showError', error, { message: error.message, title: 'Error Reactivating User Account' })
			}
		}
	}

	async function permissionChange(selectedUserAccountId: number | null, codeName: string, event: Event) {
		if (!selectedUserAccountId) {
			return
		}
		const value = (event.target as HTMLSelectElement).value
		const permissionValue: UserAccessLevel$options = value.toUpperCase() as UserAccessLevel$options
		try {
			const userAccount = userAccounts.find(account => account.id === selectedUserAccountId)
			if (!userAccount) {
				return
			}
			userAccount.userRoles = userAccount.userRoles.map(role => {
				if (role.permissionName === codeName) {
					role.value = value
				}
				return role
			})
			await setUserPermission(selectedUserAccountId, codeName, permissionValue)
			upsert(userAccounts, 'id', userAccount)
		} catch (err: unknown) {
			const error = err as Error
			console.error(error)
			mediator.call('showError', error, { message: error.message, title: 'Error Setting User Permission' })
		}
	}

	async function removeProviderProfilePicture(userAccount: AdminUserAccount | null) {
		if (!userAccount?.provider || !userAccount.provider.profilePictureFile) {
			return
		}

		if (confirm('Are you sure you want to remove this profile picture?')) {
			const success = await adminDetachProviderProfilePicture(userAccount.provider.id, userAccount.provider.profilePictureFile.id)
			if (success) {
				const tableUserAccount = userAccounts.find(account => account.id === userAccount.id)
				if (tableUserAccount?.provider) {
					tableUserAccount.provider.profilePictureFile = null
				}
				userAccount.provider.profilePictureFile = null
				providerProfilePicturePath = null
			} else {
				mediator.call('showMessage', {
					message: 'Failed to remove profile picture.',
					heading: 'Error Removing Profile Picture',
					time: false,
				})
			}
		}
	}
</script>

<div class="container-fluid mt-3">
	<Table
		responsive
		stickyHeader
		columnHidingEnabled
		paginationEnabled
		filterEnabled
		showFilterLabel
		parentStyle="max-height: 70vh; overflow-y: auto;"
		perPageCount={$userSavedPageSize}
		currentPageNumber={pageNumber}
		totalItemsCount={paginationInfo.totalItems}
		filterLabel="Filter Users"
		{columns}
		bind:rows={userAccounts}
		bind:this={theTable}
		on:pageChange={async event => {
			pageNumber = event.detail.pageNumber
			selectedUserAccountId = null
			selectedUserAccount = null
			await filterChanged()
		}}
	>
		<svelte:fragment slot="header">
			<div class="form-row align-items-end">
				<div class="col-12 col-md-3">
					<Select
						label="Account Status"
						emptyText="Any Status"
						emptyValue={null}
						disabled={$adminUserAccountsQuery.fetching}
						bind:value={$userSavedActiveFilter}
						on:change={filterChanged}
					>
						{#each accountStatusFilterList as status}
							<option value={status.value}>{status.label}</option>
						{/each}
					</Select>
				</div>
				<div class="col-12 col-md-3">
					<Select
						label="Account Type"
						emptyText="Any Type"
						emptyValue={null}
						disabled={$adminUserAccountsQuery.fetching}
						bind:value={$userSavedRolesFilter}
						on:change={filterChanged}
					>
						<option value="CUSTOMER">Customer Only</option>
						<option value="PROVIDER">Provider Only</option>
						<option value="BOTH">Both Customer & Provider</option>
					</Select>
				</div>
				<div class="col-12 col-md-3">
					<Select
						showEmptyOption={false}
						label="Page Size"
						class="d-inline"
						bind:value={$userSavedPageSize}
						options={[10, 25, 50, 100, 1000]}
						disabled={$adminUserAccountsQuery.fetching}
						on:change={() => {
							pageNumber = 1
							selectedUserAccountId = null
							selectedUserAccount = null
							filterChanged()
						}}
					/>
				</div>
			</div>
		</svelte:fragment>
		<svelte:fragment
			slot="body"
			let:rows
		>
			{#if $adminUserAccountsQuery.fetching}
				<tr>
					<td
						colspan={columns.length}
						class="text-center"
					>
						<Throbber />
					</td>
				</tr>
			{:else if rows.length === 0}
				<tr>
					<td
						colspan={columns.length}
						class="text-center"
					>
						No users found.
					</td>
				</tr>
			{:else}
				{#each rows as row}
					{@const rowProviderPicturePath = row.provider?.profilePictureFile?.path && $userSavedRolesFilter === 'PROVIDER' ? formatImageFileUrl(row.provider?.profilePictureFile?.path) : null}
					<tr
						class="cursor-pointer"
						class:table-dark={selectedUserAccountId === row.id}
						on:click={() => selectUserAccount(row.id)}
					>
						<Td property="fullName">
							<div class="d-flex align-items-center">
								{#if row.provider && $userSavedRolesFilter === 'PROVIDER'}
									<div>
										<ProviderProfilePicture
											profilePictureUrl={rowProviderPicturePath}
											pictureSize={50}
										/>
									</div>
								{/if}
								{row.fullName}
							</div>
						</Td>
						<Td property="email"><a href="mailto:{row.email}">{row.email}</a></Td>
						<Td property="mobile">
							{#if row.mobile}
								<a href="tel:{row.mobile}">{row.mobile}</a>
							{:else}
								{''}
							{/if}
						</Td>
						<Td property="created">{row.created.toLocaleDateString()}</Td>
						<Td property="status">{toTitleCase(row.status)}</Td>
						<Td property="provider[status]">
							{#if row.provider}
								{toTitleCase(row.provider.status)}
							{:else}
								{''}
							{/if}
						</Td>
						{#if $userSavedRolesFilter === null}
							<Td property="accountType">
								{#if row.customer && row.provider}
									Both
								{:else if row.customer}
									Customer
								{:else if row.provider}
									Provider
								{/if}
							</Td>
						{/if}
					</tr>
					{#if selectedUserAccountId === row.id}
						<tr>
							<td colspan={columns.length}>
								<div class="card">
									<div class="card-header d-flex justify-content-between">
										<div class="d-flex align-items-center">
											{#if row.provider}
												<!-- svelte-ignore a11y-click-events-have-key-events -->
												<!-- svelte-ignore a11y-no-static-element-interactions -->
												<div
													class:cursor-pointer={row.provider.profilePictureFile !== null}
													on:click={() => viewProfilePicture()}
												>
													<ProviderProfilePicture
														floatingButtonOutline
														profilePictureUrl={providerProfilePicturePath}
														pictureSize={50}
														showFloatingButton={providerProfilePicturePath !== null && providerProfilePicturePath !== ''}
														floatingButtonColor="danger"
														floatingButtonIconClass="trash"
														floatingButtonSize="xs"
														floatingButtonIconStyle="position: absolute; top: 60%; left: 45%; transform: translate(-50%, -50%);"
														on:click={() => viewProfilePicture()}
														on:floatingButtonClicked={() => removeProviderProfilePicture(row)}
													/>
												</div>
											{/if}
											<h3 class="mb-0">{row.firstName} {row.lastName}</h3>
										</div>
										{#if row.status === 'ACTIVE'}
											<Button
												color="danger"
												iconClass="xmark"
												on:click={() => handleDeactivateUserAccount(row.id, row.fullName)}>Deactivate Account</Button
											>
										{:else}
											<Button
												iconClass="check"
												on:click={() => handleReactivateUserAccount(row.id, row.fullName)}>Activate Account</Button
											>
										{/if}
									</div>
									{#if row.status === 'ACTIVE'}
										{#if selectedUserAccount}
											<div class="card-body">
												<div class="card">
													<div class="card-header d-flex justify-content-between">
														<h4>Permissions</h4>
													</div>
													<div class="card-body">
														<div class="form-row">
															{#each permissions as permission}
																<div class="col-6">
																	<Select
																		label="{permission.category}: {permission.displayName}"
																		class="form-check"
																		value={selectedUserAccount.userRoles.find(role => role.permissionName === permission.codeName)?.value ?? 'NONE'}
																		showEmptyOption={false}
																		on:change={e => permissionChange(selectedUserAccountId, permission.codeName, e)}
																	>
																		<option value="NONE">None</option>
																		<option value="Region">Region</option>
																		<option value="Global">Global</option>
																	</Select>
																	<small>{permission.description}</small>
																</div>
															{/each}
														</div>
													</div>
												</div>
											</div>
										{/if}
									{/if}
								</div>
							</td>
						</tr>
					{/if}
				{/each}
			{/if}
		</svelte:fragment>
	</Table>
</div>

<Modal
	confirmButtonText="Remove Profile Picture"
	confirmButtonColor="danger"
	confirmButtonIcon="trash"
	bind:show={showProfilePictureModal}
	title="Provider Profile Picture"
	on:close={() => (showProfilePictureModal = false)}
	on:backdrop={() => (showProfilePictureModal = false)}
	on:confirm={() => removeProviderProfilePicture(selectedUserAccount)}
>
	<div class="d-flex justify-content-center">
		<img
			src={providerProfilePicturePath}
			class="w-100"
			alt="Provider Profile"
		/>
	</div>
	<div class="text-center">
		<small class="text-info">*Admin can delete this profile picture if it is inappropriate.</small>
	</div>
</Modal>
