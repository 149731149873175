import {
	graphql,
	GetCustomerServiceSchedulesQueryStore,
	type GetCustomerServiceSchedulesQuery$result,
	GetCustomerResidenceCompletedScheduleJobStore,
	GetSelectedCustomerResidenceServiceSchedulesStore,
	type GetSelectedCustomerResidenceServiceSchedules$result,
	type GetCustomerResidenceCompletedScheduleJob$result,
	DisableServiceScheduleStore,
} from '$houdini'
import type { WritableDeep } from 'type-fest'

export type CustomerServiceSchedule = WritableDeep<GetCustomerServiceSchedulesQuery$result['getCustomerServiceSchedules'][number]>
export type SelectedServiceSchedule = GetSelectedCustomerResidenceServiceSchedules$result['getCustomerServiceSchedules'][number]
export type CompletedServiceSchedule = GetCustomerResidenceCompletedScheduleJob$result['getCustomerResidenceCompletedScheduleJob'][number]

const customerRecurringScheudlesQuery: GetCustomerServiceSchedulesQueryStore = graphql`
	query GetCustomerServiceSchedulesQuery($filter: CustomerServiceScheduleFilter) {
		getCustomerServiceSchedules(filter: $filter) {
			id
			status
			residence {
				id
				street
				city
				state
				zip
				country
			}
		}
	}
`

const selectedResidenceSchedulesQuery: GetSelectedCustomerResidenceServiceSchedulesStore = graphql`
	query GetSelectedCustomerResidenceServiceSchedules($filter: CustomerServiceScheduleFilter) {
		getCustomerServiceSchedules(filter: $filter) {
			id
			created
			endOfSchedule
			nextJob
			residence {
				city
				country
				state
				street
				zip
			}
			service {
				id
				name
			}
			serviceSchedulePeriod {
				serviceSchedulePeriod
			}
			status
			answers {
				id
				answer
			}
			jobs {
				id
				requestedSchedule
				jobStatus
				created
			}
		}
	}
`

const customerResidenceCompletedScheduleJobsQuery: GetCustomerResidenceCompletedScheduleJobStore = graphql`
	query GetCustomerResidenceCompletedScheduleJob($residenceId: PositiveInt!) {
		getCustomerResidenceCompletedScheduleJob(residenceId: $residenceId) {
			completed
			jobStatus
			serviceScheduleId
		}
	}
`

const cancelRecurringScheduleOrderMutation: DisableServiceScheduleStore = graphql`
	mutation DisableServiceSchedule($serviceScheduleId: PositiveInt!) {
		disableServiceSchedule(serviceScheduleId: $serviceScheduleId)
	}
`

export async function loadCustomerServiceSchedules() {
	const { data } = await customerRecurringScheudlesQuery.fetch({
		variables: {
			filter: {
				status: 'ACTIVE',
			},
		},
	})

	if (!data?.getCustomerServiceSchedules) {
		return []
	}

	return data.getCustomerServiceSchedules
}

export async function loadSelectedCustomerResidenceServiceSchedules(residenceId: number) {
	const { data } = await selectedResidenceSchedulesQuery.fetch({
		variables: {
			filter: {
				residenceId,
				status: 'ACTIVE',
			},
		},
	})

	if (!data?.getCustomerServiceSchedules) {
		return []
	}

	return data.getCustomerServiceSchedules
}

export async function loadCustomerResidenceCompletedScheduleJobs(residenceId: number) {
	const { data } = await customerResidenceCompletedScheduleJobsQuery.fetch({
		variables: {
			residenceId,
		},
	})

	if (!data?.getCustomerResidenceCompletedScheduleJob) {
		return []
	}

	return data.getCustomerResidenceCompletedScheduleJob
}

export async function cancelRecurringSchedule(serviceScheduleId: number) {
	const { data } = await cancelRecurringScheduleOrderMutation.mutate({
		serviceScheduleId,
	})

	if (!data?.disableServiceSchedule) {
		return false
	}

	return true
}
