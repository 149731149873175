export default {
    "name": "SetUserAccountPermission",
    "kind": "HoudiniMutation",
    "hash": "ea9529142696d3a19e51b438ff3334fa442cfbc474be8b650abbf1c06aad24c9",

    "raw": `mutation SetUserAccountPermission($accessLevel: UserAccessLevel!, $permissionCode: String!, $userAccountId: PositiveInt!) {
  setUserAccountPermission(
    accessLevel: $accessLevel
    permissionCode: $permissionCode
    userAccountId: $userAccountId
  )
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "setUserAccountPermission": {
                "type": "Void",
                "keyRaw": "setUserAccountPermission(accessLevel: $accessLevel, permissionCode: $permissionCode, userAccountId: $userAccountId)",
                "nullable": true,
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "accessLevel": "UserAccessLevel",
            "permissionCode": "String",
            "userAccountId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=3bd71e44c7344c74489daef9facd9d4ea7611a60a15d3354b850ec6c758acfc4";