import type { Mediator, Template, DomApi } from 'types/common'
import type { AbstractStateRouter, Renderer } from 'abstract-state-router'
import type { Decorator, Partial, Static, ValueMap } from 'ractive'
import { writable, derived } from 'svelte/store'

import makeStateRouter from 'abstract-state-router'
import makeRenderer from '@isoftdata/ractive-svelte-state-renderer-wrapper'
import mannish from 'mannish'
import component from './GetQuote.svelte'
import { type GetQuoteService, getQuoteServicesQuery, type GetQuoteStore, getSelectedAddress } from './utility/get-quote-helper'

function isChildState({ childState, parentState }: { childState: string; parentState: string }) {
	return childState.indexOf(parentState) > -1
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
const mediator: Mediator = mannish()

const svelteContext = new Map<string, unknown>([
	['apiUrl', '__apiUrl__'],
	['mediator', mediator],
	['isChildState', isChildState],
	['hasPermission', () => true], //TODO: implement has permission
])

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
const renderer = makeRenderer(
	{
		// Ractive default data
		data: {},
	},
	{
		// Svelte default props
		props: {},
		// Svelte default context, you can call getContext<Type>('key') to get the value in a svelete component
		context: svelteContext,
	}
) as (asr: AbstractStateRouter<Template, DomApi>) => Renderer<Template, DomApi>

const stateRouter = makeStateRouter(renderer, document.querySelector('body'), { throwOnError: true })
svelteContext.set('stateRouter', stateRouter)

import ractivePartialNoItemsForSelectedFilter from 'partials/no-items-for-selected-filter.html?raw'
import ractiveDecoratorSelectOnFocus from 'ractive-select-on-focus'
import ractiveDecoratorValid from '@isoftdata/ractive-decorator-valid'
import ractiveMethodUpsert from '@isoftdata/ractive-method-upsert'
import getSignupDisabledMessage from 'utility/get-signup-disabled-message'

declare const Ractive: Static
Ractive.partials.noItemsForSelectedFilter = ractivePartialNoItemsForSelectedFilter as Partial
Ractive.decorators.selectOnFocus = ractiveDecoratorSelectOnFocus as Decorator
Ractive.decorators.valid = ractiveDecoratorValid as Decorator
Ractive.defaults.upsert = ractiveMethodUpsert as keyof ValueMap

const context = {
	stateRouter,
	mediator,
	apiUrl: '__apiUrl__',
	isChildState,
	hasPermission: () => true, //TODO: implement has permission
}

stateRouter.addState({
	name: 'get-quote',
	route: 'get-quote',
	template: {
		svelte: true,
		component,
		options: {},
	},
	querystringParameters: ['serviceId'],
	async resolve(_data, parameters) {
		let services: GetQuoteService[] = []
		const [{ data: servicesData }, signupDisabledMessage] = await Promise.all([getQuoteServicesQuery.fetch({ variables: { filter: { active: true } } }), getSignupDisabledMessage()])
		services = servicesData?.services?.data ?? []

		const selectedService = services.find(service => service.id === parseInt(parameters.serviceId, 10))

		const quoteData = writable<GetQuoteStore>({
			selectableServices: services,
			selectedService,
			questions: [],
			selectedAnswerIds: new Set<number>(),
			places: [],
			bounds: null,
			emailAddress: '',
			quotedPrice: null,
			questionsAvailable: false,
			inServiceArea: false,
			priceError: null,
			isLoadingPrice: false,
			googleMapsPlaceId: '',
			approvedQuote: false,
			userAccount: {
				firstName: '',
				lastName: '',
				email: '',
				mobile: '',
				requestedRoles: ['CUSTOMER'],
				confirmPassword: '',
			},
		})

		const derivedGooglePlace = derived(quoteData, $store => $store.places?.[0] ?? null)
		const derivedAllQuestionsAnswered = derived(quoteData, $store => {
			return $store.selectedAnswerIds.size === $store.questions.length
		})
		const derivedSelectedAddress = derived(derivedGooglePlace, $store => getSelectedAddress($store))

		return {
			quoteData,
			derivedAllQuestionsAnswered,
			derivedSelectedAddress,
			signupDisabledMessage,
		}
	},
})

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call
Object.entries(import.meta.glob('./states/**/*.[tj]s', { import: 'default', eager: true }))
	.sort((a, b) => {
		const depthA = a[0].split('/').length
		const depthB = b[0].split('/').length
		return depthA - depthB
	})
	.forEach(([_, createState]) => {
		if (typeof createState === 'function') {
			createState(context)
		}
	})

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call
Object.values(import.meta.glob('./services/**/*.[tj]s', { import: 'default', eager: true })).forEach(registerService => {
	if (typeof registerService === 'function') {
		registerService(context)
	}
})

stateRouter.on('routeNotFound', () => stateRouter.go('app'))

if ('serviceWorker' in navigator) {
	let serviceWorkerRegistration
	if (!serviceWorkerRegistration) {
		void navigator.serviceWorker.register('/service-worker.js').then(registration => {
			context.mediator.provide('getServiceWorkerRegistration', () => registration)
		})
	}
} else {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
	context.mediator.provide('getServiceWorkerRegistration', () => null)
}

document.getElementById('loading-view')?.remove()
stateRouter.evaluateCurrentRoute('login')
