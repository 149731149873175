import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/OrderListItemJob'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class OrderListItemJobStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "OrderListItemJobStore",
			variables: true,
		})
	}
}

export async function load_OrderListItemJob(params) {
	await initClient()

	const store = new OrderListItemJobStore()

	await store.fetch(params)

	return {
		OrderListItemJob: store,
	}
}
