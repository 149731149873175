export default {
    "name": "ServiceQuestionData",
    "kind": "HoudiniFragment",
    "hash": "5e7e46ab734206c358081509833f1d39dbbf5cd5a756f31cda81fabcf3c33163",

    "raw": `fragment ServiceQuestionData on Question {
  id
  question
  description
  questionDataType
  answers {
    ...ServiceAnswerData
    id
  }
  services {
    id
  }
  __typename
}

fragment ServiceAnswerData on Answer {
  id
  answer
  tag {
    ...ServiceTagData
    id
  }
  __typename
}

fragment ServiceTagData on Tag {
  id
  code
  name
  description
  prompt
  entityType
  tagPricingForService(serviceId: $serviceId) {
    ...TagPricingData
    id
  }
  __typename
}

fragment TagPricingData on TagPricing {
  id
  priceAdjustment
  pricingType
  serviceId
  tagId
  postPriceAdjustment
  __typename
}
`,

    "rootType": "Question",
    "stripVariables": [],

    "selection": {
        "fields": {
            "id": {
                "type": "PositiveInt",
                "keyRaw": "id",
                "visible": true
            },

            "question": {
                "type": "String",
                "keyRaw": "question",
                "visible": true
            },

            "description": {
                "type": "String",
                "keyRaw": "description",
                "nullable": true,
                "visible": true
            },

            "questionDataType": {
                "type": "QuestionDataType",
                "keyRaw": "questionDataType",
                "visible": true
            },

            "answers": {
                "type": "Answer",
                "keyRaw": "answers",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "answer": {
                            "type": "String",
                            "keyRaw": "answer",
                            "visible": true
                        },

                        "tag": {
                            "type": "Tag",
                            "keyRaw": "tag",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "prompt": {
                                        "type": "String",
                                        "keyRaw": "prompt",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "entityType": {
                                        "type": "EntityType",
                                        "keyRaw": "entityType",
                                        "visible": true
                                    },

                                    "tagPricingForService": {
                                        "type": "TagPricing",
                                        "keyRaw": "tagPricingForService(serviceId: $serviceId)",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "priceAdjustment": {
                                                    "type": "String",
                                                    "keyRaw": "priceAdjustment",
                                                    "visible": true
                                                },

                                                "pricingType": {
                                                    "type": "PricingType",
                                                    "keyRaw": "pricingType",
                                                    "visible": true
                                                },

                                                "serviceId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "serviceId",
                                                    "visible": true
                                                },

                                                "tagId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "tagId",
                                                    "visible": true
                                                },

                                                "postPriceAdjustment": {
                                                    "type": "Boolean",
                                                    "keyRaw": "postPriceAdjustment",
                                                    "visible": true
                                                },

                                                "__typename": {
                                                    "type": "String",
                                                    "keyRaw": "__typename",
                                                    "visible": true
                                                }
                                            },

                                            "fragments": {
                                                "TagPricingData": {
                                                    "arguments": {}
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "ServiceTagData": {
                                        "arguments": {
                                            "serviceId": {
                                                "kind": "Variable",

                                                "name": {
                                                    "kind": "Name",
                                                    "value": "serviceId"
                                                }
                                            }
                                        }
                                    }
                                }
                            },

                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "ServiceAnswerData": {
                            "arguments": {
                                "serviceId": {
                                    "kind": "Variable",

                                    "name": {
                                        "kind": "Name",
                                        "value": "serviceId"
                                    }
                                }
                            }
                        }
                    }
                },

                "visible": true
            },

            "services": {
                "type": "Service",
                "keyRaw": "services",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "serviceId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=9e66e1ba11cb789bd1a49ddf15fc35c4b03c43160e5ae80430509f8f1b04ab8a";