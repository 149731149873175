import {
	graphql,
	ServiceTagsStore,
	CreateAnswerStore,
	CreateQuestionStore,
	CreateQuestionToServiceStore,
	CreateTagStore,
	RemoveAnswerStore,
	ServiceQuestionsStore,
	ServiceSchedulePeriodsStore,
	UpdateAnswerStore,
	updateServiceSchedulePeriodStore,
	UpdateTagPricingStore,
	CreateServiceSchedulePeriodStore,
	CreateTagPricingStore,
	ServicesManagementStore,
	CreateServiceStore,
	UpdateServiceStore,
	DeleteQuestionFromServiceStore,
	type ServicesManagement$result,
	type ServiceQuestions$result,
	type ServiceSchedulePeriods$result,
	type ServicesManagement$input,
	type EntityType$options,
	type ServiceTags$result,
} from '$houdini'

export type SelectedQuestionAnswer = Exclude<ServiceQuestions$result['questions']['data'][number]['answers'], null>[number] & { action?: unknown }
export type AdminService = Exclude<ServicesManagement$result['services']['data'], null>[number]
export type AdminServiceQuestion = ServiceQuestions$result['questions']['data'][number]
export type ServiceSchedulePeriod = ServiceSchedulePeriods$result['serviceSchedulePeriods']['data'][number] & { remove?: unknown }
export type AdminServiceTag = ServiceTags$result['tags']['data'][number]

graphql`
	fragment TagPricingData on TagPricing {
		id
		priceAdjustment
		pricingType
		serviceId
		tagId
		postPriceAdjustment
	}
`

graphql`
	fragment ServiceTagData on Tag @arguments(serviceId: { type: "PositiveInt" }) {
		id
		code
		name
		description
		prompt
		entityType
		tagPricingForService(serviceId: $serviceId) {
			...TagPricingData
		}
	}
`

graphql`
	fragment ServicePeriodData on ServiceSchedulePeriod @arguments(serviceId: { type: "PositiveInt" }) {
		id
		tagId
		serviceId
		status
		serviceSchedulePeriod
		tag {
			...ServiceTagData @with(serviceId: $serviceId)
		}
	}
`

graphql`
	fragment ServiceAnswerData on Answer @arguments(serviceId: { type: "PositiveInt" }) {
		id
		answer
		tag {
			...ServiceTagData @with(serviceId: $serviceId)
		}
	}
`

graphql`
	fragment ServiceQuestionData on Question @arguments(serviceId: { type: "PositiveInt" }) {
		id
		question
		description
		questionDataType
		answers {
			...ServiceAnswerData @with(serviceId: $serviceId)
		}
		services {
			id
		}
	}
`

graphql`
	fragment ServiceData on Service {
		id
		name
		description
		codeName
		active
		allowSubscription
		warning
		maximumDaysToPerform
		requirement
		allowNewApplication
		examplePictureFileId
		examplePictureFile {
			id
			name
			path
		}
	}
`

export const servicesQuery: ServicesManagementStore = graphql`
	query ServicesManagement($filter: ServiceFilter) {
		services(filter: $filter) {
			data {
				...ServiceData
			}
		}
	}
`

export const tagsQuery: ServiceTagsStore = graphql`
	query ServiceTags($filter: TagQueryFilter, $serviceId: PositiveInt) {
		tags(filter: $filter) {
			data {
				...ServiceTagData @with(serviceId: $serviceId)
			}
		}
	}
`

export const questionsQuery: ServiceQuestionsStore = graphql`
	query ServiceQuestions($questionFilter: QuestionFilter, $serviceId: PositiveInt) {
		questions(questionFilter: $questionFilter) {
			data {
				...ServiceQuestionData @with(serviceId: $serviceId)
			}
		}
	}
`

export const serviceSchedulePeriodsQuery: ServiceSchedulePeriodsStore = graphql`
	query ServiceSchedulePeriods($filter: ServiceSchedulePeriodFilter, $serviceId: PositiveInt) {
		serviceSchedulePeriods(filter: $filter) {
			data {
				...ServicePeriodData @with(serviceId: $serviceId)
			}
		}
	}
`

export const newServiceSchedulePeriodMutation: CreateServiceSchedulePeriodStore = graphql`
	mutation CreateServiceSchedulePeriod($serviceSchedulePeriod: NewServiceSchedulePeriod!, $serviceId: PositiveInt) {
		newServiceSchedulePeriod(serviceSchedulePeriod: $serviceSchedulePeriod) {
			...ServicePeriodData @with(serviceId: $serviceId)
		}
	}
`

export const newTagPricingMutation: CreateTagPricingStore = graphql`
	mutation CreateTagPricing($tagPricing: NewTagPricing!) {
		newTagPricing(tagPricing: $tagPricing) {
			...TagPricingData
		}
	}
`

export const newTagMutation: CreateTagStore = graphql`
	mutation CreateTag($tag: NewTag!, $serviceId: PositiveInt) {
		newTag(tag: $tag) {
			...ServiceTagData @with(serviceId: $serviceId)
		}
	}
`

export const newServiceMutation: CreateServiceStore = graphql`
	mutation CreateService($newService: NewService!) {
		newService(newService: $newService) {
			...ServiceData
		}
	}
`

export const updateServiceMutation: UpdateServiceStore = graphql`
	mutation UpdateService($editService: EditService!) {
		editService(editService: $editService) {
			...ServiceData
		}
	}
`

export const removeQuestionFromServiceMutation: DeleteQuestionFromServiceStore = graphql`
	mutation DeleteQuestionFromService($removeServiceQuestion: RemoveServiceQuestion!, $serviceId: PositiveInt) {
		removeQuestionFromService(removeServiceQuestion: $removeServiceQuestion) {
			questions {
				...ServiceQuestionData @with(serviceId: $serviceId)
			}
		}
	}
`

//TODO: Before PR Fix api to not have a float input
export const updateServiceSchedulePeriodStatusMutation: updateServiceSchedulePeriodStore = graphql`
	mutation updateServiceSchedulePeriod($serviceSchedulePeriodId: Float!) {
		toggleServiceSchedulePeriod(serviceSchedulePeriodId: $serviceSchedulePeriodId) {
			id
			tagId
			serviceId
			status
			serviceSchedulePeriod
		}
	}
`

export const deleteAnswerMutation: RemoveAnswerStore = graphql`
	mutation RemoveAnswer($deleteAnswerId: PositiveInt!) {
		deleteAnswer(id: $deleteAnswerId)
	}
`

export const newAnswerMutation: CreateAnswerStore = graphql`
	mutation CreateAnswer($answer: NewAnswer!, $serviceId: PositiveInt) {
		newAnswer(answer: $answer) {
			...ServiceAnswerData @with(serviceId: $serviceId)
		}
	}
`

export const editAnswerMutation: UpdateAnswerStore = graphql`
	mutation UpdateAnswer($editAnswer: EditAnswer!, $serviceId: PositiveInt) {
		editAnswer(editAnswer: $editAnswer) {
			...ServiceAnswerData @with(serviceId: $serviceId)
		}
	}
`

export const editTagPricingMutation: UpdateTagPricingStore = graphql`
	mutation UpdateTagPricing($tagPricing: EditTagPricing!) {
		editTagPricing(tagPricing: $tagPricing) {
			...TagPricingData
		}
	}
`

export const newServiceQuestionMutation: CreateQuestionToServiceStore = graphql`
	mutation CreateQuestionToService($newServiceQuestion: NewServiceQuestion!, $serviceId: PositiveInt) {
		addQuestionToService(newServiceQuestion: $newServiceQuestion) {
			questions {
				...ServiceQuestionData @with(serviceId: $serviceId)
			}
		}
	}
`

export const newQuestionMutation: CreateQuestionStore = graphql`
	mutation CreateQuestion($question: NewQuestion!, $serviceId: PositiveInt) {
		newQuestion(question: $question) {
			...ServiceQuestionData @with(serviceId: $serviceId)
		}
	}
`

export async function loadServices(filter?: ServicesManagement$input['filter']) {
	const { data } = await servicesQuery.fetch({
		variables: {
			filter,
		},
	})

	if (!data?.services.data) {
		return []
	}

	return data.services.data
}

export async function loadQuestions(serviceId?: number) {
	const { data } = await questionsQuery.fetch({
		variables: {
			questionFilter: {
				serviceId,
			},
			serviceId,
		},
	})

	if (!data?.questions.data) {
		return []
	}

	return data.questions.data
}

export async function loadServiceSchedulePeriods(serviceId: number) {
	const { data } = await serviceSchedulePeriodsQuery.fetch({
		variables: {
			filter: {
				serviceId,
			},
			serviceId,
		},
	})

	if (!data?.serviceSchedulePeriods.data) {
		return []
	}

	return data.serviceSchedulePeriods.data as ServiceSchedulePeriod[]
}

export async function loadTags(serviceId: number | null, entityType: EntityType$options = 'SCHEDULE') {
	const { data } = await tagsQuery.fetch({
		variables: {
			filter: {
				entityType,
			},
			serviceId,
		},
	})

	if (!data?.tags.data) {
		return []
	}

	return data.tags.data
}

export async function deleteAnswerFromQuestion(answerId: number) {
	await deleteAnswerMutation.mutate({
		deleteAnswerId: answerId,
	})
}
