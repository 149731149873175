import { parseISO } from 'date-fns'
import formatRelativeDate from 'utility/format/format-relative-date'
import formatCityStateZip from 'utility/format/format-city-state-zip'
import getComputedJobClaim, { type ComputedJobClaim, type ComputedJobClaimBase } from 'utility/get-computed-job-claim'
interface ComputedJobBase {
	created?: Date | string
	requestedSchedule?: Date | string
	residence: {
		city: string
		state: string
		zip: string
	}
	completed: Date | null
	jobClaims?: ComputedJobClaimBase[] | null
}

export interface ComputedJob extends ComputedJobBase {
	requestedScheduleFormatted: string
	completedFormatted: string
	jobClaims: ComputedJobClaim[]
	residence: {
		cityStateZipFormatted: string
		city: string
		state: string
		zip: string
	}
	created?: Date
	requestedSchedule?: Date
}

export function getComputedJob<T extends ComputedJobBase>(job: T, { today = new Date(), relativeDates = true, includeZip = true } = {}): T & ComputedJob {
	const requestedSchedule = job.requestedSchedule instanceof Date ? job.requestedSchedule : job.requestedSchedule ? parseISO(job.requestedSchedule) : undefined
	const completed = job.completed instanceof Date ? job.completed : job.completed ? parseISO(job.completed) : null
	const created = job.created instanceof Date ? job.created : job.created ? parseISO(job.created) : undefined

	if (!job.residence) {
		throw new Error('Missing required residence fields')
	}

	const completedFormatted = getCompletedFormatted(completed, today, relativeDates)

	const res: T & ComputedJob = {
		...job,
		created,
		requestedSchedule,
		requestedScheduleFormatted: `Requested for ${relativeDates ? formatRelativeDate(requestedSchedule, today).formattedDate : requestedSchedule?.toLocaleDateString() ?? ''}`,
		residence: {
			...job.residence,
			cityStateZipFormatted: formatCityStateZip(
				{
					city: job.residence.city,
					state: job.residence.state,
					zip: job.residence.zip,
				},
				includeZip
			),
		},
		completedFormatted: `Completed ${completedFormatted}`,
		completed,
		jobClaims: [],
	}
	if (job.jobClaims) {
		res.jobClaims = job.jobClaims.map(jobClaim => getComputedJobClaim(jobClaim, today))
	}
	return res
}

export function getCompletedFormatted(completed: Date | null, today: Date = new Date(), relativeDates: boolean = true): string {
	if (completed && relativeDates) {
		const { formattedDate, isRelativeFormat } = formatRelativeDate(completed, today)
		return isRelativeFormat ? formattedDate : `on ${formattedDate}`
	} else {
		return completed ? `on ${completed.toLocaleDateString()} ${completed.toLocaleTimeString()}` : ''
	}
}

export default getComputedJob
