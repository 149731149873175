import { loadServices } from 'utility/service-helper'
import component from './Service.svelte'

import { AppContext } from 'types/common'

export default function ({ stateRouter }: AppContext) {
	stateRouter.addState({
		name: 'app.admin.service',
		route: 'service',
		template: {
			component,
			svelte: true,
			options: {},
		},
		async resolve(_data, _parameters) {
			const services = await loadServices()
			return {
				services,
			}
		},
	})
}
