<script lang="ts">
	import Button from '@isoftdata/svelte-button'
	import Modal from '@isoftdata/svelte-modal'
	import Attachments, { type BaseAttachmentFile, type UnsavedAttachmentFile } from '@isoftdata/svelte-attachments'
	import ImageViewer from '@isoftdata/svelte-image-viewer'
	import ProviderProfilePicture from './ProviderProfilePicture.svelte'
	import jobClaimStatusMap from 'utility/job-claim-status-map'
	import formatImageFileUrl from 'utility/format/format-image-file-url'
	import { getCompletedFormatted } from 'utility/get-computed-job'
	import formatRelativeDateTime from 'utility/format/format-relative-date-time'
	import { attachNewFilesToJob, detachFilesFromJob, getMaxImageDimensions } from 'utility/graphql/manage-image-files'
	import { ImageFileType, type ImageFileType$options, type JobClaimStatus$options } from '$houdini'
	import { upsert } from '@isoftdata/utility-array'
	import { klona } from 'klona'

	type JobClaim = {
		jobClaimStatus: JobClaimStatus$options
		scheduledAt: Date | null
		provider?: {
			userAccountId: number
			userAccount: {
				fullName: string | null
			}
			profilePictureFile: {
				path: string
			} | null
		}
		cancellationReason?: string | null
		internalNotes?: string | null
	}

	type Job = {
		id: number
		jobStatus: string
		scheduledAt?: Date | null
		completed?: Date | null
		completedFormatted?: string | null
		activeJobClaim?: { scheduledAtFormatted: string } | null
		files: Array<BaseAttachmentFile & { file?: { path: string } }> | null
	}

	export let jobClaim: JobClaim
	export let job: Job
	export let adminView = false

	let showImageViewer = false
	let photoViewerPhotoIndex = 0

	const defaultAdminPhotoAttachmentModalState: typeof adminPhotoAttachmentModal = Object.freeze({
		fileAssociation: {
			jobId: null,
			imageFileType: ImageFileType.OTHER,
			userAccountId: null,
		},
		maxImageDimensions: 1600,
		show: false,
	})

	let adminPhotoAttachmentModal: {
		show: boolean
		fileAssociation: {
			jobId: number | null
			imageFileType: ImageFileType$options
			userAccountId: number | null
		}
		maxImageDimensions: number
	} = {
		fileAssociation: {
			jobId: null,
			imageFileType: ImageFileType.OTHER,
			userAccountId: null,
		},
		maxImageDimensions: 1600,
		show: false,
	}

	async function openPhotoModal() {
		if (adminView && jobClaim.provider) {
			try {
				adminPhotoAttachmentModal.maxImageDimensions = await getMaxImageDimensions()
			} catch (err: unknown) {
				const error = err as Error
				console.error(error)
			}
			adminPhotoAttachmentModal.show = true
			adminPhotoAttachmentModal.fileAssociation = {
				jobId: job.id,
				imageFileType: ImageFileType.OTHER,
				userAccountId: jobClaim.provider?.userAccountId,
			}
		} else {
			showImageViewer = true
			photoViewerPhotoIndex = 0
		}
	}

	async function addFiles(e: CustomEvent<UnsavedAttachmentFile[]>) {
		try {
			const filesAdded = e.detail

			let uploadedFiles: Array<BaseAttachmentFile> = []

			for (const file of filesAdded) {
				const uploadedFile = await attachNewFilesToJob(file, adminPhotoAttachmentModal.fileAssociation)
				uploadedFiles.push({
					...file,
					...uploadedFile,
				})
			}
			uploadedFiles.forEach(uploadedFile => (job.files ? upsert(job.files, 'uuid', uploadedFile) : (job.files = [uploadedFile])))
			job.files = job.files
		} catch (err: unknown) {
			const error = err as Error
			console.error(error)
		}
	}

	async function deleteFiles(e: CustomEvent<BaseAttachmentFile[]>) {
		try {
			const filesDeleted = e.detail

			if (!job.id) {
				throw new Error('Job ID is required to delete files')
			}

			const fileIdsToDelete = filesDeleted.map(file => {
				if (!file.fileId) {
					throw new Error('File ID is required to delete file')
				} else {
					return file.fileId
				}
			})
			try {
				await detachFilesFromJob(job.id, fileIdsToDelete)
				fileIdsToDelete.forEach(fileId => {
					job.files = job.files?.filter(file => file.fileId !== fileId) ?? []
				})
			} catch (err: unknown) {
				const error = err as Error
				console.error(error)
			}
		} catch (err: unknown) {
			const error = err as Error
			console.error(error)
		}
	}

	$: profilePictureUrl = jobClaim.provider?.profilePictureFile?.path ? formatImageFileUrl(jobClaim.provider?.profilePictureFile?.path ?? '') : null
	$: photoFilePaths = job?.files ? job.files.map(metadata => (metadata?.file?.path ? formatImageFileUrl(metadata.file.path) : '')) : []
</script>

<div class="d-flex align-items-center">
	<ProviderProfilePicture {profilePictureUrl} />
	<div class="pl-1 pl-md-3">
		<ul
			style="list-style-type: none;"
			class="pl-0"
		>
			<li>
				<h5>{jobClaim.provider?.userAccount.fullName}</h5>
			</li>
			<li>
				{#if jobClaim.jobClaimStatus}
					<span class="badge badge-{jobClaimStatusMap[jobClaim.jobClaimStatus].class}">
						{#if jobClaimStatusMap[jobClaim.jobClaimStatus].iconClass}
							<i class={jobClaimStatusMap[jobClaim.jobClaimStatus].iconClass}></i>
						{/if}
						{jobClaimStatusMap[jobClaim.jobClaimStatus].label}
					</span>
				{/if}
			</li>
			<li>
				{#if job && job.jobStatus === 'COMPLETED' && job.completed}
					{`Completed ${getCompletedFormatted(job.completed)}`}
				{:else if jobClaim.scheduledAt}
					{`Scheduled for ${jobClaim ? formatRelativeDateTime(jobClaim?.scheduledAt, new Date()).formattedDate : ''}`}
				{:else}
					Not Scheduled
				{/if}
			</li>
			{#if jobClaim.cancellationReason && adminView}
				<li>
					<strong>Cancellation Reason:</strong>
					{jobClaim.cancellationReason}
				</li>
			{/if}
			{#if jobClaim.internalNotes && adminView}
				<li>
					<strong>Internal Notes:</strong>
					{jobClaim.internalNotes}
				</li>
			{/if}
		</ul>
		{#if (job?.files && job.files.length > 0) || adminView}
			<Button
				outline
				iconClass="image"
				size="sm"
				color="info"
				class="mt-2"
				on:click={() => {
					openPhotoModal()
				}}>View Photos</Button
			>
		{/if}
	</div>
</div>

<Modal
	bind:show={adminPhotoAttachmentModal.show}
	title="Upload Photos"
	confirmButtonText="Close Viewer"
	cancelShown={false}
	modalSize="lg"
	on:close={() => {
		adminPhotoAttachmentModal = klona(defaultAdminPhotoAttachmentModalState)
	}}
	on:backdrop={() => {
		adminPhotoAttachmentModal = klona(defaultAdminPhotoAttachmentModalState)
	}}
	on:confirm={() => {
		adminPhotoAttachmentModal = klona(defaultAdminPhotoAttachmentModalState)
	}}
>
	<Attachments
		selectionButtonsDisabled
		hideRankFeatures
		hidePublicFeatures
		uniqueFileTypeSpecifer="image/*"
		fileList={job.files ?? []}
		on:filesAdded={e => {
			addFiles(e)
		}}
		on:filesDeleted={e => {
			deleteFiles(e)
		}}
		maxImageSize={adminPhotoAttachmentModal.maxImageDimensions}
	/>
</Modal>

<ImageViewer
	title="Job Photos"
	bind:show={showImageViewer}
	showIndicators={false}
	files={photoFilePaths}
	bind:currentPhotoIndex={photoViewerPhotoIndex}
	on:close={() => {
		showImageViewer = false
		photoViewerPhotoIndex = 0
	}}
/>
