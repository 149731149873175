export default {
    "name": "ServicesManagement",
    "kind": "HoudiniQuery",
    "hash": "7477f604c104f9ef831c041f9f7434953282beb39b1b683ee24fc9c126e51dfa",

    "raw": `query ServicesManagement($filter: ServiceFilter) {
  services(filter: $filter) {
    data {
      ...ServiceData
      id
    }
  }
}

fragment ServiceData on Service {
  id
  name
  description
  codeName
  active
  allowSubscription
  warning
  maximumDaysToPerform
  requirement
  allowNewApplication
  examplePictureFileId
  examplePictureFile {
    id
    name
    path
  }
  __typename
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "services": {
                "type": "ServiceResponse",
                "keyRaw": "services(filter: $filter)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Service",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "codeName": {
                                        "type": "String",
                                        "keyRaw": "codeName",
                                        "visible": true
                                    },

                                    "active": {
                                        "type": "Boolean",
                                        "keyRaw": "active",
                                        "visible": true
                                    },

                                    "allowSubscription": {
                                        "type": "Boolean",
                                        "keyRaw": "allowSubscription",
                                        "visible": true
                                    },

                                    "warning": {
                                        "type": "String",
                                        "keyRaw": "warning",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "maximumDaysToPerform": {
                                        "type": "PositiveInt",
                                        "keyRaw": "maximumDaysToPerform",
                                        "visible": true
                                    },

                                    "requirement": {
                                        "type": "String",
                                        "keyRaw": "requirement",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "allowNewApplication": {
                                        "type": "Boolean",
                                        "keyRaw": "allowNewApplication",
                                        "visible": true
                                    },

                                    "examplePictureFileId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "examplePictureFileId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "examplePictureFile": {
                                        "type": "FileEntity",
                                        "keyRaw": "examplePictureFile",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "path": {
                                                    "type": "String",
                                                    "keyRaw": "path",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "ServiceData": {
                                        "arguments": {}
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "ServiceFilter"
        },

        "types": {
            "ServiceFilter": {
                "active": "Boolean",
                "allowNewApplication": "Boolean",
                "allowSubscription": "Boolean",
                "regionId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=38f3914d162c03471c54df663ebdfdb7ed5c17c68a6bf5ede34da36cc5c26bec";