import { graphql, OrderHistoryTipJobStore, OrderHistoryReviewJobStore } from '$houdini'

const orderHistoryTipJobMutation: OrderHistoryTipJobStore = graphql`
	mutation OrderHistoryTipJob($tip: TipInput!, $review: String) {
		tipJob(tip: $tip, review: $review) {
			id
			residence {
				street
				city
				state
				zip
				country
				googleMapsPlaceId
			}
			jobClaims {
				provider {
					userAccountId
					userAccount {
						fullName
					}
					profilePictureFile {
						path
					}
				}
				jobClaimStatus
				scheduledAt
			}
			jobStatus
			requestedSchedule
			completed
			transaction {
				subtotal
				total
				paymentStatus
				tip
				internalNotes
			}
			review
			service {
				name
			}
			answers {
				id
				answer
			}
			files {
				id
				jobId
				userAccountId
				fileId
				file {
					id
					name
					created
					updated
					hash
					path
					mimeType
					type
				}
				public
				rank
				imageFileType
			}
		}
	}
`

const orderHistoryReviewJobMutation: OrderHistoryReviewJobStore = graphql`
	mutation OrderHistoryReviewJob($jobId: PositiveInt!, $review: String) {
		reviewJob(jobId: $jobId, review: $review) {
			id
			residence {
				street
				city
				state
				zip
				country
				googleMapsPlaceId
			}
			jobClaims {
				provider {
					userAccountId
					userAccount {
						fullName
					}
					profilePictureFile {
						path
					}
				}
				jobClaimStatus
				scheduledAt
			}
			jobStatus
			requestedSchedule
			completed
			transaction {
				subtotal
				total
				paymentStatus
				tip
				internalNotes
			}
			review
			service {
				name
			}
			answers {
				id
				answer
			}
			files {
				id
				jobId
				userAccountId
				fileId
				file {
					id
					name
					created
					updated
					hash
					path
					mimeType
					type
				}
				public
				rank
				imageFileType
			}
		}
	}
`

export async function orderHistoryTipJob(tip: { jobId: number; amount: string; customerWalletId?: number; token?: string }, review: string | null) {
	const { data } = await orderHistoryTipJobMutation.mutate({
		tip,
		review,
	})

	if (!data?.tipJob) {
		throw new Error('Failed to tip job')
	}

	return data.tipJob
}

export async function orderHistoryReviewJob(jobId: number, review: string | null) {
	const { data } = await orderHistoryReviewJobMutation.mutate({
		jobId,
		review,
	})

	if (!data?.reviewJob) {
		throw new Error('Failed to review job')
	}

	return data.reviewJob
}
