export default {
    "name": "AdminRemoveProviderProfilePicture",
    "kind": "HoudiniMutation",
    "hash": "6427e4780873370ddc29115d8274c1effb498e92ad328a5ea17cfaf25f74aab1",

    "raw": `mutation AdminRemoveProviderProfilePicture($providerId: PositiveInt!, $fileId: PositiveInt!) {
  detachFilesFromProvider(providerId: $providerId, fileId: $fileId)
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "detachFilesFromProvider": {
                "type": "Boolean",
                "keyRaw": "detachFilesFromProvider(fileId: $fileId, providerId: $providerId)",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "providerId": "PositiveInt",
            "fileId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=fa8ae0fc4be2cc80c13e7dd0f41d73360cb060cc36e9547d8cf103bab8e0d5ac";