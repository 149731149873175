export default {
    "name": "ServiceTagData",
    "kind": "HoudiniFragment",
    "hash": "62c9fecc20f4efaa65c417c6a1ac83b2c90b21429ba2c2e75e8dce446e495c4c",

    "raw": `fragment ServiceTagData on Tag {
  id
  code
  name
  description
  prompt
  entityType
  tagPricingForService(serviceId: $serviceId) {
    ...TagPricingData
    id
  }
  __typename
}

fragment TagPricingData on TagPricing {
  id
  priceAdjustment
  pricingType
  serviceId
  tagId
  postPriceAdjustment
  __typename
}
`,

    "rootType": "Tag",
    "stripVariables": [],

    "selection": {
        "fields": {
            "id": {
                "type": "PositiveInt",
                "keyRaw": "id",
                "visible": true
            },

            "code": {
                "type": "String",
                "keyRaw": "code",
                "visible": true
            },

            "name": {
                "type": "String",
                "keyRaw": "name",
                "visible": true
            },

            "description": {
                "type": "String",
                "keyRaw": "description",
                "nullable": true,
                "visible": true
            },

            "prompt": {
                "type": "String",
                "keyRaw": "prompt",
                "nullable": true,
                "visible": true
            },

            "entityType": {
                "type": "EntityType",
                "keyRaw": "entityType",
                "visible": true
            },

            "tagPricingForService": {
                "type": "TagPricing",
                "keyRaw": "tagPricingForService(serviceId: $serviceId)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "priceAdjustment": {
                            "type": "String",
                            "keyRaw": "priceAdjustment",
                            "visible": true
                        },

                        "pricingType": {
                            "type": "PricingType",
                            "keyRaw": "pricingType",
                            "visible": true
                        },

                        "serviceId": {
                            "type": "PositiveInt",
                            "keyRaw": "serviceId",
                            "visible": true
                        },

                        "tagId": {
                            "type": "PositiveInt",
                            "keyRaw": "tagId",
                            "visible": true
                        },

                        "postPriceAdjustment": {
                            "type": "Boolean",
                            "keyRaw": "postPriceAdjustment",
                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "TagPricingData": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "serviceId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=0bdb03f6096d3395c019851728f5245a3d14c0963802cc45e2a43c26f88b5142";