<script lang="ts">
	import Button from '@isoftdata/svelte-button'
	import Modal from '@isoftdata/svelte-modal'
	import CurrencyInput from '@isoftdata/svelte-currency-input'
	import TextArea from '@isoftdata/svelte-textarea'
	import CustomerOrderListItem from 'components/CustomerOrderListItem.svelte'
	import CustomerOrderDetail from 'components/CustomerOrderDetail.svelte'
	import { formatCurrency } from 'utility/format/format-currency'
	import { orderHistoryReviewJob, orderHistoryTipJob } from 'utility/order-history-helper'
	import { type CompletedOrdersData } from 'utility/load-completed-orders'
	import type { SvelteAsr } from 'types/common'
	import Throbber from 'components/Throbber.svelte'
	import NewCardEntry from 'components/NewCardEntry.svelte'
	import CardPicker from 'components/CardPicker.svelte'
	import { addCustomerCardToWallet, type PaymentMethodCard, type PaymentMethodSuccessResponse, getActiveWallet } from 'utility/payment-method-helper'
	import financialNumber from 'financial-number'
	import type { OrderListItemJob } from 'utility/graphql/get-customer-order-list-item'

	export let asr: SvelteAsr

	// Probably in the future the jobs should be paginated and some sort of paging should be in place for load times
	// The Order data is rather complicated with lots of joins and it starting to take a bit long on dev with lots of jobs on one account

	export let jobs: CompletedOrdersData[]
	export let customerHasJobsFromMultipleCities: boolean = false // This is set to false currently to disable city state zip showing
	export let cards: PaymentMethodCard[] = []
	export let tipLabelWidth: number = 100
	export let customerWalletId: number | null = null
	export let selectedJobId: number | null = null
	export let selectedJob: ReturnType<typeof formatSelectedJobFiles> | undefined = undefined

	let loadingJob: boolean = false
	let showTipModal: boolean = false
	let showReviewModal: boolean = false

	let tipAmount: string = ''
	let selectedTip: string = ''
	let isProcessing = false
	let reviewText = ''
	let tipError = ''
	let reviewError = ''

	let cardInfo: PaymentMethodSuccessResponse

	$: submitTipButtonText = tipAmount !== '' ? `Tip ${formatCurrency(tipAmount)}` : 'Enter Tip Amount'
	$: subtotalForSelectedJob = selectedJob ? formatCurrency(selectedJob.transaction?.subtotal) : ''
	$: computedTipAmountOptions = [
		{ label: '15%', value: 0.15 },
		{ label: '20%', value: 0.2 },
		{ label: '25%', value: 0.25 },
		{ label: 'Custom', value: 'Custom' },
	].map(option => {
		const selectedJobTotal = selectedJob?.transaction?.subtotal ?? '0'
		return {
			...option,
			amount: option.label === 'Custom' ? 'Custom' : financialNumber(selectedJobTotal).times(option.value.toString()).changePrecision(2).toString(),
		}
	})
	$: tipLabelWidth = Math.floor(100 / computedTipAmountOptions.length || 1) || 100

	function formatSelectedJobFiles(jobToFormat: Exclude<OrderListItemJob, null>) {
		return {
			...jobToFormat,
			files:
				jobToFormat.files?.map(file => ({
					...file,
					...file.file,
					name: file.file?.name ?? '',
					mimeType: file.file?.mimeType ?? '',
					size: 0,
				})) || [],
		}
	}

	async function openTipModal() {
		showTipModal = true
		const activeWallet = (await getActiveWallet()) ?? []
		cards = activeWallet
		customerWalletId = cards[0]?.id
		reviewText = selectedJob?.review ?? ''
		selectedTip = computedTipAmountOptions[0].label
		tipAmount = computedTipAmountOptions[0].amount
	}

	async function submitTip() {
		if (!selectedJobId) {
			tipError = 'Please select a job to tip'
			return
		}
		if (selectedTip !== 'Custom' && !selectedTip) {
			tipError = 'Please select a tip amount'
			return
		}
		isProcessing = true
		const token = cardInfo?.token ?? ''
		try {
			const tipJob = await orderHistoryTipJob(
				{
					jobId: selectedJobId,
					amount: tipAmount,
					...(customerWalletId ? { customerWalletId } : { token }),
				},
				reviewText ?? '',
			)
			const jobIndex = jobs.findIndex(job => job.id === selectedJobId)
			if (jobIndex !== -1) {
				jobs[jobIndex] = tipJob
			}

			selectedJob = formatSelectedJobFiles(tipJob)

			tipAmount = ''
			selectedTip = ''
			customerWalletId = null
			reviewText = ''
			showTipModal = false
		} catch (err: unknown) {
			const error = err as Error
			tipError = error.message
		} finally {
			isProcessing = false
		}
	}

	function closeTipModal() {
		tipAmount = ''
		tipError = ''
		customerWalletId = null
		showTipModal = false
	}

	function openReviewModal() {
		showReviewModal = true
		const reviewTextArea = document.getElementById('reviewTextArea')
		if (reviewTextArea) {
			reviewTextArea.focus()
		}
	}

	async function submitReview() {
		if (!selectedJobId) {
			reviewError = 'Please select a job to review'
			return
		}
		isProcessing = true
		try {
			const reviewJob = await orderHistoryReviewJob(selectedJobId, reviewText)
			const jobIndex = jobs.findIndex(job => job.id === selectedJobId)

			if (jobIndex !== -1) {
				jobs[jobIndex] = reviewJob
			}

			selectedJob = formatSelectedJobFiles(reviewJob)

			reviewText = ''
			showReviewModal = false
		} catch (err: unknown) {
			const error = err as Error
			reviewError = error.message
		} finally {
			isProcessing = false
		}
	}

	function closeReviewModal() {
		reviewText = ''
		showReviewModal = false
	}

	async function addCardToWallet(card: PaymentMethodSuccessResponse) {
		try {
			const newCard = await addCustomerCardToWallet(card)
			cards.push(newCard)
			cards = cards
			customerWalletId = newCard.id
			tipError = ''
		} catch (err: unknown) {
			const error = err as Error
			console.error(error)
		}
	}

	async function addCardToWalletTokenSuccess(e: CustomEvent<PaymentMethodSuccessResponse>) {
		if (e.detail.cardType === 'amex') {
			tipError = 'American Express cards are not currently supported. Please use a Visa, Mastercard, or Discover card.'
		} else {
			await addCardToWallet(e.detail)
		}
	}
	function addCardToWalletTokenError() {
		tipError = 'An unknown error occurred while adding your card to your wallet. Please try again later.'
	}
</script>

<div class="row">
	<div class="col-lg-4 col-xl-3">
		<div class="d-flex justify-content-between">
			<h4>Order History</h4>
		</div>
		<div
			class="list-group"
			style="overflow-y: auto; height: 50vh;"
		>
			{#if jobs.length === 0}
				<div class="list-group-item list-group-item-action">
					<div class="d-flex flex-column">
						<h6>No Orders Found</h6>
					</div>
				</div>
			{:else}
				{#each jobs as job}
					<CustomerOrderListItem
						{job}
						showCityStateZip={customerHasJobsFromMultipleCities}
						bind:selectedJobId
						bind:selectedJob
						bind:loadingJob
					/>
				{/each}
			{/if}
		</div>
	</div>
	<div class="col-lg-8 col-xl-9">
		<h4 class="mt-3 mt-lg-0">Order Details</h4>
		{#if loadingJob}
			<div class="text-center mt-2">
				<Throbber />
			</div>
		{:else if selectedJobId !== null && selectedJob}
			<CustomerOrderDetail
				bind:job={selectedJob}
				showFooter={selectedJob?.jobStatus !== 'EXPIRED' && (!selectedJob.transaction?.tip || !selectedJob?.review)}
			>
				<svelte:fragment slot="cardFooter">
					{#if selectedJob?.jobStatus !== 'EXPIRED'}
						<div class="d-flex">
							{#if !selectedJob.transaction?.tip}
								<Button
									class="mr-1"
									on:click={() => openTipModal()}>Leave a Tip</Button
								>
							{/if}
							{#if !selectedJob.review}
								<Button on:click={() => openReviewModal()}>Leave a Review</Button>
							{/if}
						</div>
					{/if}
				</svelte:fragment>
			</CustomerOrderDetail>
		{:else if selectedJobId === null && jobs.length === 0}
			<div class="alert alert-info">
				<span>No orders found.</span>
				<a href={asr.makePath('app.new-order')}>Click Here to start a new Order</a>
			</div>
		{:else}
			<div class="alert alert-info">Select an order to view details</div>
		{/if}
	</div>
</div>

<Modal
	bind:show={showTipModal}
	title="Tip"
	confirmButtonColor="primary"
	cancelButtonText="Cancel"
	confirmButtonText={submitTipButtonText}
	confirmShown={!!cards}
	confirmButtonType="submit"
	confirmButtonFormId="tippingForm"
	confirmButtonDisabled={selectedTip === '' || (selectedTip === 'Custom' && (!tipAmount || parseFloat(tipAmount) <= 0)) || isProcessing}
	on:confirm={() => {
		submitTip()
	}}
	on:close={() => {
		closeTipModal()
	}}
	on:backdrop={() => {
		closeTipModal()
	}}
>
	<form
		id="tippingForm"
		on:submit
	>
		<div class="form-group">
			{#if !selectedJob?.review}
				<TextArea
					id="reviewTextArea"
					label="Add a review (Optional)"
					showLabel={false}
					labelClass="py-0 mb-2"
					textareaStyle="min-height: 83px;"
					placeholder="Leave a review for your provider..."
					bind:value={reviewText}
				/>
			{/if}
			<label
				for="tip-buttons"
				class="mb-0 py-2">Tip Amount (Total was {subtotalForSelectedJob})</label
			>
			<div
				class="btn-group btn-group-toggle w-100"
				id="tip-buttons"
				style="width: {tipLabelWidth}%;"
			>
				{#each computedTipAmountOptions as tipOption}
					<label
						class="btn btn-outline-primary d-flex align-items-center justify-content-center"
						class:active={selectedTip === tipOption.label}
						style="width: {tipLabelWidth}%;"
					>
						<input
							type="radio"
							name={tipOption.label}
							value={tipOption.label}
							bind:group={selectedTip}
							on:change={() => {
								if (tipOption.amount === 'Custom') {
									tipAmount = ''
								} else {
									tipAmount = tipOption.amount
								}
							}}
						/>
						<div>
							<span class:font-weight-bold={tipOption.label !== 'Custom'}>{tipOption.label}</span><br />
							{#if tipOption.label !== 'Custom'}
								{formatCurrency(tipOption.amount.toString())}
							{/if}
						</div>
					</label>
				{/each}
			</div>
			{#if selectedTip === 'Custom'}
				<CurrencyInput
					id="customTipInput"
					placeholder="Enter tip amount"
					bind:value={tipAmount}
					required={selectedTip === 'Custom'}
					validation={{
						value: tipAmount,
					}}
				/>
				{#if parseFloat(tipAmount) <= 0}
					<small class="form-text text-danger text-right"> Please enter a valid tip amount </small>
				{/if}
			{/if}
		</div>
		<h5>Card</h5>
		{#if cards.length === 0}
			<NewCardEntry
				on:tokenSuccess={e => addCardToWalletTokenSuccess(e)}
				on:tokenError={() => addCardToWalletTokenError()}
				submitButtonText="Add Card to Wallet"
				submitButtonDisabled={isProcessing || (selectedTip === 'Custom' && !tipAmount)}
			/>
		{:else}
			<CardPicker bind:cards />
		{/if}
	</form>
	{#if tipError}
		<div class="alert alert-danger mt-2">{tipError}</div>
	{/if}
</Modal>

<Modal
	bind:show={showReviewModal}
	title="Leave a Review"
	confirmButtonColor="primary"
	confirmButtonText="Post Review"
	cancelButtonText="Cancel"
	confirmButtonType="submit"
	confirmButtonDisabled={!reviewText}
	on:confirm={() => {
		submitReview()
	}}
	on:close={() => {
		closeReviewModal()
	}}
	on:backdrop={() => {
		closeReviewModal()
	}}
>
	<TextArea
		id="reviewTextArea"
		showLabel={false}
		labelClass="py-0 mb-2"
		textareaStyle="min-height: 83px;"
		placeholder="Leave a review for your provider..."
		bind:value={reviewText}
	/>
	{#if reviewError}
		<div class="alert alert-danger mt-2">{reviewError}</div>
	{/if}
</Modal>
