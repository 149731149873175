export default {
    "name": "OrderHistoryReviewJob",
    "kind": "HoudiniMutation",
    "hash": "6681d0cec6e55375c5379724309e0b2a56f278c8dc891261fd3f9b05fd50f9ce",

    "raw": `mutation OrderHistoryReviewJob($jobId: PositiveInt!, $review: String) {
  reviewJob(jobId: $jobId, review: $review) {
    id
    residence {
      street
      city
      state
      zip
      country
      googleMapsPlaceId
      id
    }
    jobClaims {
      provider {
        userAccountId
        userAccount {
          fullName
          id
        }
        profilePictureFile {
          path
          id
        }
        id
      }
      jobClaimStatus
      scheduledAt
      id
    }
    jobStatus
    requestedSchedule
    completed
    transaction {
      subtotal
      total
      paymentStatus
      tip
      internalNotes
      id
    }
    review
    service {
      name
      id
    }
    answers {
      id
      answer
    }
    files {
      id
      jobId
      userAccountId
      fileId
      file {
        id
        name
        created
        updated
        hash
        path
        mimeType
        type
      }
      public
      rank
      imageFileType
    }
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "reviewJob": {
                "type": "Job",
                "keyRaw": "reviewJob(jobId: $jobId, review: $review)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "residence": {
                            "type": "Residence",
                            "keyRaw": "residence",

                            "selection": {
                                "fields": {
                                    "street": {
                                        "type": "String",
                                        "keyRaw": "street",
                                        "visible": true
                                    },

                                    "city": {
                                        "type": "String",
                                        "keyRaw": "city",
                                        "visible": true
                                    },

                                    "state": {
                                        "type": "String",
                                        "keyRaw": "state",
                                        "visible": true
                                    },

                                    "zip": {
                                        "type": "String",
                                        "keyRaw": "zip",
                                        "visible": true
                                    },

                                    "country": {
                                        "type": "String",
                                        "keyRaw": "country",
                                        "visible": true
                                    },

                                    "googleMapsPlaceId": {
                                        "type": "String",
                                        "keyRaw": "googleMapsPlaceId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "jobClaims": {
                            "type": "JobClaim",
                            "keyRaw": "jobClaims",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "provider": {
                                        "type": "Provider",
                                        "keyRaw": "provider",

                                        "selection": {
                                            "fields": {
                                                "userAccountId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "userAccountId",
                                                    "visible": true
                                                },

                                                "userAccount": {
                                                    "type": "UserAccount",
                                                    "keyRaw": "userAccount",

                                                    "selection": {
                                                        "fields": {
                                                            "fullName": {
                                                                "type": "String",
                                                                "keyRaw": "fullName",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "profilePictureFile": {
                                                    "type": "FileEntity",
                                                    "keyRaw": "profilePictureFile",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "path": {
                                                                "type": "String",
                                                                "keyRaw": "path",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "jobClaimStatus": {
                                        "type": "JobClaimStatus",
                                        "keyRaw": "jobClaimStatus",
                                        "visible": true
                                    },

                                    "scheduledAt": {
                                        "type": "DateTime",
                                        "keyRaw": "scheduledAt",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "jobStatus": {
                            "type": "JobStatus",
                            "keyRaw": "jobStatus",
                            "visible": true
                        },

                        "requestedSchedule": {
                            "type": "Date",
                            "keyRaw": "requestedSchedule",
                            "visible": true
                        },

                        "completed": {
                            "type": "DateTime",
                            "keyRaw": "completed",
                            "nullable": true,
                            "visible": true
                        },

                        "transaction": {
                            "type": "Transaction",
                            "keyRaw": "transaction",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "subtotal": {
                                        "type": "String",
                                        "keyRaw": "subtotal",
                                        "visible": true
                                    },

                                    "total": {
                                        "type": "String",
                                        "keyRaw": "total",
                                        "visible": true
                                    },

                                    "paymentStatus": {
                                        "type": "PaymentStatus",
                                        "keyRaw": "paymentStatus",
                                        "visible": true
                                    },

                                    "tip": {
                                        "type": "String",
                                        "keyRaw": "tip",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "internalNotes": {
                                        "type": "String",
                                        "keyRaw": "internalNotes",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "review": {
                            "type": "String",
                            "keyRaw": "review",
                            "nullable": true,
                            "visible": true
                        },

                        "service": {
                            "type": "Service",
                            "keyRaw": "service",

                            "selection": {
                                "fields": {
                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "answers": {
                            "type": "Answer",
                            "keyRaw": "answers",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "answer": {
                                        "type": "String",
                                        "keyRaw": "answer",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "files": {
                            "type": "JobFile",
                            "keyRaw": "files",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "jobId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "jobId",
                                        "visible": true
                                    },

                                    "userAccountId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "userAccountId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "fileId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "fileId",
                                        "visible": true
                                    },

                                    "file": {
                                        "type": "FileEntity",
                                        "keyRaw": "file",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "created": {
                                                    "type": "DateTime",
                                                    "keyRaw": "created",
                                                    "visible": true
                                                },

                                                "updated": {
                                                    "type": "DateTime",
                                                    "keyRaw": "updated",
                                                    "visible": true
                                                },

                                                "hash": {
                                                    "type": "String",
                                                    "keyRaw": "hash",
                                                    "visible": true
                                                },

                                                "path": {
                                                    "type": "String",
                                                    "keyRaw": "path",
                                                    "visible": true
                                                },

                                                "mimeType": {
                                                    "type": "String",
                                                    "keyRaw": "mimeType",
                                                    "visible": true
                                                },

                                                "type": {
                                                    "type": "FileType",
                                                    "keyRaw": "type",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "public": {
                                        "type": "Boolean",
                                        "keyRaw": "public",
                                        "visible": true
                                    },

                                    "rank": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "rank",
                                        "visible": true
                                    },

                                    "imageFileType": {
                                        "type": "ImageFileType",
                                        "keyRaw": "imageFileType",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "jobId": "PositiveInt",
            "review": "String"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=aa1305e024882d0fa6766bc96abc67497c139bf9cb9f032926ac7b497cc49c5e";