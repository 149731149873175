import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/PermissionList'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class PermissionListStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "PermissionListStore",
			variables: false,
		})
	}
}

export async function load_PermissionList(params) {
	await initClient()

	const store = new PermissionListStore()

	await store.fetch(params)

	return {
		PermissionList: store,
	}
}
