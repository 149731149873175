<script lang="ts">
	import type { ButtonColors } from '@isoftdata/utility-bootstrap'
	import type { IconName } from '@fortawesome/fontawesome-common-types'
	import { createEventDispatcher } from 'svelte'

	const dispatch = createEventDispatcher()

	export let profilePictureUrl: string | null = null
	export let pictureSize = 80
	export let showFloatingButton = false
	export let floatingButtonColor: ButtonColors = 'primary'
	export let floatingButtonOutline: boolean = true
	export let floatingButtonIconClass: IconName = 'camera'
	export let floatingButtonIconStyle: string | null = null
	export let floatingButtonSize: '' | 'xs' | 'sm' | 'lg' = 'sm'
	export let clickable = false

	$: floatingButtonWrapperClass = floatingButtonSize === 'xs' ? 'btn-xs-floating-position' : 'btn-floating-position'
	$: floatingButtonClass = floatingButtonSize === 'xs' ? 'btn-xs-floating' : 'btn-floating'
	$: computedSize = floatingButtonSize ? `btn-${floatingButtonSize}` : ''
	$: computedColor = `btn-${floatingButtonOutline ? 'outline-' : ''}${floatingButtonColor}`
	$: computedClass = `btn ${floatingButtonClass} ${computedColor} ${computedSize} cursor-pointer`
	$: computedIconSize = floatingButtonSize ? `fa-${floatingButtonSize}` : ''
	$: computedIconClass = `fas fa-${floatingButtonIconClass} ${computedIconSize}`

	$: formattedUrl = profilePictureUrl ? `${profilePictureUrl}?width=${pictureSize}&height=${pictureSize}` : 'images/placeholder-hiro.png'
</script>

{#if clickable}
	<div
		class="profile-picture"
		style="cursor: pointer;"
		on:click
		on:keypress
		role="button"
		aria-haspopup="true"
		tabindex="0"
	>
		<img
			class="rounded-circle mr-2"
			src={formattedUrl}
			style="width: {pictureSize}px; height: {pictureSize}px;"
			loading="lazy"
			alt="Your LawnHiro provider"
		/>
	</div>
{:else}
	<div class="profile-picture">
		<img
			class="rounded-circle mr-2"
			src={formattedUrl}
			style="width: {pictureSize}px; height: {pictureSize}px;"
			loading="lazy"
			alt="Your LawnHiro provider"
		/>
		{#if showFloatingButton}
			<div class={floatingButtonWrapperClass}>
				<button
					class={computedClass}
					type="button"
					on:click|stopPropagation={() => dispatch('floatingButtonClicked')}
				>
					<i
						class={computedIconClass}
						style={floatingButtonIconStyle}
					></i>
				</button>
			</div>
		{/if}
	</div>
{/if}

<style>
	.profile-picture {
		height: 100% !important;
		max-width: 100px !important;
		max-height: 100px !important;
		border-radius: 100%;
		position: relative;
	}
	.btn-floating-position {
		width: 42px;
		height: 32px;
		border-radius: 100%;
		position: absolute;
		bottom: 0;
		right: 0;
	}
	.btn-xs-floating-position {
		width: 28px;
		height: 25px;
		border-radius: 100%;
		position: absolute;
		bottom: 0;
		right: 0;
	}
	:global(.btn-floating) {
		width: 37px;
		height: 37px;
		border-radius: 50%;
	}
	:global(.btn-xs-floating) {
		width: 25px;
		height: 25px;
		border-radius: 50%;
	}
</style>
