<script lang="ts">
	import CollapsibleCard from '@isoftdata/svelte-collapsible-card'
	import GetQuoteCardHeader from './GetQuoteCardHeader.svelte'
	import SignUpForm from '../SignUpForm.svelte'
	import type { GetQuoteStore, GetQuoteGoogleAddress } from 'utility/get-quote-helper'
	import type { Readable, Writable } from 'svelte/store'

	export let quoteData: Writable<GetQuoteStore>
	export let derivedSelectedAddress: Readable<GetQuoteGoogleAddress | undefined>
	export let stepOpen: boolean
	export let disabled: boolean
	export let signupDisabledMessage: string

	$quoteData.userAccount.email = $quoteData.emailAddress

	let stepFinished = false
</script>

<CollapsibleCard
	entireHeaderToggles
	{disabled}
	cardHeaderClass="card-header d-flex justify-content-between h4"
	cardStyle="border-top-left-radius: 0px; border-top-right-radius: 0px;"
	bind:bodyShown={stepOpen}
	on:show
>
	<svelte:fragment slot="cardHeader">
		<GetQuoteCardHeader
			header="Create Account"
			{stepFinished}
		/>
	</svelte:fragment>
	<SignUpForm
		{signupDisabledMessage}
		showAccountTypeSelection={false}
		bind:userAccount={$quoteData.userAccount}
		requestedRoles={['CUSTOMER']}
		firstAddress={$derivedSelectedAddress && $quoteData.inServiceArea ? $derivedSelectedAddress : null}
		on:accountCreated={() => {
			stepFinished = true
		}}
	/>
</CollapsibleCard>
