export default {
    "name": "SignupDisabledMessage",
    "kind": "HoudiniQuery",
    "hash": "630b54be2e732ca96eac04e16d77fa563e03f0e43f08690091a37537db5794f0",

    "raw": `query SignupDisabledMessage {
  signupDisabledMessage
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "signupDisabledMessage": {
                "type": "String",
                "keyRaw": "signupDisabledMessage",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=556f97d95a92228b692c3156624fa9059ea31f6daa853df5fe642fddeb705048";