import { loadCustomerServiceSchedules } from 'utility/recurring-order-helper'
import component from './RecurringOrder.svelte'

import { AppContext } from 'types/common'

export default function ({ stateRouter }: AppContext) {
	stateRouter.addState({
		name: 'app.customer.recurring-order',
		route: 'recurring-order',
		querystringParameters: ['residenceId', 'scheduleType'],
		defaultParameters: {
			scheduleType: 'RECURRING_SCHEDULE',
		},
		template: {
			svelte: true,
			component,
			options: {},
		},
		async resolve(_data, parameters) {
			const customerRecurringSchedules = await loadCustomerServiceSchedules()

			const selectedResidenceId = parseInt(parameters.residenceId, 10) || customerRecurringSchedules[0]?.residence?.id || null

			const selectedResidence = customerRecurringSchedules.find(schedule => schedule.residence.id === selectedResidenceId) ?? null

			return {
				customerRecurringSchedules,
				selectedResidence,
			}
		},
	})
}
