export default {
    "name": "CreateTag",
    "kind": "HoudiniMutation",
    "hash": "1ea84da200ccd6b576086eb2806a49356863dc64b6f081c6fd57a277aa7949fe",

    "raw": `mutation CreateTag($tag: NewTag!, $serviceId: PositiveInt) {
  newTag(tag: $tag) {
    ...ServiceTagData_7Hvpf
    id
  }
}

fragment ServiceTagData_7Hvpf on Tag {
  id
  code
  name
  description
  prompt
  entityType
  tagPricingForService(serviceId: $serviceId) {
    ...TagPricingData
    id
  }
  __typename
}

fragment TagPricingData on TagPricing {
  id
  priceAdjustment
  pricingType
  serviceId
  tagId
  postPriceAdjustment
  __typename
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "newTag": {
                "type": "Tag",
                "keyRaw": "newTag(tag: $tag)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "code": {
                            "type": "String",
                            "keyRaw": "code",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "nullable": true,
                            "visible": true
                        },

                        "prompt": {
                            "type": "String",
                            "keyRaw": "prompt",
                            "nullable": true,
                            "visible": true
                        },

                        "entityType": {
                            "type": "EntityType",
                            "keyRaw": "entityType",
                            "visible": true
                        },

                        "tagPricingForService": {
                            "type": "TagPricing",
                            "keyRaw": "tagPricingForService(serviceId: $serviceId)",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "priceAdjustment": {
                                        "type": "String",
                                        "keyRaw": "priceAdjustment",
                                        "visible": true
                                    },

                                    "pricingType": {
                                        "type": "PricingType",
                                        "keyRaw": "pricingType",
                                        "visible": true
                                    },

                                    "serviceId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "serviceId",
                                        "visible": true
                                    },

                                    "tagId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "tagId",
                                        "visible": true
                                    },

                                    "postPriceAdjustment": {
                                        "type": "Boolean",
                                        "keyRaw": "postPriceAdjustment",
                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "TagPricingData": {
                                        "arguments": {}
                                    }
                                }
                            },

                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "ServiceTagData": {
                            "arguments": {
                                "serviceId": {
                                    "kind": "Variable",

                                    "name": {
                                        "kind": "Name",
                                        "value": "serviceId"
                                    }
                                }
                            }
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "tag": "NewTag",
            "serviceId": "PositiveInt"
        },

        "types": {
            "NewTag": {
                "code": "NonEmptyString",
                "description": "String",
                "entityType": "EntityType",
                "name": "NonEmptyString",
                "prompt": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=16e812e675e5958a7effbce6101defe954ec9dc2ddfb11ac8810a3131ff9b64d";