import { FragmentStore } from '../runtime/stores/fragment'
import artifact from '$houdini/artifacts/ServiceTagData'


// create the fragment store

export class ServiceTagDataStore extends FragmentStore {
	constructor() {
		super({
			artifact,
			storeName: "ServiceTagDataStore",
			variables: true,
			
		})
	}
}
