export default {
    "name": "ServiceSchedulePeriods",
    "kind": "HoudiniQuery",
    "hash": "52bb1d5f659a16a17f8d55658253d63d787f0635a7a6803b128b9b41631afee3",

    "raw": `query ServiceSchedulePeriods($filter: ServiceSchedulePeriodFilter, $serviceId: PositiveInt) {
  serviceSchedulePeriods(filter: $filter) {
    data {
      ...ServicePeriodData_7Hvpf
      id
    }
  }
}

fragment ServicePeriodData_7Hvpf on ServiceSchedulePeriod {
  id
  tagId
  serviceId
  status
  serviceSchedulePeriod
  tag {
    ...ServiceTagData_7Hvpf
    id
  }
  __typename
}

fragment ServiceTagData_7Hvpf on Tag {
  id
  code
  name
  description
  prompt
  entityType
  tagPricingForService(serviceId: $serviceId) {
    ...TagPricingData
    id
  }
  __typename
}

fragment TagPricingData on TagPricing {
  id
  priceAdjustment
  pricingType
  serviceId
  tagId
  postPriceAdjustment
  __typename
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "serviceSchedulePeriods": {
                "type": "ServiceSchedulePeriodResponse",
                "keyRaw": "serviceSchedulePeriods(filter: $filter)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "ServiceSchedulePeriod",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "tagId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "tagId",
                                        "visible": true
                                    },

                                    "serviceId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "serviceId",
                                        "visible": true
                                    },

                                    "status": {
                                        "type": "ServiceSchedulePeriodStatus",
                                        "keyRaw": "status",
                                        "visible": true
                                    },

                                    "serviceSchedulePeriod": {
                                        "type": "PositiveInt",
                                        "keyRaw": "serviceSchedulePeriod",
                                        "visible": true
                                    },

                                    "tag": {
                                        "type": "Tag",
                                        "keyRaw": "tag",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "code": {
                                                    "type": "String",
                                                    "keyRaw": "code",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "description": {
                                                    "type": "String",
                                                    "keyRaw": "description",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "prompt": {
                                                    "type": "String",
                                                    "keyRaw": "prompt",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "entityType": {
                                                    "type": "EntityType",
                                                    "keyRaw": "entityType",
                                                    "visible": true
                                                },

                                                "tagPricingForService": {
                                                    "type": "TagPricing",
                                                    "keyRaw": "tagPricingForService(serviceId: $serviceId)",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "priceAdjustment": {
                                                                "type": "String",
                                                                "keyRaw": "priceAdjustment",
                                                                "visible": true
                                                            },

                                                            "pricingType": {
                                                                "type": "PricingType",
                                                                "keyRaw": "pricingType",
                                                                "visible": true
                                                            },

                                                            "serviceId": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "serviceId",
                                                                "visible": true
                                                            },

                                                            "tagId": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "tagId",
                                                                "visible": true
                                                            },

                                                            "postPriceAdjustment": {
                                                                "type": "Boolean",
                                                                "keyRaw": "postPriceAdjustment",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "TagPricingData": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "__typename": {
                                                    "type": "String",
                                                    "keyRaw": "__typename",
                                                    "visible": true
                                                }
                                            },

                                            "fragments": {
                                                "ServiceTagData": {
                                                    "arguments": {
                                                        "serviceId": {
                                                            "kind": "Variable",

                                                            "name": {
                                                                "kind": "Name",
                                                                "value": "serviceId"
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "ServicePeriodData": {
                                        "arguments": {
                                            "serviceId": {
                                                "kind": "Variable",

                                                "name": {
                                                    "kind": "Name",
                                                    "value": "serviceId"
                                                }
                                            }
                                        }
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "ServiceSchedulePeriodFilter",
            "serviceId": "PositiveInt"
        },

        "types": {
            "ServiceSchedulePeriodFilter": {
                "schedulePeriod": "PositiveInt",
                "serviceId": "PositiveInt",
                "serviceSchedulePeriodId": "PositiveInt",
                "status": "ServiceSchedulePeriodStatus",
                "tagId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=ced24aedbd1f0b5e22c4fb72f276f7e77825890161f13763130b33d85850b0f4";