export default {
    "name": "DisableServiceSchedule",
    "kind": "HoudiniMutation",
    "hash": "acdfe224040393afdd5daac7ba9c8195620998e7eb272f4840635b22968785d6",

    "raw": `mutation DisableServiceSchedule($serviceScheduleId: PositiveInt!) {
  disableServiceSchedule(serviceScheduleId: $serviceScheduleId)
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "disableServiceSchedule": {
                "type": "Boolean",
                "keyRaw": "disableServiceSchedule(serviceScheduleId: $serviceScheduleId)",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "serviceScheduleId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=a654047ecbbce1403daf1c04e99023c5ae2c980b64987d622f003b26ef87da17";