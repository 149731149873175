<script lang="ts">
	import type { FormattedUpcomingOrderData } from 'utility/load-upcoming-orders'
	import CustomerOrderDetail from 'components/CustomerOrderDetail.svelte'
	import CustomerOrderListItem from 'components/CustomerOrderListItem.svelte'
	import { customerCancelJobMutation } from 'utility/upcoming-orders-helper'
	import formatRelativeDate from 'utility/format/format-relative-date'
	import type { SvelteAsr } from 'types/common'
	import Throbber from 'components/Throbber.svelte'
	import { upsert } from '@isoftdata/utility-array'
	import Modal from '@isoftdata/svelte-modal'
	import TextArea from '@isoftdata/svelte-textarea'

	export let asr: SvelteAsr

	export let jobs: FormattedUpcomingOrderData[] = []
	export let selectedJobId: number | null = null
	export let selectedJob: FormattedUpcomingOrderData | null = null

	let listItem: CustomerOrderListItem

	let showCancelJobModal = false
	let saving = false
	let cancellationReason = ''
	let modalError = ''

	$: customerHasJobsFromMultipleCities = new Set(jobs.map(job => `${job.residence.city}${job.residence.state}`)).size > 1

	let loadingJob = false

	function openCancelJobModal() {
		showCancelJobModal = true
	}

	async function cancelOrder() {
		if (!selectedJobId) return
		let cancelledOrder: FormattedUpcomingOrderData | null = null
		try {
			const { data } = await customerCancelJobMutation.mutate({
				cancelJobInput: {
					jobId: selectedJobId,
					reason: cancellationReason,
				},
			})

			if (!data?.cancelJob) {
				throw new Error('Failed to cancel job')
			}

			const formattedRes = {
				...data.cancelJob,
				files:
					data.cancelJob.files?.map(metadata => {
						return {
							...metadata,
							size: 0,
							mimeType: metadata.file?.mimeType ?? '',
							name: metadata.file?.name ?? '',
						}
					}) ?? [],
			}
			cancelledOrder = formattedRes
			const jobsIndex = jobs.findIndex(job => job.id === selectedJobId)
			if (jobsIndex !== -1) {
				jobs = upsert(jobs, 'id', cancelledOrder)
			}
		} catch (err: unknown) {
			const error = err as Error & { extensions?: { code?: string } }
			let message = error.message
			switch (error?.extensions?.code) {
				case 'MODIFY_JOB_ERROR_OUTSIDE_CANCELABLE_HOURS':
					message = 'The job has been claimed by a Lawnhiro provider and is no longer cancelable. Please contact Lawnhiro support to cancel the job.'
					break
				default:
					message = 'There was an error cancelling your order. Please try again. IF the problem persists, please contact Lawnhiro support.'
					break
			}
			modalError = message
			console.error(error.message)
		}
		if (!cancelledOrder) return
		listItem.reselectJob(cancelledOrder.id)
		showCancelJobModal = false
		cancellationReason = ''
	}
</script>

<div class="row">
	<div class="col-lg-4 col-xl-3">
		<div class="d-flex justify-content-between">
			<h4>Upcoming Orders</h4>
		</div>
		<div
			class="list-group"
			style="overflow-y: auto; height: 50vh;"
		>
			{#if jobs.length === 0}
				<div class="list-group-item list-group-item-action">
					<div class="d-flex flex-column">
						<h6>You don't have any upcoming orders</h6>
					</div>
				</div>
			{:else}
				{#each jobs as job}
					<CustomerOrderListItem
						{job}
						showCityStateZip={customerHasJobsFromMultipleCities}
						bind:selectedJobId
						bind:selectedJob
						bind:loadingJob
						bind:this={listItem}
					/>
				{/each}
			{/if}
		</div>
	</div>
	<div class="col-lg-8 col-xl-9">
		<h4 class="mt-3 mt-lg-0">Order Details</h4>
		{#if loadingJob}
			<div class="text-center mt-2">
				<Throbber />
			</div>
		{:else if selectedJob}
			<div id="orderDetail">
				<CustomerOrderDetail
					job={selectedJob}
					showFooter={false}
					on:cancelJob={openCancelJobModal}
				/>
			</div>
		{:else if selectedJobId === null && jobs.length === 0}
			<div class="alert alert-info">
				<span>No orders found.</span>
				<a href={asr.makePath('app.new-order')}>Click Here to start a new Order</a>
			</div>
		{:else}
			<div class="alert alert-info">Select an order to view details</div>
		{/if}
	</div>
</div>

<Modal
	bind:show={showCancelJobModal}
	title="Cancel Job for {formatRelativeDate(selectedJob?.requestedSchedule, new Date()).formattedDate}"
	confirmButtonText="Confirm"
	cancelButtonText="Cancel"
	confirmButtonIcon={saving ? 'spinner' : undefined}
	on:cancel={() => (showCancelJobModal = false)}
	on:close={() => (showCancelJobModal = false)}
	on:confirm={() => cancelOrder()}
>
	<TextArea
		id="jobCancellationReason"
		label="Cancellation Reason"
		labelClass="py-0 mb-2"
		textareaStyle="min-height: 100px;"
		placeholder="What is your reason for cancelling this job?"
		bind:value={cancellationReason}
	/>
	{#if modalError !== ''}
		<div class="alert alert-danger mt-2">{modalError}</div>
	{/if}
</Modal>
