export default {
    "name": "GetCustomerResidenceCompletedScheduleJob",
    "kind": "HoudiniQuery",
    "hash": "3e448c356ede1ea211d8c8100ecced2dfcc5f59a56eeafd3dcbc2736b759a8c2",

    "raw": `query GetCustomerResidenceCompletedScheduleJob($residenceId: PositiveInt!) {
  getCustomerResidenceCompletedScheduleJob(residenceId: $residenceId) {
    completed
    jobStatus
    serviceScheduleId
    id
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "getCustomerResidenceCompletedScheduleJob": {
                "type": "Job",
                "keyRaw": "getCustomerResidenceCompletedScheduleJob(residenceId: $residenceId)",

                "selection": {
                    "fields": {
                        "completed": {
                            "type": "DateTime",
                            "keyRaw": "completed",
                            "nullable": true,
                            "visible": true
                        },

                        "jobStatus": {
                            "type": "JobStatus",
                            "keyRaw": "jobStatus",
                            "visible": true
                        },

                        "serviceScheduleId": {
                            "type": "PositiveInt",
                            "keyRaw": "serviceScheduleId",
                            "nullable": true,
                            "visible": true
                        },

                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "residenceId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=7a909d1c4518ed442310449f86e6bbc34fecbd7b232f77b1c9efff485e2116ac";