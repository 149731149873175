import { AppContext, ResolveParameters, DefaultParameters } from 'types/common'
import component from './SignUp.svelte'
import { graphql } from '$houdini'
import getSignupDisabledMessage from 'utility/get-signup-disabled-message'

type SignUpParameters = {
	type: 'CUSTOMER' | 'PROVIDER'
}

export default ({ stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'sign-up',
		route: 'sign-up',
		querystringParameters: ['type'],
		defaultParameters: {
			type: 'CUSTOMER',
		} satisfies DefaultParameters<SignUpParameters>,
		template: {
			svelte: true,
			component,
			options: {},
		},
		async resolve(_data, parameters: ResolveParameters<SignUpParameters>) {
			return {
				signupDisabledMessage: await getSignupDisabledMessage(),
				requestedRoles: [parameters.type.toUpperCase()], //Default the checkbox of the account type to whatever the URL arg said
			}
		},
	})
}
