export default {
    "name": "CreateAnswer",
    "kind": "HoudiniMutation",
    "hash": "53b0c7179ca554e27b85c8fcaaac2241e00bba1ef8350018150a0071fafcd669",

    "raw": `mutation CreateAnswer($answer: NewAnswer!, $serviceId: PositiveInt) {
  newAnswer(answer: $answer) {
    ...ServiceAnswerData_7Hvpf
    id
  }
}

fragment ServiceAnswerData_7Hvpf on Answer {
  id
  answer
  tag {
    ...ServiceTagData_7Hvpf
    id
  }
  __typename
}

fragment ServiceTagData_7Hvpf on Tag {
  id
  code
  name
  description
  prompt
  entityType
  tagPricingForService(serviceId: $serviceId) {
    ...TagPricingData
    id
  }
  __typename
}

fragment TagPricingData on TagPricing {
  id
  priceAdjustment
  pricingType
  serviceId
  tagId
  postPriceAdjustment
  __typename
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "newAnswer": {
                "type": "Answer",
                "keyRaw": "newAnswer(answer: $answer)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "answer": {
                            "type": "String",
                            "keyRaw": "answer",
                            "visible": true
                        },

                        "tag": {
                            "type": "Tag",
                            "keyRaw": "tag",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "prompt": {
                                        "type": "String",
                                        "keyRaw": "prompt",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "entityType": {
                                        "type": "EntityType",
                                        "keyRaw": "entityType",
                                        "visible": true
                                    },

                                    "tagPricingForService": {
                                        "type": "TagPricing",
                                        "keyRaw": "tagPricingForService(serviceId: $serviceId)",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "priceAdjustment": {
                                                    "type": "String",
                                                    "keyRaw": "priceAdjustment",
                                                    "visible": true
                                                },

                                                "pricingType": {
                                                    "type": "PricingType",
                                                    "keyRaw": "pricingType",
                                                    "visible": true
                                                },

                                                "serviceId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "serviceId",
                                                    "visible": true
                                                },

                                                "tagId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "tagId",
                                                    "visible": true
                                                },

                                                "postPriceAdjustment": {
                                                    "type": "Boolean",
                                                    "keyRaw": "postPriceAdjustment",
                                                    "visible": true
                                                },

                                                "__typename": {
                                                    "type": "String",
                                                    "keyRaw": "__typename",
                                                    "visible": true
                                                }
                                            },

                                            "fragments": {
                                                "TagPricingData": {
                                                    "arguments": {}
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "ServiceTagData": {
                                        "arguments": {
                                            "serviceId": {
                                                "kind": "Variable",

                                                "name": {
                                                    "kind": "Name",
                                                    "value": "serviceId"
                                                }
                                            }
                                        }
                                    }
                                }
                            },

                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "ServiceAnswerData": {
                            "arguments": {
                                "serviceId": {
                                    "kind": "Variable",

                                    "name": {
                                        "kind": "Name",
                                        "value": "serviceId"
                                    }
                                }
                            }
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "answer": "NewAnswer",
            "serviceId": "PositiveInt"
        },

        "types": {
            "NewAnswer": {
                "answer": "NonEmptyString",
                "questionId": "PositiveInt",
                "tagId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=c5c9f05ae5d6129016a0bc1b9511c923ed43321279e6bc535de5cc8ef0208a1a";