export default {
    "name": "AdminUserAccounts",
    "kind": "HoudiniQuery",
    "hash": "c75c0c971465f5df6cd85a50d8a9da298a90f3f37314d07cbefc55ddd774feca",

    "raw": `query AdminUserAccounts($filter: UserFilter, $pagination: PaginatedInput) {
  userAccounts(filter: $filter, pagination: $pagination) {
    data {
      id
      mobile
      created
      email
      firstName
      lastName
      fullName
      status
      customer {
        id
      }
      provider {
        id
        status
        profilePictureFile {
          id
          path
        }
      }
      userRoles {
        permissionName
        value
      }
    }
    info {
      pageNumber
      pageSize
      totalItems
      totalPages
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "userAccounts": {
                "type": "UserAccountResponse",
                "keyRaw": "userAccounts(filter: $filter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "UserAccount",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "mobile": {
                                        "type": "PhoneNumber",
                                        "keyRaw": "mobile",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "created": {
                                        "type": "DateTime",
                                        "keyRaw": "created",
                                        "visible": true
                                    },

                                    "email": {
                                        "type": "EmailAddress",
                                        "keyRaw": "email",
                                        "visible": true
                                    },

                                    "firstName": {
                                        "type": "String",
                                        "keyRaw": "firstName",
                                        "visible": true
                                    },

                                    "lastName": {
                                        "type": "String",
                                        "keyRaw": "lastName",
                                        "visible": true
                                    },

                                    "fullName": {
                                        "type": "String",
                                        "keyRaw": "fullName",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "status": {
                                        "type": "UserAccountStatus",
                                        "keyRaw": "status",
                                        "visible": true
                                    },

                                    "customer": {
                                        "type": "Customer",
                                        "keyRaw": "customer",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "provider": {
                                        "type": "Provider",
                                        "keyRaw": "provider",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "status": {
                                                    "type": "ProviderStatus",
                                                    "keyRaw": "status",
                                                    "visible": true
                                                },

                                                "profilePictureFile": {
                                                    "type": "FileEntity",
                                                    "keyRaw": "profilePictureFile",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "path": {
                                                                "type": "String",
                                                                "keyRaw": "path",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "userRoles": {
                                        "type": "UserRole",
                                        "keyRaw": "userRoles",

                                        "selection": {
                                            "fields": {
                                                "permissionName": {
                                                    "type": "NonEmptyString",
                                                    "keyRaw": "permissionName",
                                                    "visible": true
                                                },

                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "info": {
                            "type": "PageInfo",
                            "keyRaw": "info",

                            "selection": {
                                "fields": {
                                    "pageNumber": {
                                        "type": "Int",
                                        "keyRaw": "pageNumber",
                                        "visible": true
                                    },

                                    "pageSize": {
                                        "type": "Int",
                                        "keyRaw": "pageSize",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "totalItems": {
                                        "type": "Int",
                                        "keyRaw": "totalItems",
                                        "visible": true
                                    },

                                    "totalPages": {
                                        "type": "Int",
                                        "keyRaw": "totalPages",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "UserFilter",
            "pagination": "PaginatedInput"
        },

        "types": {
            "UserFilter": {
                "activeOnly": "Boolean",
                "roles": "UserAccessType"
            },

            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=d142f51f6d99a84d3c498e112124c5f77ca4724f809bdea65bbd77fb7c315ab1";