export default {
    "name": "UpcomingOrders",
    "kind": "HoudiniQuery",
    "hash": "d9a4711a6565caec5e8dd7e6b5457e84e2ff1499a17e35d4a3d906b9baabfb48",

    "raw": `query UpcomingOrders($orderBy: [JobOrderBy!], $pagination: PaginatedInput) {
  upcomingOrders(orderBy: $orderBy, pagination: $pagination) {
    data {
      id
      residence {
        street
        city
        state
        zip
        country
        id
      }
      jobStatus
      requestedSchedule
      service {
        name
        id
      }
      jobClaims {
        scheduledAt
        jobClaimStatus
        id
      }
      answers {
        id
        answer
      }
      files {
        id
        jobId
        userAccountId
        fileId
        file {
          id
          name
          created
          updated
          hash
          path
          mimeType
          type
        }
        public
        rank
        imageFileType
      }
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "upcomingOrders": {
                "type": "JobResponse",
                "keyRaw": "upcomingOrders(orderBy: $orderBy, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Job",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "residence": {
                                        "type": "Residence",
                                        "keyRaw": "residence",

                                        "selection": {
                                            "fields": {
                                                "street": {
                                                    "type": "String",
                                                    "keyRaw": "street",
                                                    "visible": true
                                                },

                                                "city": {
                                                    "type": "String",
                                                    "keyRaw": "city",
                                                    "visible": true
                                                },

                                                "state": {
                                                    "type": "String",
                                                    "keyRaw": "state",
                                                    "visible": true
                                                },

                                                "zip": {
                                                    "type": "String",
                                                    "keyRaw": "zip",
                                                    "visible": true
                                                },

                                                "country": {
                                                    "type": "String",
                                                    "keyRaw": "country",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "jobStatus": {
                                        "type": "JobStatus",
                                        "keyRaw": "jobStatus",
                                        "visible": true
                                    },

                                    "requestedSchedule": {
                                        "type": "Date",
                                        "keyRaw": "requestedSchedule",
                                        "visible": true
                                    },

                                    "service": {
                                        "type": "Service",
                                        "keyRaw": "service",

                                        "selection": {
                                            "fields": {
                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "jobClaims": {
                                        "type": "JobClaim",
                                        "keyRaw": "jobClaims",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "scheduledAt": {
                                                    "type": "DateTime",
                                                    "keyRaw": "scheduledAt",
                                                    "visible": true
                                                },

                                                "jobClaimStatus": {
                                                    "type": "JobClaimStatus",
                                                    "keyRaw": "jobClaimStatus",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "answers": {
                                        "type": "Answer",
                                        "keyRaw": "answers",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "answer": {
                                                    "type": "String",
                                                    "keyRaw": "answer",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "files": {
                                        "type": "JobFile",
                                        "keyRaw": "files",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "jobId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "jobId",
                                                    "visible": true
                                                },

                                                "userAccountId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "userAccountId",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "fileId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "fileId",
                                                    "visible": true
                                                },

                                                "file": {
                                                    "type": "FileEntity",
                                                    "keyRaw": "file",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            },

                                                            "created": {
                                                                "type": "DateTime",
                                                                "keyRaw": "created",
                                                                "visible": true
                                                            },

                                                            "updated": {
                                                                "type": "DateTime",
                                                                "keyRaw": "updated",
                                                                "visible": true
                                                            },

                                                            "hash": {
                                                                "type": "String",
                                                                "keyRaw": "hash",
                                                                "visible": true
                                                            },

                                                            "path": {
                                                                "type": "String",
                                                                "keyRaw": "path",
                                                                "visible": true
                                                            },

                                                            "mimeType": {
                                                                "type": "String",
                                                                "keyRaw": "mimeType",
                                                                "visible": true
                                                            },

                                                            "type": {
                                                                "type": "FileType",
                                                                "keyRaw": "type",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "public": {
                                                    "type": "Boolean",
                                                    "keyRaw": "public",
                                                    "visible": true
                                                },

                                                "rank": {
                                                    "type": "NonNegativeInt",
                                                    "keyRaw": "rank",
                                                    "visible": true
                                                },

                                                "imageFileType": {
                                                    "type": "ImageFileType",
                                                    "keyRaw": "imageFileType",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "orderBy": "JobOrderBy",
            "pagination": "PaginatedInput"
        },

        "types": {
            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=331c4b4c7a132dbe20a2cf26d1e4fcfb85d67313c7e93dcfd1887825b7cce370";