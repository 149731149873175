<script lang="ts">
	import Table, { Td, type Column } from '@isoftdata/svelte-table'
	import Button from '@isoftdata/svelte-button'
	import formatCurrency from 'utility/format/format-currency'
	import { toDisplay } from 'utility/format/format-percent'
	import { updateServiceSchedulePeriodStatusMutation, type ServiceSchedulePeriod } from 'utility/service-helper'
	import type { WritableDeep } from 'type-fest'

	export let serviceSchedulePeriods: Array<WritableDeep<ServiceSchedulePeriod>> = []
	let rowLoading: number | null = null

	const columns: Column<ServiceSchedulePeriod>[] = [
		{ property: 'serviceSchedulePeriod', name: 'Period' },
		{ property: 'tag[name]', name: 'Tag Name' },
		{ property: 'tag[tagPricingForService][priceAdjustment]', name: 'Price Adjustment' },
		{ property: 'status', name: 'Status' },
		{ property: 'remove', name: '', sortType: false },
	]

	async function updateServiceSchedulePeriodStatus(id: number) {
		try {
			rowLoading = id
			const { data } = await updateServiceSchedulePeriodStatusMutation.mutate({
				serviceSchedulePeriodId: id,
			})

			if (!data) {
				throw new Error('Failed to update service schedule period status')
			}

			const schedulePeriodIndex = serviceSchedulePeriods.findIndex(period => period.id === data.toggleServiceSchedulePeriod.id)
			serviceSchedulePeriods[schedulePeriodIndex].status = data.toggleServiceSchedulePeriod.status
			rowLoading = null
		} catch (err: unknown) {
			const error = err as Error
			console.error(error)
		}
	}
</script>

<Table
	responsive
	class="mb-0"
	filterEnabled={false}
	rows={serviceSchedulePeriods}
	{columns}
	let:row
>
	<svelte:fragment slot="no-rows">
		<tr>
			<td
				colspan={columns.length}
				class="text-center">No service schedule periods found</td
			>
		</tr>
	</svelte:fragment>
	<tr>
		<Td property="serviceSchedulePeriod">{row.serviceSchedulePeriod ? row.serviceSchedulePeriod : 'NA'} days</Td>
		<Td property="tag[name]">{row.tag?.name ?? '---'}</Td>
		<Td property="tag[tagPricingForService][priceAdjustment]">
			{@const formattedCurrency =
				row.tag?.tagPricingForService?.pricingType === 'PERCENT' ? `${toDisplay(row.tag?.tagPricingForService?.priceAdjustment)}%` : formatCurrency(row.tag?.tagPricingForService?.priceAdjustment)}
			{#if row.tag?.tagPricingForService?.priceAdjustment[0] === '-'}
				<span class="text-success">{formattedCurrency ? formattedCurrency.slice(1) : 'NA'} Discount</span>
			{:else}
				<span class="text-danger">{formattedCurrency ? formattedCurrency : 'NA'} Upcharge</span>
			{/if}
		</Td>
		<Td
			property="status"
			class={row.status === 'ACTIVE' ? 'text-success' : 'text-danger'}
		>
			{#if rowLoading === row.id}
				Loading...
			{:else if row.status === 'ACTIVE'}
				Active
			{:else}
				Inactive
			{/if}
		</Td>
		<Td
			property="remove"
			style="width:50px;"
		>
			<Button
				size="sm"
				color={row.status === 'ACTIVE' ? 'danger' : 'success'}
				iconClass={row.status === 'ACTIVE' ? 'xmark' : 'check'}
				on:click={() => updateServiceSchedulePeriodStatus(row.id)}
			/>
		</Td>
	</tr>
</Table>
