export default {
    "name": "TagPricingData",
    "kind": "HoudiniFragment",
    "hash": "3d81c0c1a1a541b1403e6bee7ea580c636490b8e18a445a6f2453c47bb40f982",

    "raw": `fragment TagPricingData on TagPricing {
  id
  priceAdjustment
  pricingType
  serviceId
  tagId
  postPriceAdjustment
  __typename
}
`,

    "rootType": "TagPricing",
    "stripVariables": [],

    "selection": {
        "fields": {
            "id": {
                "type": "PositiveInt",
                "keyRaw": "id",
                "visible": true
            },

            "priceAdjustment": {
                "type": "String",
                "keyRaw": "priceAdjustment",
                "visible": true
            },

            "pricingType": {
                "type": "PricingType",
                "keyRaw": "pricingType",
                "visible": true
            },

            "serviceId": {
                "type": "PositiveInt",
                "keyRaw": "serviceId",
                "visible": true
            },

            "tagId": {
                "type": "PositiveInt",
                "keyRaw": "tagId",
                "visible": true
            },

            "postPriceAdjustment": {
                "type": "Boolean",
                "keyRaw": "postPriceAdjustment",
                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=adf72c54d54ee5d5e4672d8c0c19d1121e31f18dda2864e9da110e501a6c1100";