import { getUpcomingOrders } from 'utility/load-upcoming-orders'
import component from './UpcomingOrders.svelte'

import { AppContext } from 'types/common'

export default ({ stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'app.customer.upcoming-orders',
		route: 'upcoming-orders',
		template: {
			svelte: true,
			component,
			options: {},
		},
		async resolve(_data, _parameters) {
			const upcomingOrders = await getUpcomingOrders()

			const selectedJobId = upcomingOrders[0]?.id ?? undefined

			const selectedJob = upcomingOrders.find(job => job.id === selectedJobId) ?? null

			return {
				jobs: upcomingOrders,
				selectedJobId,
				selectedJob,
			}
		},
	})
}
