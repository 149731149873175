export default {
    "name": "UpdateTagPricing",
    "kind": "HoudiniMutation",
    "hash": "9ce51e89cdb8ad44ec704358c0d87f68a6e6604d1eecdb1750781646d33f75d2",

    "raw": `mutation UpdateTagPricing($tagPricing: EditTagPricing!) {
  editTagPricing(tagPricing: $tagPricing) {
    ...TagPricingData
    id
  }
}

fragment TagPricingData on TagPricing {
  id
  priceAdjustment
  pricingType
  serviceId
  tagId
  postPriceAdjustment
  __typename
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "editTagPricing": {
                "type": "TagPricing",
                "keyRaw": "editTagPricing(tagPricing: $tagPricing)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "priceAdjustment": {
                            "type": "String",
                            "keyRaw": "priceAdjustment",
                            "visible": true
                        },

                        "pricingType": {
                            "type": "PricingType",
                            "keyRaw": "pricingType",
                            "visible": true
                        },

                        "serviceId": {
                            "type": "PositiveInt",
                            "keyRaw": "serviceId",
                            "visible": true
                        },

                        "tagId": {
                            "type": "PositiveInt",
                            "keyRaw": "tagId",
                            "visible": true
                        },

                        "postPriceAdjustment": {
                            "type": "Boolean",
                            "keyRaw": "postPriceAdjustment",
                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "TagPricingData": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "tagPricing": "EditTagPricing"
        },

        "types": {
            "EditTagPricing": {
                "postPriceAdjustment": "Boolean",
                "priceAdjustment": "String",
                "pricingType": "PricingType",
                "tagPricingId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=b62401a8ba112120fea3975100227ed674138673e2a43c83562034ab98a4f9c7";