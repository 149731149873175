export default {
    "name": "CustomerCancelJobMutation",
    "kind": "HoudiniMutation",
    "hash": "3584ea2b624a686caeaf2aec77011689ad01b8474878cfb9e7e3f54c013f20d8",

    "raw": `mutation CustomerCancelJobMutation($cancelJobInput: CancelJobInput!) {
  cancelJob(cancelJobInput: $cancelJobInput) {
    id
    residence {
      street
      city
      state
      zip
      country
      id
    }
    jobStatus
    requestedSchedule
    service {
      name
      id
    }
    jobClaims {
      scheduledAt
      jobClaimStatus
      id
    }
    answers {
      id
      answer
    }
    files {
      id
      jobId
      userAccountId
      fileId
      file {
        id
        name
        created
        updated
        hash
        path
        mimeType
        type
      }
      public
      rank
      imageFileType
    }
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "cancelJob": {
                "type": "Job",
                "keyRaw": "cancelJob(cancelJobInput: $cancelJobInput)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "residence": {
                            "type": "Residence",
                            "keyRaw": "residence",

                            "selection": {
                                "fields": {
                                    "street": {
                                        "type": "String",
                                        "keyRaw": "street",
                                        "visible": true
                                    },

                                    "city": {
                                        "type": "String",
                                        "keyRaw": "city",
                                        "visible": true
                                    },

                                    "state": {
                                        "type": "String",
                                        "keyRaw": "state",
                                        "visible": true
                                    },

                                    "zip": {
                                        "type": "String",
                                        "keyRaw": "zip",
                                        "visible": true
                                    },

                                    "country": {
                                        "type": "String",
                                        "keyRaw": "country",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "jobStatus": {
                            "type": "JobStatus",
                            "keyRaw": "jobStatus",
                            "visible": true
                        },

                        "requestedSchedule": {
                            "type": "Date",
                            "keyRaw": "requestedSchedule",
                            "visible": true
                        },

                        "service": {
                            "type": "Service",
                            "keyRaw": "service",

                            "selection": {
                                "fields": {
                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "jobClaims": {
                            "type": "JobClaim",
                            "keyRaw": "jobClaims",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "scheduledAt": {
                                        "type": "DateTime",
                                        "keyRaw": "scheduledAt",
                                        "visible": true
                                    },

                                    "jobClaimStatus": {
                                        "type": "JobClaimStatus",
                                        "keyRaw": "jobClaimStatus",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "answers": {
                            "type": "Answer",
                            "keyRaw": "answers",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "answer": {
                                        "type": "String",
                                        "keyRaw": "answer",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "files": {
                            "type": "JobFile",
                            "keyRaw": "files",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "jobId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "jobId",
                                        "visible": true
                                    },

                                    "userAccountId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "userAccountId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "fileId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "fileId",
                                        "visible": true
                                    },

                                    "file": {
                                        "type": "FileEntity",
                                        "keyRaw": "file",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "created": {
                                                    "type": "DateTime",
                                                    "keyRaw": "created",
                                                    "visible": true
                                                },

                                                "updated": {
                                                    "type": "DateTime",
                                                    "keyRaw": "updated",
                                                    "visible": true
                                                },

                                                "hash": {
                                                    "type": "String",
                                                    "keyRaw": "hash",
                                                    "visible": true
                                                },

                                                "path": {
                                                    "type": "String",
                                                    "keyRaw": "path",
                                                    "visible": true
                                                },

                                                "mimeType": {
                                                    "type": "String",
                                                    "keyRaw": "mimeType",
                                                    "visible": true
                                                },

                                                "type": {
                                                    "type": "FileType",
                                                    "keyRaw": "type",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "public": {
                                        "type": "Boolean",
                                        "keyRaw": "public",
                                        "visible": true
                                    },

                                    "rank": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "rank",
                                        "visible": true
                                    },

                                    "imageFileType": {
                                        "type": "ImageFileType",
                                        "keyRaw": "imageFileType",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "cancelJobInput": "CancelJobInput"
        },

        "types": {
            "CancelJobInput": {
                "jobId": "PositiveInt",
                "reason": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=a82b05a5b5c4d9f37e4b4a114b251893ce8f95e4859ba29e486ce9b45f1f2018";