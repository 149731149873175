import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/GetCustomerServiceSchedulesQuery'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class GetCustomerServiceSchedulesQueryStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "GetCustomerServiceSchedulesQueryStore",
			variables: false,
		})
	}
}

export async function load_GetCustomerServiceSchedulesQuery(params) {
	await initClient()

	const store = new GetCustomerServiceSchedulesQueryStore()

	await store.fetch(params)

	return {
		GetCustomerServiceSchedulesQuery: store,
	}
}
