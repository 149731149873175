export default {
    "name": "CreateTagPricing",
    "kind": "HoudiniMutation",
    "hash": "72e2ec30d571c15aea45b36608ec83852b96939319f3bb1851189e3796486243",

    "raw": `mutation CreateTagPricing($tagPricing: NewTagPricing!) {
  newTagPricing(tagPricing: $tagPricing) {
    ...TagPricingData
    id
  }
}

fragment TagPricingData on TagPricing {
  id
  priceAdjustment
  pricingType
  serviceId
  tagId
  postPriceAdjustment
  __typename
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "newTagPricing": {
                "type": "TagPricing",
                "keyRaw": "newTagPricing(tagPricing: $tagPricing)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "priceAdjustment": {
                            "type": "String",
                            "keyRaw": "priceAdjustment",
                            "visible": true
                        },

                        "pricingType": {
                            "type": "PricingType",
                            "keyRaw": "pricingType",
                            "visible": true
                        },

                        "serviceId": {
                            "type": "PositiveInt",
                            "keyRaw": "serviceId",
                            "visible": true
                        },

                        "tagId": {
                            "type": "PositiveInt",
                            "keyRaw": "tagId",
                            "visible": true
                        },

                        "postPriceAdjustment": {
                            "type": "Boolean",
                            "keyRaw": "postPriceAdjustment",
                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "TagPricingData": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "tagPricing": "NewTagPricing"
        },

        "types": {
            "NewTagPricing": {
                "postPriceAdjustment": "Boolean",
                "priceAdjustment": "String",
                "pricingType": "PricingType",
                "serviceId": "PositiveInt",
                "tagId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=26fb8f12d488ea5d927c80be9c913470b1597f10d457cdd0028de51c6df1f7d8";