<script lang="ts">
	import Button from '@isoftdata/svelte-button'
	import Table, { Td, type Column } from '@isoftdata/svelte-table'
	import Select from '@isoftdata/svelte-select'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import Modal from '@isoftdata/svelte-modal'
	import Input from '@isoftdata/svelte-input'
	import TextArea from '@isoftdata/svelte-textarea'
	import CurrencyInput from '@isoftdata/svelte-currency-input'
	import CollapsibleCard from '@isoftdata/svelte-collapsible-card'
	import ServiceQuestionList from './ServiceQuestionList.svelte'
	import ServiceSchedulePeriodTable from './ServiceSchedulePeriodTable.svelte'
	import { tagTypeList } from 'utility/tag-type-list'
	import { tagPricingTypeList } from 'utility/tag-pricing-types'
	import {
		loadQuestions,
		loadServices,
		loadServiceSchedulePeriods,
		loadTags,
		newServiceMutation,
		newServiceSchedulePeriodMutation,
		newTagMutation,
		newTagPricingMutation,
		updateServiceMutation,
		type AdminService,
		type AdminServiceQuestion,
		type ServiceSchedulePeriod,
	} from 'utility/service-helper'

	import { klona } from 'klona'
	import formatImageFileUrl from 'utility/format/format-image-file-url'
	import { toDisplay, toSave } from 'utility/format/format-percent'
	import formatCurrency from 'utility/format/format-currency'
	import { readAsDataURL } from 'promise-file-reader'
	import { upsert } from '@isoftdata/utility-array'
	import Throbber from 'components/Throbber.svelte'
	import { FileInput } from '@isoftdata/svelte-file-input'

	type ServiceModal = {
		show: boolean
		maximumDaysToPerform: number | null
		status: string | null
		serviceId: number | null
		name: string
		codeName: string
		description: string
		warning: string
		requirement: string
		allowSubscription: boolean
		allowNewApplication: boolean
		errorMessage: string
		serviceImageFile: File | null
		serviceImageURL: string | null
		isEditing: boolean
	}

	type ServicePeriodModal = {
		show: boolean
		period: number | null
		availableTags: ServiceSchedulePeriod['tag'][]
		selectedTagId: number | null
		tagCode: string | null
		tagName: string | null
		tagDescription: string
		tagPrompt: string
		postPriceAdjustment: boolean
		tagEntityType: Exclude<ServiceSchedulePeriod['tag'], null>['entityType']
		pricingType: Exclude<Exclude<ServiceSchedulePeriod['tag'], null>['tagPricingForService'], null>['pricingType']
		priceAdjustment: string
		errorMessage: string
	}

	const defaultServiceModalState: ServiceModal = Object.freeze({
		serviceId: null,
		name: '',
		codeName: '',
		description: '',
		warning: '',
		requirement: '',
		status: null,
		maximumDaysToPerform: null,
		allowSubscription: false,
		allowNewApplication: false,
		show: false,
		errorMessage: '',
		serviceImageFile: null,
		serviceImageURL: null,
		isEditing: false,
	})

	const defaultServicePeriodModalState: ServicePeriodModal = Object.freeze({
		period: null,
		availableTags: [],
		selectedTagId: null,
		tagCode: null,
		tagName: null,
		tagDescription: '',
		tagPrompt: '',
		postPriceAdjustment: true,
		tagEntityType: 'SCHEDULE',
		pricingType: 'PERCENT',
		priceAdjustment: '',
		show: false,
		errorMessage: '',
	})

	const serviceStatusList = [
		{ value: 'Active', label: 'Active' },
		{ value: 'Inactive', label: 'Inactive' },
	]

	export let services: Array<AdminService & { edit?: unknown }> = []

	let selectedServiceServiceQuestions: AdminServiceQuestion[] | undefined = undefined
	let selectedServiceSchedulePeriods: ServiceSchedulePeriod[] | undefined = undefined
	let selectedQuestionId: number | null = null
	let selectedQuestion: AdminServiceQuestion | null = null
	let preChangeName: string = ''

	let serviceStatusFilter: string | null = null
	let selectedServiceId: number | null = null
	let loadingServices = false
	let loadingSubtable = false

	let serviceModal: ServiceModal = klona(defaultServiceModalState)
	let addServicePeriodModal: ServicePeriodModal = klona(defaultServicePeriodModalState)

	const columns: Column<AdminService & { edit?: unknown }>[] = [
		{ property: 'name', name: 'Name' },
		{ property: 'description', name: 'Description' },
		{ property: 'maximumDaysToPerform', name: 'Service Window (Days)' },
		{ property: 'active', name: 'Status' },
		{ property: 'edit', name: '', sortType: false },
	]

	$: isMakingNewTag = addServicePeriodModal.selectedTagId === null

	function openAddServiceModal() {
		serviceModal = {
			...klona(defaultServiceModalState),
			status: 'Active',
			show: true,
			maximumDaysToPerform: 1,
		}
	}

	function formatCodeName(codeName: string): string {
		return codeName
			.trim()
			.split(' ')
			.map(word => word.trim())
			.join('_')
			.toUpperCase()
	}

	function openEditServiceModal(service: AdminService) {
		serviceModal = {
			...klona(defaultServiceModalState),
			serviceId: service.id,
			name: service.name,
			codeName: service.codeName,
			description: service.description ?? '',
			warning: service.warning ?? '',
			status: service.active ? 'Active' : 'Inactive',
			maximumDaysToPerform: service.maximumDaysToPerform,
			allowSubscription: service.allowSubscription,
			allowNewApplication: service.allowNewApplication,
			serviceImageFile: null,
			serviceImageURL: service.examplePictureFile?.path ? formatImageFileUrl(service.examplePictureFile.path) : null,
			show: true,
			isEditing: true,
		}
	}

	async function openAddServicePeriodModal(serviceId: number) {
		const tags = await loadTags(serviceId)
		const servicePeriods = selectedServiceSchedulePeriods?.map(serviceSchedulePeriod => {
			const tagPricing = serviceSchedulePeriod.tag?.tagPricingForService
			return {
				...serviceSchedulePeriod,
				tagName: serviceSchedulePeriod.tag?.name,
				priceAdjustment: tagPricing?.pricingType === 'PERCENT' ? `${toDisplay(tagPricing.priceAdjustment)}%` : formatCurrency(tagPricing?.priceAdjustment),
			}
		})
		const availableTags = tags.filter(tag => !servicePeriods?.find(servicePeriod => servicePeriod.tagId === tag.id))

		addServicePeriodModal = {
			...klona(defaultServicePeriodModalState),
			availableTags,
			show: true,
		}
	}

	async function selectServiceId(id: number) {
		if (selectedServiceId === id) {
			selectedServiceId = null
			selectedServiceServiceQuestions = undefined
			selectedQuestionId = null
			selectedQuestion = null
		} else {
			selectedServiceId = id
			loadingSubtable = true
			selectedQuestionId = null
			selectedQuestion = null
			selectedServiceServiceQuestions = await loadQuestions(id)
			selectedServiceSchedulePeriods = await loadServiceSchedulePeriods(id)
			loadingSubtable = false
		}
	}

	async function saveService() {
		try {
			const serviceToSave: {
				name: string
				codeName: string
				description: string | null
				warning: string | null
				active: boolean
				allowSubscription: boolean
				allowNewApplication: boolean
				maximumDaysToPerform: number
				minimumDaysToPerform: number
				requirement: string
				exampleImageFile: { base64String: string; fileName: string } | null
			} = {
				name: serviceModal.name,
				codeName: serviceModal.codeName,
				description: serviceModal.description || null,
				warning: serviceModal.warning || null,
				active: serviceModal.status === 'Active',
				allowSubscription: serviceModal.allowSubscription,
				allowNewApplication: serviceModal.allowNewApplication,
				maximumDaysToPerform: serviceModal.maximumDaysToPerform ?? 1,
				minimumDaysToPerform: 0,
				requirement: serviceModal.requirement,
				exampleImageFile: null,
			}
			if (serviceModal.serviceImageFile) {
				const fileString = await readAsDataURL(serviceModal.serviceImageFile)
				const prefixString = ';base64,'
				const base64EncodedData = fileString.substring(fileString.indexOf(prefixString) + prefixString.length)
				serviceToSave.exampleImageFile = {
					base64String: base64EncodedData,
					fileName: serviceModal.serviceImageFile.name,
				}
			} else {
				serviceToSave.exampleImageFile = null
			}

			let savedService: AdminService | null = null
			if (serviceModal.serviceId) {
				const { data } = await updateServiceMutation.mutate({
					editService: {
						id: serviceModal.serviceId,
						...serviceToSave,
					},
				})

				if (!data) {
					throw new Error('Failed to save service')
				}

				savedService = data.editService
			} else {
				const { data } = await newServiceMutation.mutate({
					newService: {
						...serviceToSave,
					},
				})

				if (!data) {
					throw new Error('Failed to save service')
				}

				savedService = data.newService
			}

			services = upsert(services, 'id', savedService)

			services = services

			serviceModal = klona(defaultServiceModalState)
		} catch (err: unknown) {
			const error = err as Error
			serviceModal.errorMessage = error.message
			console.error(error)
		}
	}

	async function setImageFile(file: File | undefined) {
		if (!file) {
			return
		}
		const fileUrl = URL.createObjectURL(file)
		serviceModal.serviceImageURL = fileUrl
		serviceModal.serviceImageFile = file
	}

	function uploadServiceImage(): void {
		document.getElementById('serviceImage')?.click()
	}

	function removeServiceImage() {
		if (serviceModal.serviceImageURL) {
			URL.revokeObjectURL(serviceModal.serviceImageURL)
			serviceModal.serviceImageURL = null
			serviceModal.serviceImageFile = null
		}
	}

	async function addNewServicePeriod() {
		let tagId: number | null = null
		if (!selectedServiceId) {
			addServicePeriodModal.errorMessage = 'Service is required'
			return
		}
		let serviceId: number = selectedServiceId
		if (!addServicePeriodModal.selectedTagId && addServicePeriodModal.tagCode && addServicePeriodModal.tagName && addServicePeriodModal.tagEntityType) {
			try {
				const { data } = await newTagMutation.mutate({
					tag: {
						code: addServicePeriodModal.tagCode,
						name: addServicePeriodModal.tagName,
						description: addServicePeriodModal.tagDescription,
						prompt: addServicePeriodModal.tagPrompt,
						entityType: addServicePeriodModal.tagEntityType,
					},
				})

				if (!data) {
					throw new Error('Failed to create new tag')
				}

				tagId = data.newTag.id
			} catch (err: unknown) {
				const error = err as Error
				addServicePeriodModal.errorMessage = error.message
				console.error(error)
			}
		} else {
			tagId = addServicePeriodModal.selectedTagId
		}

		if (!tagId) {
			addServicePeriodModal.errorMessage = 'Tag is required'
			return
		}

		const tagPricing = {
			tagId,
			serviceId: serviceId,
			priceAdjustment: addServicePeriodModal.pricingType === 'PERCENT' ? toSave(parseFloat(addServicePeriodModal.priceAdjustment)) : addServicePeriodModal.priceAdjustment,
			pricingType: addServicePeriodModal.pricingType,
			postPriceAdjustment: addServicePeriodModal.postPriceAdjustment,
		}

		if (!addServicePeriodModal.period) {
			addServicePeriodModal.errorMessage = 'Period is required'
			return
		}

		const serviceSchedulePeriod = {
			serviceId: selectedServiceId,
			tagId,
			serviceSchedulePeriod: addServicePeriodModal.period,
		}

		try {
			// we don't need the new tag pricing object, since tag pricing need to tie to a tag
			// which in the newServiceSchedulePeriod mutation, we are passing the same tagId as well
			// therefore, in the return object of newServiceSchedulePeriod mutation, we will get the new tagPricing object as well
			const { data } = await newTagPricingMutation.mutate({
				tagPricing,
			})

			if (!data) {
				throw new Error('Failed to create new tag pricing')
			}
		} catch (err: unknown) {
			const error = err as Error
			addServicePeriodModal.errorMessage = error.message
			console.error(error)
		}

		try {
			const { data } = await newServiceSchedulePeriodMutation.mutate({
				serviceSchedulePeriod,
				serviceId,
			})

			if (!data) {
				throw new Error('Failed to create new service schedule period')
			}

			selectedServiceSchedulePeriods = upsert(selectedServiceSchedulePeriods ?? [], 'id', data.newServiceSchedulePeriod)
			selectedServiceSchedulePeriods = selectedServiceSchedulePeriods
			addServicePeriodModal = klona(defaultServicePeriodModalState)
		} catch (err: unknown) {
			const error = err as Error
			addServicePeriodModal.errorMessage = error.message
			console.error(error)
		}
	}

	function codeNameIsEqual(): boolean {
		const formattedCodeName = formatCodeName(serviceModal.codeName)
		return formatCodeName(preChangeName) === formattedCodeName
	}

	async function filterServices() {
		loadingServices = true
		if (serviceStatusFilter) {
			services = await loadServices({
				active: serviceStatusFilter === 'Active',
			})
		} else {
			services = await loadServices()
		}
		loadingServices = false
	}
</script>

<div class="container-fluid mt-3">
	<div class="d-flex justify-content-end">
		<Button
			iconClass="plus"
			on:click={() => openAddServiceModal()}
		>
			Add New Service
		</Button>
	</div>
	<Table
		responsive
		filterEnabled
		showFilterLabel
		filterLabel="Filter Services"
		rows={services}
		{columns}
	>
		<svelte:fragment slot="header">
			<div class="form-row align-items-end">
				<div class="col-12 col-md-3">
					<Select
						label="Service Status"
						bind:value={serviceStatusFilter}
						emptyText="Any Status"
						disabled={loadingServices}
						on:change={() => filterServices()}
					>
						{#each serviceStatusList as status}
							<option value={status.value}>{status.label}</option>
						{/each}
					</Select>
				</div>
			</div>
		</svelte:fragment>
		<svelte:fragment slot="no-rows">
			<tr>
				<td colspan="5">
					<span>No services found</span>
				</td>
			</tr>
		</svelte:fragment>
		<svelte:fragment
			slot="body"
			let:rows
		>
			{#if loadingServices}
				<tr>
					<td colspan="5">
						<Throbber />
					</td>
				</tr>
			{:else}
				{#each rows as row}
					<tr
						class="cursor-pointer"
						class:table-dark={row.id === selectedServiceId}
						on:click={() => selectServiceId(row.id)}
					>
						<Td
							property="name"
							class="font-weight-bold"
							style="height: 60px;"
						>
							<span>{row.name}</span>
							<div>
								<span
									style="font-size: 60%;"
									class="badge badge-pill badge-primary mr-1">{row.codeName}</span
								>
								{#if row.allowSubscription}
									<span
										style="font-size: 60%;"
										class="badge badge-pill badge-info mr-1">ALlow Recurring Subscription</span
									>
								{/if}
								{#if row.allowNewApplication}
									<span
										style="font-size: 60%;"
										class="badge badge-pill badge-success mr-1">Allow New Applications</span
									>
								{/if}
								{#if row.warning}
									<span
										style="font-size: 60%;"
										class="badge badge-pill badge-danger mr-1"
										title={row.warning}>Has Warning Text</span
									>
								{/if}
							</div>
						</Td>
						<Td property="description">{row.description ?? ''}</Td>
						<Td property="maximumDaysToPerform">{row.maximumDaysToPerform}</Td>
						<Td
							property="active"
							class={row.active ? 'text-success' : 'text-danger'}
						>
							{row.active ? 'Active' : 'Inactive'}
						</Td>
						<Td
							property="edit"
							stopPropagation
						>
							<Button
								size="sm"
								iconClass="pen"
								on:click={() => openEditServiceModal(row)}
							/>
						</Td>
					</tr>
					{#if row.id === selectedServiceId}
						{#if loadingSubtable}
							<tr>
								<td colspan={columns.length}>
									<Throbber />
								</td>
							</tr>
						{:else}
							<tr>
								<td colspan={columns.length}>
									<ServiceQuestionList
										{selectedServiceId}
										bind:serviceQuestions={selectedServiceServiceQuestions}
										bind:selectedQuestionId
										bind:selectedQuestion
										newAnswerAllowed
									/>
								</td>
							</tr>
							<tr>
								<td colspan={columns.length}>
									<div class="card">
										<div class="card-header d-flex justify-content-between align-items-center py-1 px-2">
											<h5 class="mb-0">Subscription Periods</h5>
											<Button
												size="sm"
												iconClass="plus"
												on:click={() => openAddServicePeriodModal(row.id)}
											>
												Add New Period
											</Button>
										</div>
										<div class="card-body p-2">
											<ServiceSchedulePeriodTable bind:serviceSchedulePeriods={selectedServiceSchedulePeriods} />
										</div>
									</div>
								</td>
							</tr>
						{/if}
					{/if}
				{/each}
			{/if}
		</svelte:fragment>
	</Table>
</div>

<Modal
	bind:show={serviceModal.show}
	title={serviceModal.isEditing ? 'Edit Service' : 'Add New Service'}
	cancelButtonText="Cancel"
	confirmButtonDisabled={!serviceModal.name || !serviceModal.codeName || !serviceModal.maximumDaysToPerform}
	backdropClickCancels={false}
	on:confirm={() => saveService()}
	on:close={() => (serviceModal.show = false)}
>
	<div class="form-row">
		<div class="col-6">
			<Input
				id="serviceName"
				label="Name"
				bind:value={serviceModal.name}
				required
				validation={{
					value: serviceModal.name,
				}}
				on:input={() => {
					if (serviceModal.codeName === '' || codeNameIsEqual()) {
						serviceModal.codeName = formatCodeName(serviceModal.name)
					}
					preChangeName = serviceModal.name
				}}
			/>
		</div>
		<div class="col-6">
			<Input
				id="serviceCodeName"
				label="Code Name"
				bind:value={serviceModal.codeName}
				required
				validation={{
					value: serviceModal.codeName,
				}}
				disabled={serviceModal.isEditing}
				on:input={() => {
					serviceModal.codeName = formatCodeName(serviceModal.codeName)
				}}
			/>
		</div>
		<div class="col-12">
			<TextArea
				id="serviceDescription"
				label="Description"
				bind:value={serviceModal.description}
				placeholder=""
				rows={2}
			/>
		</div>
		<div class="col-12">
			<CollapsibleCard
				headerText="Provider Onboarding Details"
				cardHeaderClass="card-header d-flex justify-content-between h6"
				entireHeaderToggles
				bodyShown={true}
			>
				<div class="form-row align-items-center">
					<div class="col-12 col-sm-4">
						<div class="d-flex flex-column justify-content-center align-items-center">
							<img
								class="rounded"
								src={serviceModal.serviceImageURL ?? '/images/placeholder-hiro.png'}
								alt="Service"
								title="This is an example image of the service"
								style="max-width: 100px; max-height: 100px;"
							/>
							<div>
								<FileInput
									id="serviceImage"
									accept="image/*"
									style="display: none;"
									on:change={event => setImageFile(event.detail[0])}
								/>
								<Button
									outline
									class="mt-2"
									size="xs"
									color="primary"
									title="Upload New Image"
									iconClass="image"
									on:click={() => uploadServiceImage()}
								>
									{#if !serviceModal.serviceImageURL}
										Upload Image
									{/if}
								</Button>
								{#if serviceModal.serviceImageURL}
									<Button
										outline
										class="mt-2"
										size="xs"
										color="danger"
										title="Remove Image"
										iconClass="trash"
										on:click={() => removeServiceImage()}
									></Button>
								{/if}
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-8">
						<div class="d-flex flex-column">
							<div class="mb-2">
								<TextArea
									id="requirement"
									rows={4}
									label="Requirement"
									bind:value={serviceModal.requirement}
									placeholder="Write the requirements for the service here..."
								/>
							</div>
							<div>
								<Checkbox
									inline
									type="radio"
									label="Allow New Applications?"
									bind:checked={serviceModal.allowNewApplication}
									radioButtonColor="primary"
									trueLabel="Yes"
									falseLabel="No"
								/>
							</div>
						</div>
					</div>
				</div>
			</CollapsibleCard>
		</div>
		<div class="col-12">
			<Input
				label="Warning text (if any)"
				bind:value={serviceModal.warning}
			/>
		</div>
		<div class="col-6">
			<Select
				label="Status"
				bind:value={serviceModal.status}
				showEmptyOption={false}
			>
				{#each serviceStatusList as status}
					<option value={status.value}>{status.label}</option>
				{/each}
			</Select>
		</div>
		<div class="col-6">
			<Input
				id="maximumDaysToPerform"
				label="Maximum Days to Perform"
				bind:value={serviceModal.maximumDaysToPerform}
				type="number"
				min="1"
				required
				validation={{
					value: serviceModal.maximumDaysToPerform,
				}}
			/>
		</div>
		<div class="col-6">
			<Checkbox
				inline
				type="radio"
				label="Allow Recurring Subscription?"
				bind:checked={serviceModal.allowSubscription}
				radioButtonColor="primary"
				trueLabel="Yes"
				falseLabel="No"
			/>
		</div>
	</div>
	{#if serviceModal.errorMessage}
		<div
			style="opacity: 1;"
			class="signin-message-box text-center alert alert-danger"
			role="alert"
		>
			<i class="fas fa-exclamation-circle"></i> <strong style="font-size: x-small">{serviceModal.errorMessage}</strong>
		</div>
	{/if}
</Modal>

<Modal
	bind:show={addServicePeriodModal.show}
	title="Add New Service Period"
	cancelButtonText="Cancel"
	confirmButtonText="Create Service Period"
	confirmButtonDisabled={!addServicePeriodModal.period ||
		!addServicePeriodModal.priceAdjustment ||
		(addServicePeriodModal.postPriceAdjustment && parseFloat(addServicePeriodModal.priceAdjustment) >= 0) ||
		(isMakingNewTag && !(addServicePeriodModal.tagCode && addServicePeriodModal.tagName))}
	on:confirm={() => addNewServicePeriod()}
	on:close={() => (addServicePeriodModal.show = false)}
	on:backdrop={() => (addServicePeriodModal.show = false)}
>
	<div class="form-row">
		<div class="col-6">
			<Input
				required
				id="servicePeriod"
				label="Period (days)"
				bind:value={addServicePeriodModal.period}
				type="number"
				min="1"
				validation={{
					value: addServicePeriodModal.period,
				}}
			/>
		</div>
		<div class="col-6">
			<Select
				label="Tag"
				bind:value={addServicePeriodModal.selectedTagId}
				emptyValue={null}
				emptyText="New Tag"
			>
				{#each addServicePeriodModal.availableTags as tag}
					<option value={tag?.id}>{tag?.name}</option>
				{/each}
			</Select>
		</div>
		{#if isMakingNewTag}
			<div class="col-6">
				<Input
					required
					id="tagCode"
					label="Tag Code"
					bind:value={addServicePeriodModal.tagCode}
					validation={{
						value: addServicePeriodModal.tagCode,
					}}
				/>
			</div>
			<div class="col-6">
				<Input
					required
					id="tagName"
					label="Tag Name"
					bind:value={addServicePeriodModal.tagName}
					validation={{
						value: addServicePeriodModal.tagName,
					}}
				/>
			</div>
			<div class="col-12">
				<Input
					id="tagDescription"
					label="Tag Description"
					bind:value={addServicePeriodModal.tagDescription}
				/>
			</div>
			<div class="col-12">
				<TextArea
					id="promptTextArea"
					label="Prompt"
					labelClass="py-0 mb-0"
					textareaStyle="min-height:83px;"
					placeholder="Description on the tag that will only be shown to an admin"
					bind:value={addServicePeriodModal.tagPrompt}
				/>
			</div>
			<div class="col-12">
				<Select
					label="Tag Type"
					bind:value={addServicePeriodModal.tagEntityType}
					showEmptyOption={false}
				>
					{#each tagTypeList as tagType}
						<option value={tagType.value}>{tagType.label}</option>
					{/each}
				</Select>
			</div>
		{/if}
		<div class="col-6">
			<Select
				id="tagPricingType"
				label="Discount Type"
				bind:value={addServicePeriodModal.pricingType}
				showEmptyOption={false}
				on:change={() => {
					addServicePeriodModal.priceAdjustment = '0'
				}}
			>
				{#each tagPricingTypeList as pricingType}
					<option value={pricingType.value}>{pricingType.label}</option>
				{/each}
			</Select>
		</div>
		<div class="col-6">
			{#if addServicePeriodModal.pricingType === 'PERCENT'}
				<Input
					required
					id="priceAdjustmentPercentage"
					label="Percentage Amount"
					bind:value={addServicePeriodModal.priceAdjustment}
					type="number"
					placeholder="0"
					max={addServicePeriodModal.postPriceAdjustment ? '-1' : ''}
					validation={{
						validator: value => {
							if (addServicePeriodModal.postPriceAdjustment) {
								if (typeof value === 'string') {
									return parseInt(value, 10) < 0
								} else if (typeof value == 'number') {
									return value < 0
								} else {
									return false
								}
							} else {
								return true
							}
						},
						value: addServicePeriodModal.priceAdjustment,
					}}
				/>
			{:else}
				<CurrencyInput
					required
					label="Flat Amount"
					bind:value={addServicePeriodModal.priceAdjustment}
					inputmode="decimal"
					validation={{
						value: addServicePeriodModal.priceAdjustment,
					}}
				/>
			{/if}
			{#if addServicePeriodModal.postPriceAdjustment && parseFloat(addServicePeriodModal.priceAdjustment) > 0}
				<small class="form-text text-danger"> Amount must be negative </small>
			{/if}
		</div>
	</div>
	<small class="text-danger"> Enter a negative value for a discount. Subscription discounts will always apply to the final price. </small>
	{#if addServicePeriodModal.errorMessage}
		<div
			style="opacity: 1;"
			class="signin-message-box text-center alert alert-danger"
			role="alert"
		>
			<i class="fas fa-exclamation-circle"></i> <strong style="font-size: x-small">{addServicePeriodModal.errorMessage}</strong>
		</div>
	{/if}
</Modal>
