import { graphql } from '$houdini'

const getSignupDisabledMessageQuery = graphql(`
	query SignupDisabledMessage {
		signupDisabledMessage
	}
`)

export default async function getSignupDisabledMessage() {
	const { data } = await getSignupDisabledMessageQuery.fetch()
	return data?.signupDisabledMessage ?? ''
}
