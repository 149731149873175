export default {
    "name": "ServiceAnswerData",
    "kind": "HoudiniFragment",
    "hash": "a3dc35bd85ea5e3c827302ed455263991ec4b2dc886233397400b8ca7eecae9f",

    "raw": `fragment ServiceAnswerData on Answer {
  id
  answer
  tag {
    ...ServiceTagData
    id
  }
  __typename
}

fragment ServiceTagData on Tag {
  id
  code
  name
  description
  prompt
  entityType
  tagPricingForService(serviceId: $serviceId) {
    ...TagPricingData
    id
  }
  __typename
}

fragment TagPricingData on TagPricing {
  id
  priceAdjustment
  pricingType
  serviceId
  tagId
  postPriceAdjustment
  __typename
}
`,

    "rootType": "Answer",
    "stripVariables": [],

    "selection": {
        "fields": {
            "id": {
                "type": "PositiveInt",
                "keyRaw": "id",
                "visible": true
            },

            "answer": {
                "type": "String",
                "keyRaw": "answer",
                "visible": true
            },

            "tag": {
                "type": "Tag",
                "keyRaw": "tag",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "code": {
                            "type": "String",
                            "keyRaw": "code",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "nullable": true,
                            "visible": true
                        },

                        "prompt": {
                            "type": "String",
                            "keyRaw": "prompt",
                            "nullable": true,
                            "visible": true
                        },

                        "entityType": {
                            "type": "EntityType",
                            "keyRaw": "entityType",
                            "visible": true
                        },

                        "tagPricingForService": {
                            "type": "TagPricing",
                            "keyRaw": "tagPricingForService(serviceId: $serviceId)",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "priceAdjustment": {
                                        "type": "String",
                                        "keyRaw": "priceAdjustment",
                                        "visible": true
                                    },

                                    "pricingType": {
                                        "type": "PricingType",
                                        "keyRaw": "pricingType",
                                        "visible": true
                                    },

                                    "serviceId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "serviceId",
                                        "visible": true
                                    },

                                    "tagId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "tagId",
                                        "visible": true
                                    },

                                    "postPriceAdjustment": {
                                        "type": "Boolean",
                                        "keyRaw": "postPriceAdjustment",
                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "TagPricingData": {
                                        "arguments": {}
                                    }
                                }
                            },

                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "ServiceTagData": {
                            "arguments": {
                                "serviceId": {
                                    "kind": "Variable",

                                    "name": {
                                        "kind": "Name",
                                        "value": "serviceId"
                                    }
                                }
                            }
                        }
                    }
                },

                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "serviceId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=d4c3cbd5c8b81f5b623c798f46798a1f8024d8e9c10c02769574f838ecfbe1ba";