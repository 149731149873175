export default {
    "name": "CreateServiceSchedulePeriod",
    "kind": "HoudiniMutation",
    "hash": "e71fdc3a51b954edd3554a55b3a72f7f841a5a251e2d6e841af69cdc52dfae29",

    "raw": `mutation CreateServiceSchedulePeriod($serviceSchedulePeriod: NewServiceSchedulePeriod!, $serviceId: PositiveInt) {
  newServiceSchedulePeriod(serviceSchedulePeriod: $serviceSchedulePeriod) {
    ...ServicePeriodData_7Hvpf
    id
  }
}

fragment ServicePeriodData_7Hvpf on ServiceSchedulePeriod {
  id
  tagId
  serviceId
  status
  serviceSchedulePeriod
  tag {
    ...ServiceTagData_7Hvpf
    id
  }
  __typename
}

fragment ServiceTagData_7Hvpf on Tag {
  id
  code
  name
  description
  prompt
  entityType
  tagPricingForService(serviceId: $serviceId) {
    ...TagPricingData
    id
  }
  __typename
}

fragment TagPricingData on TagPricing {
  id
  priceAdjustment
  pricingType
  serviceId
  tagId
  postPriceAdjustment
  __typename
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "newServiceSchedulePeriod": {
                "type": "ServiceSchedulePeriod",
                "keyRaw": "newServiceSchedulePeriod(serviceSchedulePeriod: $serviceSchedulePeriod)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "tagId": {
                            "type": "PositiveInt",
                            "keyRaw": "tagId",
                            "visible": true
                        },

                        "serviceId": {
                            "type": "PositiveInt",
                            "keyRaw": "serviceId",
                            "visible": true
                        },

                        "status": {
                            "type": "ServiceSchedulePeriodStatus",
                            "keyRaw": "status",
                            "visible": true
                        },

                        "serviceSchedulePeriod": {
                            "type": "PositiveInt",
                            "keyRaw": "serviceSchedulePeriod",
                            "visible": true
                        },

                        "tag": {
                            "type": "Tag",
                            "keyRaw": "tag",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "prompt": {
                                        "type": "String",
                                        "keyRaw": "prompt",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "entityType": {
                                        "type": "EntityType",
                                        "keyRaw": "entityType",
                                        "visible": true
                                    },

                                    "tagPricingForService": {
                                        "type": "TagPricing",
                                        "keyRaw": "tagPricingForService(serviceId: $serviceId)",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "priceAdjustment": {
                                                    "type": "String",
                                                    "keyRaw": "priceAdjustment",
                                                    "visible": true
                                                },

                                                "pricingType": {
                                                    "type": "PricingType",
                                                    "keyRaw": "pricingType",
                                                    "visible": true
                                                },

                                                "serviceId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "serviceId",
                                                    "visible": true
                                                },

                                                "tagId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "tagId",
                                                    "visible": true
                                                },

                                                "postPriceAdjustment": {
                                                    "type": "Boolean",
                                                    "keyRaw": "postPriceAdjustment",
                                                    "visible": true
                                                },

                                                "__typename": {
                                                    "type": "String",
                                                    "keyRaw": "__typename",
                                                    "visible": true
                                                }
                                            },

                                            "fragments": {
                                                "TagPricingData": {
                                                    "arguments": {}
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "ServiceTagData": {
                                        "arguments": {
                                            "serviceId": {
                                                "kind": "Variable",

                                                "name": {
                                                    "kind": "Name",
                                                    "value": "serviceId"
                                                }
                                            }
                                        }
                                    }
                                }
                            },

                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "ServicePeriodData": {
                            "arguments": {
                                "serviceId": {
                                    "kind": "Variable",

                                    "name": {
                                        "kind": "Name",
                                        "value": "serviceId"
                                    }
                                }
                            }
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "serviceSchedulePeriod": "NewServiceSchedulePeriod",
            "serviceId": "PositiveInt"
        },

        "types": {
            "NewServiceSchedulePeriod": {
                "serviceId": "PositiveInt",
                "serviceSchedulePeriod": "PositiveInt",
                "tagId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=65de2f9a26886ad51143637c985e54af3e5ef473d0cf9efb9e2a7134728f0570";