export default {
    "name": "ServiceData",
    "kind": "HoudiniFragment",
    "hash": "116a38cbf4ccd69bccb38e91eda9fe944b4186a7aa681d5d8a087d26c69ae3a5",

    "raw": `fragment ServiceData on Service {
  id
  name
  description
  codeName
  active
  allowSubscription
  warning
  maximumDaysToPerform
  requirement
  allowNewApplication
  examplePictureFileId
  examplePictureFile {
    id
    name
    path
  }
  __typename
}
`,

    "rootType": "Service",
    "stripVariables": [],

    "selection": {
        "fields": {
            "id": {
                "type": "PositiveInt",
                "keyRaw": "id",
                "visible": true
            },

            "name": {
                "type": "String",
                "keyRaw": "name",
                "visible": true
            },

            "description": {
                "type": "String",
                "keyRaw": "description",
                "nullable": true,
                "visible": true
            },

            "codeName": {
                "type": "String",
                "keyRaw": "codeName",
                "visible": true
            },

            "active": {
                "type": "Boolean",
                "keyRaw": "active",
                "visible": true
            },

            "allowSubscription": {
                "type": "Boolean",
                "keyRaw": "allowSubscription",
                "visible": true
            },

            "warning": {
                "type": "String",
                "keyRaw": "warning",
                "nullable": true,
                "visible": true
            },

            "maximumDaysToPerform": {
                "type": "PositiveInt",
                "keyRaw": "maximumDaysToPerform",
                "visible": true
            },

            "requirement": {
                "type": "String",
                "keyRaw": "requirement",
                "nullable": true,
                "visible": true
            },

            "allowNewApplication": {
                "type": "Boolean",
                "keyRaw": "allowNewApplication",
                "visible": true
            },

            "examplePictureFileId": {
                "type": "PositiveInt",
                "keyRaw": "examplePictureFileId",
                "nullable": true,
                "visible": true
            },

            "examplePictureFile": {
                "type": "FileEntity",
                "keyRaw": "examplePictureFile",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "path": {
                            "type": "String",
                            "keyRaw": "path",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=d1acb53e63b31d266f3a80a6cfbb8da58d8a04395d2ae2ded33c187b5bb7cb42";