import { getCompletedOrders, type CompletedOrdersData } from 'utility/load-completed-orders'
import component from './OrderHistory.svelte'

import { AppContext } from 'types/common'

export default ({ stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'app.customer.order-history',
		route: 'order-history',
		querystringParameters: ['jobId'],
		template: {
			svelte: true,
			component,
			options: {},
		},
		async resolve(_data, parameters) {
			const completedOrders = await getCompletedOrders()

			const selectedJobId = parameters.jobId ? parseInt(parameters.jobId, 10) : completedOrders[0]?.id ?? undefined

			let selectedJob: CompletedOrdersData | null = null
			if (selectedJobId) {
				selectedJob = completedOrders.find(job => job.id === selectedJobId) ?? null
			}
			return {
				jobs: completedOrders,
				selectedJobId,
				selectedJob,
			}
		},
	})
}
