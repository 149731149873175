export default {
    "name": "updateServiceSchedulePeriod",
    "kind": "HoudiniMutation",
    "hash": "c1d8b460c79bb7e26cc473f54fdd3f51cbb8f1d06c4b0d5c7fe2cac8dfc60640",

    "raw": `mutation updateServiceSchedulePeriod($serviceSchedulePeriodId: Float!) {
  toggleServiceSchedulePeriod(serviceSchedulePeriodId: $serviceSchedulePeriodId) {
    id
    tagId
    serviceId
    status
    serviceSchedulePeriod
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "toggleServiceSchedulePeriod": {
                "type": "ServiceSchedulePeriod",
                "keyRaw": "toggleServiceSchedulePeriod(serviceSchedulePeriodId: $serviceSchedulePeriodId)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "tagId": {
                            "type": "PositiveInt",
                            "keyRaw": "tagId",
                            "visible": true
                        },

                        "serviceId": {
                            "type": "PositiveInt",
                            "keyRaw": "serviceId",
                            "visible": true
                        },

                        "status": {
                            "type": "ServiceSchedulePeriodStatus",
                            "keyRaw": "status",
                            "visible": true
                        },

                        "serviceSchedulePeriod": {
                            "type": "PositiveInt",
                            "keyRaw": "serviceSchedulePeriod",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "serviceSchedulePeriodId": "Float"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=0cbb6028b1952c1e3f6173c726450646d18c7418163faf4b9f0652649800cdda";