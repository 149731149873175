<script lang="ts">
	import formatCurrency from 'utility/format/format-currency'
	import jobStatusMap from 'utility/job-status-map'
	import Button from '@isoftdata/svelte-button'
	import JobClaimInfo from './JobClaimInfo.svelte'
	import type { PaymentStatus$options } from '$houdini/graphql'
	import jobClaimStatusMap from 'utility/job-claim-status-map'
	import formatRelativeDate from 'utility/format/format-relative-date'
	import formatCityStateZip from 'utility/format/format-city-state-zip'
	import type { BaseAttachmentFile } from '@isoftdata/svelte-attachments'
	import { createEventDispatcher } from 'svelte'
	import GenericBadge from './GenericBadge.svelte'

	const dispatch = createEventDispatcher()

	type Job = {
		id: number
		jobStatus: keyof typeof jobStatusMap
		completed?: Date | null
		jobClaims:
			| {
					scheduledAt: Date
					jobClaimStatus: keyof typeof jobClaimStatusMap
					provider?: {
						userAccountId: number
						profilePictureFile: {
							path: string
						} | null
						userAccount: {
							fullName: string | null
						}
					}
			  }[]
			| null
		residence: {
			street: string
			city: string
			state: string
			zip: string
		}
		requestedSchedule: Date
		transaction?: {
			paymentStatus: PaymentStatus$options
			tip: string | null
			internalNotes: string | null
		} | null
		service: {
			name: string
		}
		answers:
			| {
					answer: string
			  }[]
			| null
		review?: string | null
		files: BaseAttachmentFile[] | null
	}

	export let job: Job
	export let showFooter: boolean = true
</script>

<div class="card">
	<div class="card-body">
		<div class="d-flex justify-content-between">
			<h6
				class="mb-0"
				style="font-size: large;"
			>
				{job.residence.street}
				<br />
				{formatCityStateZip(
					{
						city: job.residence.city,
						state: job.residence.state,
						zip: job.residence.zip,
					},
					true,
				)}
			</h6>
			<div class="d-flex flex-column align-items-end">
				<span class="badge badge-info ellipsis border mb-1">{job.service.name}</span>
				<GenericBadge jobType={job.jobStatus} />
				{#if job.review && job.review !== ''}
					<span class="badge badge-info mb-1">
						<i class="fa-solid fa-comment"></i>
						Reviewed
					</span>
				{/if}
				{#if job.transaction?.tip}
					<span class="badge badge-info mb-1">
						<i class="fa-solid fa-dollar-sign"></i>
						Tipped
					</span>
				{/if}
			</div>
		</div>
		{#if job.jobStatus !== 'COMPLETED' && job.jobStatus !== 'CANCELLED' && job.jobStatus !== 'EXPIRED'}
			<div class="mb-2">
				<span>{`Requested for ${formatRelativeDate(job.requestedSchedule, new Date()).formattedDate}`}</span>
			</div>
			<Button
				outline
				color="danger"
				class="mb-2 responsive-button"
				iconClass="xmark"
				on:click={() => {
					dispatch('cancelJob')
				}}>Cancel Order</Button
			>
		{/if}
		{#if job.jobStatus === 'CANCELLED'}
			<div class="mb-2">
				<span>{`Requested for ${formatRelativeDate(job.requestedSchedule, new Date()).formattedDate}`}</span>
			</div>
			{#if job.transaction?.internalNotes && job.transaction?.internalNotes !== ''}
				<div class="card mb-2">
					<div class="card-header">
						<h6 class="mb-0">Cancellation Reason</h6>
					</div>
					<div class="card-body">{job.transaction?.internalNotes}</div>
				</div>
			{/if}
		{/if}
		{#if job.answers && job.answers?.length > 0}
			<h6 class="mt-3 mb-0">Add-ons:</h6>
			{#each job.answers as addOn}
				<span class="badge badge-info mb-1 mr-1">{addOn.answer}</span>
			{/each}
		{/if}
		{#if job.jobClaims && job.jobClaims?.length > 0}
			<ul class="list-group">
				{#each job.jobClaims as jobCliam}
					<li class="list-group-item px-2">
						<JobClaimInfo
							jobClaim={jobCliam}
							{job}
						/>
					</li>
				{/each}
			</ul>
		{/if}
		<div class="form-row mx-0">
			{#if job.review}
				<div
					class:col-7={job.transaction?.tip || job.transaction?.tip === '0'}
					class="pl-0"
				>
					<div class="card mt-2">
						<div class="card-header">
							<h6 class="mb-0">Your Review</h6>
						</div>
						<div class="card-body">{job.review}</div>
					</div>
				</div>
			{/if}
			{#if job.transaction?.tip}
				<div
					class:col-5={job.review}
					class="pl-0"
				>
					<div class="card mt-2">
						<div class="card-header">
							<h6 class="mb-0">Tip</h6>
						</div>
						<div
							class="card-body numeric-tabular-nums"
							style="text-align: right;"
						>
							{formatCurrency(job.transaction?.tip)}
						</div>
					</div>
				</div>
			{/if}
		</div>
		{#if job.transaction?.paymentStatus === 'FAILED'}
			<div class="col-12 p-0 mt-2">
				<div class="alert alert-danger mb-0">
					<i class="fas fa-exclamation-circle"></i>
					<strong style="font-size: small"
						>There was a problem when charging your card. Please contact <a
							class="text-dark text-underline"
							href="https://lawnhiro.com/contact/">Lawnhiro support</a
						> to complete payment.</strong
					>
				</div>
			</div>
		{/if}
	</div>
	<div
		class="card-footer"
		class:d-none={!showFooter}
	>
		<slot name="cardFooter" />
	</div>
</div>
