<script lang="ts">
	import { addDays, addHours, endOfDay, formatDuration, formatISO } from 'date-fns'
	import { slide } from 'svelte/transition'
	import { getContext } from 'svelte'

	import Button from '@isoftdata/svelte-button'
	import { type BaseAttachmentFile } from '@isoftdata/svelte-attachments'
	import { upsert } from '@isoftdata/utility-array'

	import ClaimedJobsList from './ClaimedJobsList.svelte'
	import ClaimedJobsModal from './ClaimedJobsModal.svelte'
	import JobTimePicker from 'components/JobTimePicker.svelte'
	import DirectionButtons from 'components/DirectionButtons.svelte'
	import FirstOrderBadge from 'components/FirstOrderBadge.svelte'
	import GooglePlacesMap from 'components/GooglePlacesMap.svelte'

	import {
		clockInOrOut,
		completeJobClaim,
		delayClaim,
		loadFormattedJobClaimsWithMarkers,
		loadJobClaimActivity,
		loadJobForPhotoUpload,
		type ClockInOrOutResponse,
		type JobClaimWithMarker,
	} from 'utility/claimed-jobs-helper'
	import getValidJobDates from 'utility/get-valid-job-dates'
	import type { GoogleMapsPin, PlaceResultWithSelected } from 'utility/google-maps-helper'
	import { getMaxImageDimensions, type FileAssociation } from 'utility/graphql/manage-image-files'

	import type { SvelteAsr, PromiseType, Mediator } from 'types/common'
	import formatImageFileUrl from 'utility/format/format-image-file-url'
	import { getSession } from 'stores/session'
	import getFileLimitGlobalSetting from 'utility/graphql/get-file-limit-global-setting'

	const mediator = getContext<Mediator>('mediator')

	export let asr: SvelteAsr
	let gMap: GooglePlacesMap

	export let session: ReturnType<typeof getSession>
	export let jobClaimsWithMarkers: JobClaimWithMarker[] = []
	export let jobPlaces: PlaceResultWithSelected[]
	export let bounds: google.maps.LatLngBounds | null = null

	let fileLimitGlobalSetting: {
		beforeServiceMinFileCount: number
		beforeServiceMaxFileCount: number
		afterServiceMinFileCount: number
		afterServiceMaxFileCount: number
	}
	let providerId = session.provider?.id
	let userAccountId = session.userAccountId
	let jobPins: GoogleMapsPin[] = []
	let selectedJobId: number | null = null
	let selectedJobClaimId: number | null = null
	let loadingJobClaimDetails = false
	let showModal: boolean = false
	let delaying: boolean = false
	let isSavingDelay: boolean = false
	let isConfirmingCompletion: boolean = false
	let isCompletingJob: boolean = false
	let isTogglingClockState: boolean = false
	let isClockedIn: boolean = false
	let selectedJobStarted: boolean = false
	let intervalId: NodeJS.Timeout | null = null
	let fileAssociation: FileAssociation = {
		userAccountId,
		jobId: null,
		imageFileType: 'BEFORE',
	}
	let maxImageDimensions = 1600
	let photoFiles: BaseAttachmentFile[] = []
	let delayRescheduledTo: Date | undefined
	let validJobHours: PromiseType<ReturnType<typeof getValidJobDates>> | undefined
	let jobDay: string = ''
	let timeRange: string | null = 'SunriseSunset'
	let delayReason: string | null = null
	let internalNotes: string | null = null

	$: selectedJobClaim = jobClaimsWithMarkers.find(job => job.id === selectedJobClaimId)
	$: selectedJobClaimTotalActivityMinutes = jobClaimTotalActivityMinutes(selectedJobClaim?.allActivity) //This needs to be reactive to update the total time when the clock is running

	// This also can't be onMount since it needs the page loaded before it can use the elements to scroll to or click the map
	async function selectJobOnInitialLoad() {
		if (jobClaimsWithMarkers.length > 0) {
			const clockedInJobClaimIndex = jobClaimsWithMarkers.findIndex(jobClaim => !!jobClaim.allActivity?.length)

			if (clockedInJobClaimIndex !== -1) {
				const clockedInJobClaimIdOnLoad = jobClaimsWithMarkers[clockedInJobClaimIndex].id
				if (jobClaimsWithMarkers.length && clockedInJobClaimIdOnLoad) {
					const selectedJobClaim = jobClaimsWithMarkers.find(jobClaim => jobClaim.id === clockedInJobClaimIdOnLoad)
					if (selectedJobClaim) {
						const jobClickedEvent = new CustomEvent('jobClicked', { detail: selectedJobClaim.job.id })
						await jobClicked(jobClickedEvent)
					}
				}
			}
		}
	}

	selectJobOnInitialLoad().catch(console.error) // This is being asyncronously called so we need to catch any errors but it also needs to wait till the component is loaded before elements exist to "fake" click

	function clearActivityInterval() {
		if (intervalId) {
			clearInterval(intervalId)
		}
	}

	async function confirmDelayReschedule(jobClaimId: number | null, delayRescheduledTo: Date | undefined, delayReason: string | null): Promise<void> {
		if (!jobClaimId || !delayRescheduledTo || !providerId) {
			return
		}
		try {
			isSavingDelay = true
			await delayClaim(jobClaimId, delayRescheduledTo, delayReason)
			const jobClaims = await loadFormattedJobClaimsWithMarkers(providerId)

			clearActivityInterval()

			jobClaimsWithMarkers = jobClaims
		} catch (error: any) {
			mediator.call('showError', error, { title: 'Error Delaying Job', message: 'An unknown error occurred while trying to delay this job. Please refresh and try again.' })
			console.error(error)
		} finally {
			selectedJobClaimId = null
			isSavingDelay = false
			delaying = false
		}
	}

	async function confirmJobCompletion(jobClaimId: number | null, internalNotes: string | null): Promise<void> {
		if (!jobClaimId || !providerId) {
			return
		}
		try {
			isCompletingJob = true
			await completeJobClaim(jobClaimId, internalNotes)
			const jobClaims = await loadFormattedJobClaimsWithMarkers(providerId)

			clearActivityInterval()

			jobClaimsWithMarkers = jobClaims
			selectedJobClaimId = null
		} catch (error) {
			console.error(error)
		} finally {
			isCompletingJob = false
			isConfirmingCompletion = false
		}
	}

	function delay() {
		if (!validJobHours) {
			return
		}

		delaying = true
		delayRescheduledTo = delayRescheduledTo ?? validJobHours?.days[Object.keys(validJobHours.days)?.[0]]?.[0]?.date // default to first available day
		jobDay = validJobHours.days[Object.keys(validJobHours.days)?.[0]]?.[0]?.relativeDay ?? ''
	}

	async function toggleClockState(clockedIn: boolean) {
		if (!selectedJobClaim) {
			return
		}
		try {
			isTogglingClockState = true
			let savedJobClaimActivity = await clockInOrOut(selectedJobClaim.id, clockedIn)

			if (!savedJobClaimActivity) {
				throw new Error('Failed to clock in or out')
			}
			isClockedIn = !clockedIn // we didn't throw so we can assume the state has changed

			clearActivityInterval()

			if (isClockedIn) {
				startClockInInterval(savedJobClaimActivity)
			} else {
				selectedJobClaim.allActivity = upsert(selectedJobClaim?.allActivity ?? [], 'id', savedJobClaimActivity)
			}
		} catch (error: any) {
			console.error(error)
			mediator.call('showError', error, { title: 'Error Clocking In/Out', message: 'An unknown error occurred while trying to clock in or out of this job. Please refresh and try again.' })
		} finally {
			isTogglingClockState = false
		}
	}

	async function jobClicked(e: CustomEvent<any>, fromMap: boolean = false) {
		let localJobClaimId: number = e.detail
		let jobClaim: JobClaimWithMarker | undefined
		if (fromMap) {
			jobClaim = jobClaimsWithMarkers.find(jc => jc.id === localJobClaimId)
			if (!jobClaim) {
				return
			}
			localJobClaimId = jobClaim?.job.id
		} else {
			jobClaim = jobClaimsWithMarkers.find(jc => jc.job.id === localJobClaimId)
		}
		if (!jobClaim) {
			return
		}
		if (selectedJobId !== localJobClaimId) {
			selectedJobId = localJobClaimId
			selectedJobClaimId = jobClaim.id
		} else {
			gMap.hideInfoWindow()
			selectedJobClaimId = null
			selectedJobId = null
			// Just unselecting the job so we don't need to load/do other work for it
			return
		}

		loadingJobClaimDetails = true

		const loadedJobClaim = await loadJobClaimActivity(jobClaim.id)

		if (!loadedJobClaim || !selectedJobClaim) {
			// Selected job claim should be true but if we fail to load the job claim, we should not continue since it might have out of date data
			return
		}

		selectedJobClaim.allActivity = loadedJobClaim.allActivity

		const endDate = endOfDay(addDays(new Date(), 1))

		if (!loadedJobClaim?.job.residence?.latitude || !loadedJobClaim?.job.residence?.longitude) {
			return
		}

		validJobHours = await getValidJobDates(loadedJobClaim.job.residence.latitude, loadedJobClaim.job.residence.longitude, addHours(new Date(), 1), endDate)

		delaying = false
		isClockedIn = loadedJobClaim.allActivity?.some(({ end }) => !end) ?? false
		delayRescheduledTo = undefined
		delayReason = null
		loadingJobClaimDetails = false

		if (isClockedIn) {
			const activity = selectedJobClaim?.allActivity?.find(({ end }) => !end)
			if (activity) {
				startClockInInterval(activity)
			}
		}

		gMap.showInfoWindow({ position: { latitude: loadedJobClaim.job.residence.latitude, longitude: loadedJobClaim.job.residence.longitude } })
		const cardElement = document.getElementById(`job-${selectedJobClaim.job.id}`)
		if (cardElement) {
			cardElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
		}
	}

	function startClockInInterval(activity: ClockInOrOutResponse) {
		if (!selectedJobClaim) {
			return
		}

		selectedJobClaim.allActivity = upsert(selectedJobClaim?.allActivity ?? [], 'id', { ...activity, end: new Date() })

		// TODO: Ideally, since the delay function will close one job claim activity and open another, we should add all elapsed time when provider start the clock
		const startingIntervalId: NodeJS.Timeout = setInterval(() => {
			const jobClaimAllActivity = selectedJobClaim?.allActivity
			if (!jobClaimAllActivity) {
				return clearInterval(startingIntervalId)
			}
			const activityIndex = jobClaimAllActivity.findIndex(({ id }) => id === activity.id)
			let updatedActivity = jobClaimAllActivity[activityIndex]
			if (!updatedActivity.end) {
				return clearInterval(startingIntervalId)
			}
			selectedJobClaim?.allActivity?.splice(activityIndex, 1, {
				...updatedActivity,
				end: new Date(),
				elapsedTime: Math.floor((updatedActivity.end.getTime() - updatedActivity.start.getTime()) / 1000),
			})
			selectedJobClaim.allActivity = selectedJobClaim.allActivity
		}, 1000) // Count up every second

		intervalId = startingIntervalId
		return startingIntervalId
	}

	async function openUploadPhotoModal(activityLength?: number) {
		let hasStartedJob = activityLength ? true : false
		if (!selectedJobClaimId || !selectedJobId) {
			return
		}

		const selectedJobAddOns = selectedJobClaim?.job.answers?.map(answer => `- ${answer.answer}`).join('\n') ?? null
		const confirmationText = `Are you sure you want to ${hasStartedJob ? 'complete' : 'start'} this job? \n\n${
			hasStartedJob ? 'Please make sure that you have completed all add on services:' : 'Please be aware that you are responsible for completing all add on services:'
		} \n${selectedJobAddOns}`

		if (!selectedJobAddOns || confirm(confirmationText)) {
			try {
				const files = await loadJobForPhotoUpload(selectedJobId)
				const formattedPhotos = files
					?.filter(photo => (hasStartedJob ? photo.imageFileType === 'AFTER' : photo.imageFileType === 'BEFORE'))
					.map(photo => {
						return {
							fileId: photo.fileId,
							public: photo.public,
							rank: photo.rank,
							name: photo.file?.name ?? '',
							size: 0,
							mimeType: photo.file?.mimeType ?? '',
							createdDate: formatISO(photo.file?.created ?? 0, { representation: 'complete' }),
							path: formatImageFileUrl(photo.file?.path ?? ''),
						}
					})

				photoFiles = formattedPhotos ?? []
				fileAssociation = {
					userAccountId,
					jobId: selectedJobId,
					imageFileType: hasStartedJob ? 'AFTER' : 'BEFORE',
				}
				maxImageDimensions = await getMaxImageDimensions()
				selectedJobStarted = hasStartedJob
				isClockedIn = isClockedIn
				if (!fileLimitGlobalSetting) {
					// If we havn't loaded the settings yet, load them now
					fileLimitGlobalSetting = await getFileLimitGlobalSetting()
				}
				showModal = true
			} catch (error) {
				console.error(error)
			}
		}
	}

	function focusInternalNotes() {
		const internalNotesElement = document.getElementById('internalNotes')
		if (internalNotesElement) {
			internalNotesElement.focus()
		}
	}

	function jobClaimTotalActivityMinutes(jobClaimActivity: JobClaimWithMarker['allActivity'] | undefined): string {
		if (!jobClaimActivity) {
			return ''
		}
		const seconds = jobClaimActivity.reduce((acc, activity) => acc + activity.elapsedTime, 0) ?? 0
		const formattedDuration = formatDuration({ seconds }, { format: ['hours', 'minutes', 'seconds'] })
		return formattedDuration
	}
</script>

<div class="row m-3">
	<div class="col-12 col-md-6 col-lg-6 col-xl-3 p-0">
		<ClaimedJobsList
			jobClaims={jobClaimsWithMarkers}
			{selectedJobId}
			on:jobClicked={jobClicked}
		>
			{#if selectedJobClaim && selectedJobClaim.job.jobStatus === 'COMPLETED'}
				<div class="d-flex">
					<h1 class="mb-0 text-success mr-3"><i class="fa-solid fa-check"></i></h1>
					<p class="mb-0">
						This job is completed!<br />
						On to the next.
					</p>
				</div>
			{:else}
				<form>
					<div>
						{#if loadingJobClaimDetails}
							<i class="fas fa-spinner fa-spin fa-2x fa-fw"></i>
						{:else}
							{#if selectedJobClaim?.job.residence.answers && selectedJobClaim?.job.residence.answers.length > 0}
								<div class="mb-2">
									<span>Residence Details:</span>
									<div>
										{#each selectedJobClaim?.job.residence.answers as residenceTag}
											<span class="badge badge-primary mr-1">{residenceTag.tag.name}</span>
										{/each}
									</div>
								</div>
								<hr class="w-100 m-0" />
							{/if}
							{#if selectedJobClaim?.job.answers && selectedJobClaim?.job.answers.length > 0}
								<div class="mb-2 mt-1">
									<span>Add-Ons</span>
									<div>
										{#each selectedJobClaim?.job.answers as jobTag}
											<span class="badge badge-primary mr-1">{jobTag.answer}</span>
										{/each}
									</div>
								</div>
								<hr class="w-100 m-0" />
							{/if}
							{#if delaying}
								<h4>Delay Job</h4>
								<div
									class="mt-2"
									in:slide={{ duration: 100 }}
									out:slide={{ duration: 100 }}
								>
									<JobTimePicker
										label="Reschedule to"
										bind:selectedDateTime={delayRescheduledTo}
										bind:validJobHours
										bind:jobDay
										bind:timeRange
									/>
									<div class="form-group mt-2">
										<label for="delayNotes">Rain Delay Notes</label>
										<textarea
											class="form-control"
											id="delayNotes"
											bind:value={delayReason}
											placeholder="Optional. These notes will be shown to the customer, so be kind."
											rows="2"
										/>
									</div>
									<div class="d-flex justify-content-between w-100">
										<Button
											outline
											color="danger"
											size="lg"
											class="btn-block mt-2 mr-2"
											on:click={() => (delaying = false)}>Cancel</Button
										>
										<Button
											class="btn-block mt-2"
											color="info"
											size="lg"
											isLoading={isSavingDelay}
											on:click={() => confirmDelayReschedule(selectedJobClaimId, delayRescheduledTo, delayReason)}
											disabled={isSavingDelay}>Delay Job</Button
										>
									</div>
								</div>
							{:else if isConfirmingCompletion}
								<h4>Complete Job</h4>
								<label for="internalNotes">Internal Notes</label>
								<textarea
									id="internalNotes"
									rows="3"
									class="form-control"
									bind:value={internalNotes}
									placeholder="This will only be seen by LawnHiro administrators. The customer will not see these notes."
								/>
								<div class="d-flex justify-content-between w-100">
									<Button
										outline
										color="danger"
										size="lg"
										class="btn-block mt-2 mr-2"
										on:click={() => (isConfirmingCompletion = false)}>Cancel</Button
									>
									<Button
										class="btn-block mt-2"
										color="primary"
										size="lg"
										isLoading={isCompletingJob}
										on:click={() => confirmJobCompletion(selectedJobClaimId, internalNotes)}
										disabled={isCompletingJob}>Confirm</Button
									>
								</div>
							{:else}
								<div
									class="d-flex flex-column mb-2 mt-2"
									in:slide={{ duration: 100 }}
									out:slide={{ duration: 100 }}
								>
									<div class="d-flex justify-content-between align-items-end w-100 mb-2">
										{#if selectedJobClaim?.job.residence}
											<div>
												<DirectionButtons residence={selectedJobClaim.job.residence} />
											</div>
										{/if}
										<Button
											outline
											color="info"
											iconClass="cloud-drizzle"
											on:click={() => delay()}>Rain Delay</Button
										>
									</div>
									{#if selectedJobClaim && (selectedJobClaim.allActivity?.length ?? 0) > 0}
										<div class="d-flex justify-content-between align-items-center mt-2 mb-2 border rounded p-2">
											<div style="font-size: larger;">
												{selectedJobClaimTotalActivityMinutes}
											</div>
											<div>
												<Button
													outline
													color="dark"
													size="sm"
													disabled={isTogglingClockState}
													iconClass={isClockedIn ? 'pause' : 'play'}
													on:click={() => toggleClockState(isClockedIn)}
												>
													{isClockedIn ? 'Pause' : 'Resume'} Timer
												</Button>
											</div>
										</div>
									{/if}
									{#if selectedJobClaim?.job.specialInstructions}
										<div class="w-100 text-smaller">
											<div class="alert alert-danger mt-2 mb-2">
												<div class="font-weight-bold alert-heading">Customer Special Instructions</div>
												{selectedJobClaim.job.specialInstructions}
											</div>
										</div>
									{/if}
									<div class="d-flex justify-content-between w-100">
										<Button
											outline
											class="btn-block mt-2 w-100"
											style="height: 80px;"
											iconClass={selectedJobClaim?.allActivity?.length ? 'check' : 'play'}
											size="lg"
											color="primary"
											on:click={() => openUploadPhotoModal(selectedJobClaim?.allActivity?.length)}
										>
											{selectedJobClaim?.allActivity?.length ? 'Complete' : 'Start'} Job
										</Button>
									</div>
									{#if selectedJobClaim?.job.customerFirstJob}
										<FirstOrderBadge />
									{/if}
								</div>
							{/if}
						{/if}
					</div>
				</form>
			{/if}
		</ClaimedJobsList>
		{#if !jobClaimsWithMarkers.length}
			<div class="text-center mt-4">
				<div class="h3">No jobs found</div>
				<p class="lead">
					You can find more jobs on the <a href={asr.makePath('app.provider.available-jobs')}>available jobs page</a>.
				</p>
			</div>
		{/if}
	</div>
	{#if jobClaimsWithMarkers.length}
		<div class="col-12 col-md-6 col-lg-6 col-xl-9 mt-3 mt-md-0 pl-md-3">
			<GooglePlacesMap
				bind:places={jobPlaces}
				{bounds}
				mapStyle="height: 60vh; min-height: 200px;"
				initWithCurrentLocation={false}
				bind:pins={jobPins}
				on:markerClicked={e => jobClicked(e, true)}
				bind:this={gMap}
			/>
		</div>
	{/if}
</div>

<ClaimedJobsModal
	bind:show={showModal}
	bind:jobId={selectedJobId}
	bind:jobClaimId={selectedJobClaimId}
	{isClockedIn}
	bind:isConfirmingCompletion
	bind:fileAssociation
	bind:hasStartedJob={selectedJobStarted}
	bind:photoFiles
	{fileLimitGlobalSetting}
	{maxImageDimensions}
	on:toggleClockState={e => {
		toggleClockState(e.detail.isClockedIn)
	}}
	on:focusInternalNotes={focusInternalNotes}
/>
