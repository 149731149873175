import {
	graphql,
	PermissionListStore,
	AdminUserAccountsStore,
	type AdminUserAccounts$result,
	type PermissionList$result,
	SetUserAccountPermissionStore,
	type UserAccessLevel$options,
	UserAccountReactivateStore,
	UserAccountDeactivateStore,
	AdminRemoveProviderProfilePictureStore,
} from '$houdini'
import type { WritableDeep } from 'type-fest'

export type AdminUserAccount = WritableDeep<AdminUserAccounts$result['userAccounts']['data'][number]> & { accountType?: unknown }
export type AdminUserPermission = PermissionList$result['permissions']['data'][number]

const permissionListQuery: PermissionListStore = graphql`
	query PermissionList($pagination: PaginatedInput) {
		permissions(pagination: $pagination) {
			data {
				codeName
				category
				displayName
				description
			}
		}
	}
`

export const adminUserAccountsQuery: AdminUserAccountsStore = graphql`
	query AdminUserAccounts($filter: UserFilter, $pagination: PaginatedInput) {
		userAccounts(filter: $filter, pagination: $pagination) {
			data {
				id
				mobile
				created
				email
				firstName
				lastName
				fullName
				status
				customer {
					id
				}
				provider {
					id
					status
					profilePictureFile {
						id
						path
					}
				}
				userRoles {
					permissionName
					value
				}
			}
			info {
				pageNumber
				pageSize
				totalItems
				totalPages
			}
		}
	}
`

const setUserAccountPermissionMutation: SetUserAccountPermissionStore = graphql`
	mutation SetUserAccountPermission($accessLevel: UserAccessLevel!, $permissionCode: String!, $userAccountId: PositiveInt!) {
		setUserAccountPermission(accessLevel: $accessLevel, permissionCode: $permissionCode, userAccountId: $userAccountId)
	}
`

const reactivateUserAccountMutation: UserAccountReactivateStore = graphql`
	mutation UserAccountReactivate($userAccountId: Float!) {
		userAccountReactivate(userAccountId: $userAccountId) {
			id
			mobile
			created
			email
			firstName
			lastName
			fullName
			status
			customer {
				id
			}
			provider {
				id
				status
				profilePictureFile {
					id
					path
				}
			}
			userRoles {
				permissionName
				value
			}
		}
	}
`

const deactivateUserAccountMutation: UserAccountDeactivateStore = graphql`
	mutation UserAccountDeactivate($lockNotes: String!, $userAccountId: Float!) {
		userAccountDeactivate(lockNotes: $lockNotes, userAccountId: $userAccountId) {
			id
			mobile
			created
			email
			firstName
			lastName
			fullName
			status
			customer {
				id
			}
			provider {
				id
				status
				profilePictureFile {
					id
					path
				}
			}
			userRoles {
				permissionName
				value
			}
		}
	}
`

const removeProviderProfilePictureMutation: AdminRemoveProviderProfilePictureStore = graphql`
	mutation AdminRemoveProviderProfilePicture($providerId: PositiveInt!, $fileId: PositiveInt!) {
		detachFilesFromProvider(providerId: $providerId, fileId: $fileId)
	}
`

export async function adminDetachProviderProfilePicture(providerId: number, fileId: number) {
	const { data } = await removeProviderProfilePictureMutation.mutate({
		providerId,
		fileId,
	})

	if (!data?.detachFilesFromProvider) {
		return false
	}

	return data.detachFilesFromProvider
}

export async function loadPermissions() {
	const { data } = await permissionListQuery.fetch({
		variables: {
			pagination: {
				pageNumber: 1,
				pageSize: 0,
			},
		},
	})

	if (!data?.permissions.data) {
		return []
	}

	return data.permissions.data
}

export async function loadUserAccounts(
	active: boolean | null = true,
	roles: Array<'CUSTOMER' | 'PROVIDER'> | null = null,
	pagination: { pageNumber: number; pageSize: number } = { pageNumber: 1, pageSize: 0 }
) {
	const { data } = await adminUserAccountsQuery.fetch({
		variables: {
			filter: {
				activeOnly: active,
				roles,
			},
			pagination: {
				...pagination,
			},
		},
	})

	if (!data?.userAccounts.data) {
		return { data: [], info: { pageNumber: 1, pageSize: 0, totalItems: 0, totalPages: 0 } }
	}

	return data.userAccounts
}

export async function setUserPermission(userAccountId: number, permissionCode: string, accessLevel: UserAccessLevel$options) {
	const { data } = await setUserAccountPermissionMutation.mutate({
		userAccountId,
		permissionCode,
		accessLevel,
	})

	if (!data) {
		throw new Error('Failed to set user permission')
	}
}

export async function reactivateUserAccount(userAccountId: number) {
	const { data } = await reactivateUserAccountMutation.mutate({
		userAccountId,
	})

	if (!data?.userAccountReactivate) {
		throw new Error('Failed to reactivate user account')
	}

	return data.userAccountReactivate
}

export async function deactivateUserAccount(userAccountId: number, lockNotes: string) {
	const { data } = await deactivateUserAccountMutation.mutate({
		userAccountId,
		lockNotes,
	})

	if (!data?.userAccountDeactivate) {
		throw new Error('Failed to deactivate user account')
	}

	return data.userAccountDeactivate
}
