export default {
    "name": "ServiceTags",
    "kind": "HoudiniQuery",
    "hash": "202fb85bb80a1fbd9ce0b45e54894907e402eb8aa271774e1e5b73d330935e9c",

    "raw": `query ServiceTags($filter: TagQueryFilter, $serviceId: PositiveInt) {
  tags(filter: $filter) {
    data {
      ...ServiceTagData_7Hvpf
      id
    }
  }
}

fragment ServiceTagData_7Hvpf on Tag {
  id
  code
  name
  description
  prompt
  entityType
  tagPricingForService(serviceId: $serviceId) {
    ...TagPricingData
    id
  }
  __typename
}

fragment TagPricingData on TagPricing {
  id
  priceAdjustment
  pricingType
  serviceId
  tagId
  postPriceAdjustment
  __typename
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "tags": {
                "type": "TagResponse",
                "keyRaw": "tags(filter: $filter)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Tag",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "prompt": {
                                        "type": "String",
                                        "keyRaw": "prompt",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "entityType": {
                                        "type": "EntityType",
                                        "keyRaw": "entityType",
                                        "visible": true
                                    },

                                    "tagPricingForService": {
                                        "type": "TagPricing",
                                        "keyRaw": "tagPricingForService(serviceId: $serviceId)",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "priceAdjustment": {
                                                    "type": "String",
                                                    "keyRaw": "priceAdjustment",
                                                    "visible": true
                                                },

                                                "pricingType": {
                                                    "type": "PricingType",
                                                    "keyRaw": "pricingType",
                                                    "visible": true
                                                },

                                                "serviceId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "serviceId",
                                                    "visible": true
                                                },

                                                "tagId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "tagId",
                                                    "visible": true
                                                },

                                                "postPriceAdjustment": {
                                                    "type": "Boolean",
                                                    "keyRaw": "postPriceAdjustment",
                                                    "visible": true
                                                },

                                                "__typename": {
                                                    "type": "String",
                                                    "keyRaw": "__typename",
                                                    "visible": true
                                                }
                                            },

                                            "fragments": {
                                                "TagPricingData": {
                                                    "arguments": {}
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "ServiceTagData": {
                                        "arguments": {
                                            "serviceId": {
                                                "kind": "Variable",

                                                "name": {
                                                    "kind": "Name",
                                                    "value": "serviceId"
                                                }
                                            }
                                        }
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "TagQueryFilter",
            "serviceId": "PositiveInt"
        },

        "types": {
            "TagQueryFilter": {
                "code": "String",
                "entityType": "EntityType",
                "id": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=6e84184bef4ef86c33bdd4ca72df9bce64ca09b2fab31e12236b35cee80a25fe";