export default {
    "name": "GetSelectedCustomerResidenceServiceSchedules",
    "kind": "HoudiniQuery",
    "hash": "c0fce3014e6751ac1beac430d8e89f9e66526ab5fa25ef7a97986cbcb39328b7",

    "raw": `query GetSelectedCustomerResidenceServiceSchedules($filter: CustomerServiceScheduleFilter) {
  getCustomerServiceSchedules(filter: $filter) {
    id
    created
    endOfSchedule
    nextJob
    residence {
      city
      country
      state
      street
      zip
      id
    }
    service {
      id
      name
    }
    serviceSchedulePeriod {
      serviceSchedulePeriod
      id
    }
    status
    answers {
      id
      answer
    }
    jobs {
      id
      requestedSchedule
      jobStatus
      created
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "getCustomerServiceSchedules": {
                "type": "ServiceSchedule",
                "keyRaw": "getCustomerServiceSchedules(filter: $filter)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "created": {
                            "type": "DateTime",
                            "keyRaw": "created",
                            "visible": true
                        },

                        "endOfSchedule": {
                            "type": "Date",
                            "keyRaw": "endOfSchedule",
                            "visible": true
                        },

                        "nextJob": {
                            "type": "Date",
                            "keyRaw": "nextJob",
                            "visible": true
                        },

                        "residence": {
                            "type": "Residence",
                            "keyRaw": "residence",

                            "selection": {
                                "fields": {
                                    "city": {
                                        "type": "String",
                                        "keyRaw": "city",
                                        "visible": true
                                    },

                                    "country": {
                                        "type": "String",
                                        "keyRaw": "country",
                                        "visible": true
                                    },

                                    "state": {
                                        "type": "String",
                                        "keyRaw": "state",
                                        "visible": true
                                    },

                                    "street": {
                                        "type": "String",
                                        "keyRaw": "street",
                                        "visible": true
                                    },

                                    "zip": {
                                        "type": "String",
                                        "keyRaw": "zip",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "service": {
                            "type": "Service",
                            "keyRaw": "service",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "serviceSchedulePeriod": {
                            "type": "ServiceSchedulePeriod",
                            "keyRaw": "serviceSchedulePeriod",

                            "selection": {
                                "fields": {
                                    "serviceSchedulePeriod": {
                                        "type": "PositiveInt",
                                        "keyRaw": "serviceSchedulePeriod",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "status": {
                            "type": "ServiceScheduleStatus",
                            "keyRaw": "status",
                            "visible": true
                        },

                        "answers": {
                            "type": "Answer",
                            "keyRaw": "answers",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "answer": {
                                        "type": "String",
                                        "keyRaw": "answer",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "jobs": {
                            "type": "Job",
                            "keyRaw": "jobs",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "requestedSchedule": {
                                        "type": "Date",
                                        "keyRaw": "requestedSchedule",
                                        "visible": true
                                    },

                                    "jobStatus": {
                                        "type": "JobStatus",
                                        "keyRaw": "jobStatus",
                                        "visible": true
                                    },

                                    "created": {
                                        "type": "DateTime",
                                        "keyRaw": "created",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "CustomerServiceScheduleFilter"
        },

        "types": {
            "CustomerServiceScheduleFilter": {
                "residenceId": "PositiveInt",
                "serviceId": "PositiveInt",
                "status": "ServiceScheduleStatus"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=56c80d34ee53d0f5ba926658b3d8c2dd2a1b0cbbfb41a6f609a4d35fabaab56f";