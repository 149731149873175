import { graphql, type OrderListItemJob$result } from '$houdini'

export type OrderListItemJob = OrderListItemJob$result['job']

const orderListItemJobQuery = graphql(`
	query OrderListItemJob($jobId: PositiveInt!) {
		job(id: $jobId) {
			id
			residence {
				street
				city
				state
				zip
				country
				googleMapsPlaceId
			}
			jobStatus
			requestedSchedule
			completed
			transaction {
				subtotal
				total
				tip
				paymentStatus
				internalNotes
			}
			jobClaims {
				provider {
					userAccountId
					userAccount {
						fullName
					}
					profilePictureFile {
						path
					}
				}
				jobClaimStatus
				scheduledAt
			}
			review
			service {
				name
			}
			answers {
				id
				answer
			}
			files {
				id
				jobId
				userAccountId
				fileId
				file {
					id
					name
					created
					updated
					hash
					path
					mimeType
					type
				}
				public
				rank
				imageFileType
			}
		}
	}
`)

export async function getCustomerOrderListItemJob(jobId: number) {
	const { data } = await orderListItemJobQuery.fetch({
		variables: {
			jobId,
		},
	})

	if (!data?.job) {
		throw new Error('Failed to load job')
	}

	return data.job
}

export default getCustomerOrderListItemJob
