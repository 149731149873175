<script lang="ts">
	import Table, { Td, type Column, type SortDirection } from '@isoftdata/svelte-table'
	import Select from '@isoftdata/svelte-select'
	import DateRange from '@isoftdata/svelte-date-range'
	import Button from '@isoftdata/svelte-button'
	import Modal from '@isoftdata/svelte-modal'
	import TextArea from '@isoftdata/svelte-textarea'
	import JobClaimInfo from 'components/JobClaimInfo.svelte'
	import { adminJobsComputedJob, cancelJob, loadAdminJobs, releaseJobFromClaims, adminJobsListQuery, type ComputedAdminJob } from 'utility/admin-jobs-helper'
	import jobStatusMap from 'utility/job-status-map'
	import { parseISO } from 'date-fns'
	import { rangeFromDates, type NamedDateRange } from '@isoftdata/utility-date-time'
	import { upsert } from '@isoftdata/utility-array'
	import { type JobOrderBy$options, type JobStatus$options, type AdminJobs$input } from '$houdini'
	import Throbber from 'components/Throbber.svelte'

	const cancellableJobStatus = ['PENDING', 'CLAIMED', 'CLAIMABLE']
	const releasableJobStatus = ['CLAIMED']

	export let DEFAULT_RANGE: NamedDateRange = 'Last 7 Days'
	export let jobs: ComputedAdminJob[]
	export let pagination: { pageNumber: number; pageSize: number; totalPages: number; totalItems: number }
	export let dates: { from: string; to: string }
	let dateType = 'creationDate'

	const computedRange = rangeFromDates(dates.from, dates.to)
	let range = computedRange === '' ? DEFAULT_RANGE : computedRange
	let jobStatusFilter: string | null = null
	let sortDirection: SortDirection = 'DESC'
	let sortColumn: Column<ComputedAdminJob> = { property: 'id', name: 'Job Id' }
	let selectedJobId: number | null = null

	let cancelJobModal: {
		show: boolean
		job: ComputedAdminJob | null
		reason: string
		errorMessage: string
	} = {
		show: false,
		job: null,
		reason: '',
		errorMessage: '',
	}

	let jobStatusFilterList = [
		{ value: 'PENDING,CLAIMED,COMPLETED', label: 'Pending, Scheduled, or Completed' },
		{ value: 'PENDING,CLAIMED', label: 'Pending or Scheduled' },
		{ value: 'EXPIRED,COMPLETED', label: 'Expired or Completed' },
		...Object.entries(jobStatusMap).map(([value, obj]) => {
			return { value, label: obj.label }
		}),
	]

	const pageSizeList = [
		{ value: 10, label: '10' },
		{ value: 25, label: '25' },
		{ value: 50, label: '50' },
		{ value: 100, label: '100' },
		{ value: 0, label: 'All' },
	]

	const columns: Column<ComputedAdminJob>[] = [
		{ property: 'id', name: 'Job Id' },
		{ property: 'jobStatus', name: 'Status', sortType: false },
		{ property: 'residence[region][name]', name: 'Region', sortType: false },
		{ property: 'customerName', name: 'Customer Name', sortType: false },
		{ property: 'customerEmail', name: 'Customer Email', sortType: false },
		{ property: 'customerPhone', name: 'Customer Phone', sortType: false },
		{ property: 'serviceName', name: 'Service', sortType: false },
		{ property: 'created', name: 'Created', sortType: false },
		{ property: 'requestedSchedule', name: 'Requested Schedule' },
		{ property: 'completed', name: 'Completed' },
		{ property: 'formattedResidence', name: 'Address', sortType: false },
		{ property: 'squareFootage', name: 'sq. ft.', sortType: false },
		{ property: 'cost', name: 'Cost', sortType: false },
		{ property: 'total', name: 'Total', sortType: false },
		{ property: 'totalElapsedTime', name: 'Elapsed Time', sortType: false },
	]

	function selectJob(jobId: number) {
		if (selectedJobId === jobId) {
			selectedJobId = null
		} else {
			selectedJobId = jobId
		}
	}

	async function makeJobAvailable(jobId: number) {
		if (jobId && confirm('Are you sujre you want to move this job back to available?\n\nAll active claims for this job will be cancelled.')) {
			const releasedJobFromClaims = await releaseJobFromClaims(jobId)
			if (releasedJobFromClaims) {
				const computedReleasedJob = adminJobsComputedJob(releasedJobFromClaims)
				upsert(jobs, 'id', computedReleasedJob)
				jobs = jobs
			}
		}
	}

	function openCancelJobModal(id: number) {
		const foundJob = jobs.find(job => job.id === id)
		if (!foundJob) return
		cancelJobModal.job = foundJob
		cancelJobModal.show = true
		cancelJobModal.errorMessage = ''
		const reasonTextArea = document.getElementById('reasonForCancellationTextArea')
		if (reasonTextArea) {
			reasonTextArea.focus()
		}
	}

	async function adminJobCancel(jobId: number, reason: string) {
		try {
			const canceledJobRes = await cancelJob(jobId, reason)
			if (canceledJobRes) {
				const computedCanceledJob = adminJobsComputedJob(canceledJobRes)
				upsert(jobs, 'id', computedCanceledJob)
				jobs = jobs
				cancelJobModal.show = false
			}
		} catch (err: unknown) {
			const error = err as Error
			cancelJobModal.errorMessage = error.message
		}
	}

	async function dateRangeFilterChanged(e: CustomEvent) {
		if (e.detail.dates.from !== '' || e.detail.dates.to !== '') {
			const fromDate = parseISO(e.detail.dates.from)
			const toDate = parseISO(e.detail.dates.to)
			if (fromDate.getFullYear() < 1970 || toDate.getFullYear() < 1970) {
				return
			}
			if (fromDate > toDate) {
				alert('From date must be before To date')
				return
			}
			range = e.detail.range
			await filterUpdated()
		}
	}

	async function filterUpdated() {
		const filterInput: AdminJobs$input['filter'] = {}

		if (dateType === 'creationDate') {
			filterInput.createdFrom = dates.from ? parseISO(dates.from) : null
			filterInput.createdTo = dates.to ? parseISO(dates.to) : null
		} else if (dateType === 'requestedSchedule') {
			filterInput.requestedScheduleFrom = dates.from ? parseISO(dates.from) : null
			filterInput.requestedScheduleTo = dates.to ? parseISO(dates.to) : null
		}

		if (jobStatusFilter && jobStatusFilter !== 'FIRSTJOB') {
			filterInput.statuses = jobStatusFilter.split(',') as JobStatus$options[]
		}

		if (jobStatusFilter === 'FIRSTJOB') {
			filterInput.customerFirstJobOnly = true
		}

		const paginationInput = {
			pageNumber: pagination.pageNumber,
			pageSize: pagination.pageSize,
		}
		const orderByInput: JobOrderBy$options[] = [`${sortColumn.property.toUpperCase()}_${sortDirection}` as JobOrderBy$options]
		try {
			const newJobArray = await loadAdminJobs(filterInput, paginationInput, orderByInput)
			if (newJobArray.data) {
				let keepSelected: boolean = false
				const formattedJobs = newJobArray.data.map(job => {
					const computedJob = adminJobsComputedJob(job)
					if (selectedJobId === computedJob.id) {
						keepSelected = true
					}
					return computedJob
				})
				jobs = formattedJobs
				if (!keepSelected) {
					selectedJobId = null
				}
			}
			if (newJobArray.info) {
				pagination = {
					pageNumber: newJobArray.info.pageNumber,
					pageSize: newJobArray.info.pageSize ?? 0,
					totalPages: newJobArray.info.totalPages,
					totalItems: newJobArray.info.totalItems,
				}
			}
		} catch (err: unknown) {
			const error = err as Error
			console.error(error)
		}
	}
</script>

<div class="container-fluid mt-3">
	<Table
		responsive
		showFilterLabel
		filterEnabled
		paginationEnabled
		parentStyle="max-height: 70vh; overflow-y: auto;"
		perPageCount={pagination.pageSize}
		currentPageNumber={pagination.pageNumber}
		totalItemsCount={pagination.totalItems}
		{columns}
		bind:sortColumn
		bind:sortDirection
		bind:rows={jobs}
		filterLabel="Filter Jobs"
		on:pageChange={async event => {
			pagination.pageNumber = event.detail.pageNumber
			selectedJobId = null
			await filterUpdated()
		}}
	>
		<svelte:fragment slot="header">
			<div class="row align-items-end">
				<div class="col-12 col-md-6">
					<div class="form-row">
						<div class="col-12">
							<!-- Also add scheduledAt date as a date selector-->
							{#each ['creationDate', 'requestedSchedule'] as dateTypeOption}
								<div class="form-check">
									<input
										class="form-check-input"
										type="radio"
										id={`date-range-${dateTypeOption}`}
										name="dateType"
										value={dateTypeOption}
										bind:group={dateType}
										on:change={filterUpdated}
										disabled={$adminJobsListQuery.fetching}
									/>
									<label
										class="form-check-label"
										for={`date-range-${dateTypeOption}`}
									>
										{dateTypeOption === 'creationDate' ? 'Created Date' : 'Requested Schedule'}
									</label>
								</div>
							{/each}
						</div>
						<div class="col-12">
							<DateRange
								inline
								allowNone
								colClass="col-12 col-sm-4 mb-1"
								rangeLabel=""
								bind:range
								bind:dates
								on:change={dateRangeFilterChanged}
								disabled={$adminJobsListQuery.fetching}
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-2">
					<Select
						label="Page Size"
						bind:value={pagination.pageSize}
						on:change={filterUpdated}
						disabled={$adminJobsListQuery.fetching}
					>
						{#each pageSizeList as pageSize}
							<option value={pageSize.value}>{pageSize.label}</option>
						{/each}
					</Select>
				</div>
				<div class="col-12 col-md-3">
					<Select
						label="Job Status"
						bind:value={jobStatusFilter}
						emptyValue={null}
						emptyText="Any Status"
						on:change={filterUpdated}
						disabled={$adminJobsListQuery.fetching}
					>
						{#each jobStatusFilterList as jobStatus}
							<option value={jobStatus.value}>{jobStatus.label}</option>
						{/each}
					</Select>
				</div>
			</div>
		</svelte:fragment>
		<svelte:fragment
			slot="body"
			let:rows
		>
			{#if $adminJobsListQuery.fetching}
				<tr>
					<td
						colspan={columns.length}
						class="text-center"
					>
						<Throbber />
					</td>
				</tr>
			{:else if rows.length === 0}
				<tr>
					<td
						colspan={columns.length}
						class="text-center"
					>
						No jobs found
					</td>
				</tr>
			{:else}
				{#each rows as row}
					<tr
						class="cursor-pointer"
						class:table-dark={row.id === selectedJobId}
						on:click={() => {
							selectJob(row.id)
						}}
					>
						<Td property="id">{row.id}</Td>
						<Td property="jobStatus">
							<span class="badge badge-{jobStatusMap[row.jobStatus].class}"><i class="fa-solid {jobStatusMap[row.jobStatus].iconClass}"></i> {jobStatusMap[row.jobStatus].label}</span>
							{#if row.isRecurringSchedule}
								<br />
								<span class="badge badge-info"><i class="fa-solid fa-repeat fa-xs"></i> Recurring</span>
							{/if}
							{#if row.activeJobClaim}
								<br />
								{row.activeJobClaim.scheduledAtFormatted}
								{#if row.activeJobClaim.isLate}
									<br />
									<span class="badge badge-danger"><i class="fa-solid fa-alarm-exclamation fa-bounce"></i> Missed Scheduled Time</span>
								{:else if row.activeJobClaim.inProgress}
									<br />
									<span class="badge badge-warning"><i class="fa-solid fa-circle-notch fa-spin"></i> In Progress</span>
								{/if}
							{/if}
							{#if row.customerFirstJob}
								<span class="badge badge-warning"><i class="fa-solid fa-star"></i> Customer First Job</span>
							{/if}
						</Td>
						<Td property="residence[region][name]">{row.residence.region.name}</Td>
						<Td property="customerName">{row.customerName}</Td>
						<Td property="customerEmail"><a href="mailto:{row.customerEmail}">{row.customerEmail}</a></Td>
						<Td property="customerPhone"><a href="tel:{row.customerPhone}">{row.customerPhone}</a></Td>
						<Td property="serviceName">{row.serviceName}</Td>
						<Td property="created">{row.created}</Td>
						<Td property="requestedSchedule">{row.requestedScheduleFormatted}</Td>
						<Td property="completed">{row.completedFormatted}</Td>
						<Td property="formattedResidence">{row.formattedResidence}</Td>
						<Td property="squareFootage">{row.formattedSquareFootage}</Td>
						<Td property="cost">{row.cost}</Td>
						<Td property="total">{row.total}</Td>
						<Td property="totalElapsedTime">{row.formattedElapsedTime}</Td>
					</tr>
					{#if selectedJobId === row.id}
						<tr>
							<td colspan={columns.length}>
								{#if releasableJobStatus.includes(row.jobStatus)}
									<Button
										size="sm"
										on:click={() => {
											makeJobAvailable(row.id)
										}}>Make Available</Button
									>
								{/if}
								{#if cancellableJobStatus.includes(row.jobStatus)}
									<Button
										size="sm"
										color="danger"
										on:click={() => {
											openCancelJobModal(row.id)
										}}>Cancel Job...</Button
									>
								{/if}
								{#if row.jobClaims.length > 0}
									<ul class="list-group">
										{#each row.jobClaims as jobClaim}
											<li class="list-group-item pl-2">
												<JobClaimInfo
													{jobClaim}
													job={row}
													adminView={true}
												/>
											</li>
										{/each}
									</ul>
								{/if}
							</td>
						</tr>
					{/if}
				{/each}
			{/if}
		</svelte:fragment>
	</Table>
</div>

<Modal
	bind:show={cancelJobModal.show}
	title="Cancel Job #{cancelJobModal.job?.id}"
	cancelButtonText="Cancel"
	confirmButtonText="Cancel Job"
	confirmButtonDisabled={!cancelJobModal.reason}
	on:confirm={() => {
		if (!cancelJobModal.job) return
		adminJobCancel(cancelJobModal.job.id, cancelJobModal.reason)
	}}
	on:close={() => {
		cancelJobModal.show = false
	}}
	on:backdropClick={() => {
		cancelJobModal.show = false
	}}
>
	<div class="list-group">
		<li class="d-flex justify-content-between">
			<strong>Address:</strong>
			<span>{cancelJobModal.job?.formattedResidence}</span>
		</li>
		<li class="d-flex justify-content-between">
			<strong>Service:</strong>
			<span>{cancelJobModal.job?.serviceName}</span>
		</li>
		<TextArea
			id="reasonForCancellationTextArea"
			label="Reason:"
			labelClass="font-weight-bold"
			textareaStyle="min-height: 83px;"
			placeholder="Reason for canceling the job..."
			bind:value={cancelJobModal.reason}
		/>
		{#if cancelJobModal.errorMessage}
			<li
				style="opacity: 1;"
				class="signin-message-box text center alert alert-danger"
				role="alert"
			>
				<i class="fas fa-exclamation-circle"></i> <strong style="font-size: x-small;">{cancelJobModal.errorMessage}</strong>
			</li>
		{/if}
	</div>
</Modal>
