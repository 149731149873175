export default {
    "name": "PermissionList",
    "kind": "HoudiniQuery",
    "hash": "9a3e5a129a4c6219d8f66bb44f0dfe45f908ab781186e26b15f3dfb14e8aa111",

    "raw": `query PermissionList($pagination: PaginatedInput) {
  permissions(pagination: $pagination) {
    data {
      codeName
      category
      displayName
      description
      id
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "permissions": {
                "type": "PermissionResponse",
                "keyRaw": "permissions(pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Permission",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "codeName": {
                                        "type": "String",
                                        "keyRaw": "codeName",
                                        "visible": true
                                    },

                                    "category": {
                                        "type": "String",
                                        "keyRaw": "category",
                                        "visible": true
                                    },

                                    "displayName": {
                                        "type": "String",
                                        "keyRaw": "displayName",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "pagination": "PaginatedInput"
        },

        "types": {
            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=30369df19239ea4b773d32f416a0d7ae3e30927a3753b08892185d2022a6f320";